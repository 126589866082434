import { GridColDef } from "@mui/x-data-grid";
import { SessionLogsReportData } from "..";
import dayjs from "dayjs";
import { getUserTimeZone } from "../../../../../../utils/timeZones";
import { ObservationsTableCell } from "../../components";
import { Box, Tooltip, Typography } from "@mui/material";

type GridRowsColumnDefType = GridColDef<NonNullable<SessionLogsReportData["pageRecords"]>[number]>;
export type SessionLogsObservations = NonNullable<
  NonNullable<SessionLogsReportData["pageRecords"]>[number]["observations"]
>;
type ObservationsColumnDefinition = GridColDef<SessionLogsObservations[number]>;

export default function useSessionLogsReportTableColumnDefinition() {
  const columnDefDefaultProps: Partial<GridRowsColumnDefType> = {
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    valueGetter(params) {
      return params.value || emptyCellValue;
    },
  };

  const emptyCellValue = "N/A";

  const sessionLogsReportTablecolumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "studentLastName",
      headerName: "Last Name",
    },
    {
      ...columnDefDefaultProps,
      field: "studentFirstName",
      headerName: "First Name",
    },
    {
      ...columnDefDefaultProps,
      field: "studentId",
      headerName: "Student ID",
      minWidth: 150,
    },
    {
      ...columnDefDefaultProps,
      field: "serviceProviderName",
      headerName: "Service Provider",
    },
    {
      ...columnDefDefaultProps,
      field: "serviceDate",
      headerName: "Service Date",
    },
    {
      ...columnDefDefaultProps,
      field: "sessionStatus",
      headerName: "Session Status",
    },
    {
      ...columnDefDefaultProps,
      field: "service",
      headerName: "Service Type",
      minWidth: 150,
    },
    {
      ...columnDefDefaultProps,
      field: "groupSize",
      headerName: "Group Size",
    },
    {
      ...columnDefDefaultProps,
      field: "sessionNarrative",
      headerName: "Narrative",
    },
    {
      ...columnDefDefaultProps,
      field: "studentNarrative",
      headerName: "Student Narrative",
    },
    {
      ...columnDefDefaultProps,
      field: "scheduledDuration",
      headerName: "Scheduled Duration",
    },
    {
      ...columnDefDefaultProps,
      field: "timeAway",
      headerName: "Time Away",
    },
    {
      ...columnDefDefaultProps,
      field: "netDuration",
      headerName: "Duration",
    },
    {
      ...columnDefDefaultProps,
      field: "startTime",
      headerName: "Start Time",
      valueGetter(params) {
        return getTimeZoneTime(params?.value) || emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "endTime",
      headerName: "end Time",
      valueGetter(params) {
        return getTimeZoneTime(params?.value) || emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "location",
      headerName: "Location",
    },
    {
      ...columnDefDefaultProps,
      field: "districtOfLiability",
      headerName: "District of Liability",
    },
    {
      ...columnDefDefaultProps,
      field: "school",
      headerName: "School",
      minWidth: 250,
    },
    {
      ...columnDefDefaultProps,
      field: "present",
      headerName: "Present",
    },
    {
      ...columnDefDefaultProps,
      field: "makeup",
      headerName: "Makeup",
    },
    {
      ...columnDefDefaultProps,
      field: "submittedDate",
      headerName: "Submitted Date",
      valueGetter(params) {
        return params.value ? dayjs(params.value).unix() : emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "postedDate",
      headerName: "Posted Date",
      valueGetter(params) {
        return params.value ? dayjs(params.value).unix() : emptyCellValue;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "observations",
      sortable: false,
      headerName: "Observations",
      minWidth: 200,
      renderCell(params) {
        const cellData = params.value as NonNullable<SessionLogsObservations>;
        //implement nested table
        return cellData?.length > 0 ? <ObservationsTableCell rows={cellData} /> : emptyCellValue;
      },
      /*  useOverride: {
         overrideCell(row) {
           return row.observations && row.observations.length > 0
             ? "View Observations"
             : undefined;
         },
         useNestedTable(row) {
           const cell_data = row?.observations;

           return {
             title: `${row?.studentFirstName} ${row?.studentLastName}'s Observations`,
             expandOnCellClick: true,
             columns: [
               {
                 key: "refNumber",
                 headerName: "Ref Number",
               },
               {
                 key: "description",
                 headerName: "Description",
               },
               {
                 key: "observation",
                 headerName: "Observation",
                 // minWidth: 200,
               },
               {
                 key: "narrative",
                 headerName: "Narrative",
               },
             ],
             rows: cell_data,
           };
         },
       }, */
    },
    {
      ...columnDefDefaultProps,
      field: "activities",
      headerName: "Activities",
      sortable: false,
      width: 100,
      /* useTooltip: {
        overrideTitle: (cell) =>
          renderObservationsActivitiesAccommodationsModifications(cell as string),
      }, */
    },
    {
      ...columnDefDefaultProps,
      field: "accommodations",
      headerName: "Accommodations",
      sortable: false,
      width: 100,
      /* useTooltip: {
        overrideTitle: (cell) =>
          renderObservationsActivitiesAccommodationsModifications(cell as string),
      }, */
    },
    {
      ...columnDefDefaultProps,
      field: "modifications",
      headerName: "Modifications",
      sortable: false,
      width: 100,
      /* useTooltip: {
        overrideTitle: (cell) =>
          renderObservationsActivitiesAccommodationsModifications(cell as string),
      }, */
    },
  ];

  const renderObservationsActivitiesAccommodationsModifications = (cell: string) => {
    const splitString = cell?.split("-");

    const cellData = splitString?.map((item, index) => {
      return (
        <Typography key={index + index} fontSize={"10.5px"}>
          {item}
        </Typography>
      );
    });

    return (
      <Tooltip title={cellData}>
        <Box>{cellData}</Box>
      </Tooltip>
    );
  };

  const observationsColumnDefDefaultProps: Partial<ObservationsColumnDefinition> = {
    sortable: false,
    disableColumnMenu: true,
  };

  const observationsTablecolumns: ObservationsColumnDefinition[] = [
    {
      ...observationsColumnDefDefaultProps,
      field: "refNumber",
      headerName: "Ref Number",
    },
    {
      ...observationsColumnDefDefaultProps,
      field: "description",
      headerName: "Description",
      renderCell(params) {
        return (
          renderObservationsActivitiesAccommodationsModifications(params.value) || emptyCellValue
        );
      },
    },
    {
      ...observationsColumnDefDefaultProps,
      field: "observation",
      headerName: "Observation",
      renderCell(params) {
        return (
          renderObservationsActivitiesAccommodationsModifications(params.value) || emptyCellValue
        );
      },
    },
    {
      ...observationsColumnDefDefaultProps,
      field: "narrative",
      headerName: "Narrative",
      renderCell(params) {
        return (
          renderObservationsActivitiesAccommodationsModifications(params.value) || emptyCellValue
        );
      },
    },
  ];

  function getTimeZoneTime(time: string | null | undefined) {
    const timZoneId = getUserTimeZone();
    let utcTime = time;
    let format = "h:mm A";
    return dayjs.utc(utcTime, format).tz(timZoneId).format(format);
  }

  return { sessionLogsReportTablecolumns, observationsTablecolumns };
}
