import { useEffect, useRef, useState } from "react"
import useProgressReporting from "../context/hooks/useProgressReporting"
import { Box, FormControlLabel, Switch, Typography } from "@mui/material" 
import ProgressReportingPopupRoot from "./progress_reporting_popup_root"
import XNGDropDown from "../../../../../design/low-level/dropdown2";
import { ProgressReportingActionType } from "../context/progress_reporting_actions"
import { ServiceProviderResponse } from "../../../../../profile-sdk"
import { isValidArray } from "../utils/validators";


export const SettingsPopup = ({ children }: { children: React.ReactNode }) => {


  

  return (
    <ProgressReportingPopupRoot popupForm={<PopupForm />}>
      {children}
    </ProgressReportingPopupRoot>
  )

}

const PopupForm = () => {
  const [isObjective, setIsObjective] = useState(true)
  const [isChart, setIsChart] = useState(false)  
  const serviceProviderLoggedInRef = useRef<HTMLDivElement>(null) 
  const dispatch = useProgressReporting()?.dispatch 
  const { showAggregatedData, showLastSession, showTeamAggregatedData, showChart, showActiveGoals, showObjectives, serviceProviderSelected } = useProgressReporting()?.state?.settingsPopup
  // initially would be logged in service provider
  const currentlySelectedServiceProviderFromSettings = useProgressReporting()?.state?.settingsPopup?.serviceProviderSelected
  let allServiceProvidersUnderSelectedCampuses = useProgressReporting()?.state?.allServiceProvidersUnderSelectedCampuses
  allServiceProvidersUnderSelectedCampuses = isValidArray(allServiceProvidersUnderSelectedCampuses) ? allServiceProvidersUnderSelectedCampuses : []
  const currentlyLoggedInServiceProvider = useProgressReporting()?.state?.currentlyLoggedInServiceProvider


  useEffect(() => {
    dispatch({
      type: ProgressReportingActionType.TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS,
      payload: isObjective
    })
  }, [isObjective])

  useEffect(() => {
    dispatch({
      type: ProgressReportingActionType.TOGGLE_SHOW_CHART_FROM_SETTINGS,
      payload: isChart
    })
  }, [isChart])

  
  
   
  const handleActiveGoalsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ProgressReportingActionType.TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS,
      payload: !showActiveGoals
    })
  }

  const handleAggregationToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ProgressReportingActionType.TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS,
      payload: !showAggregatedData
    })
    dispatch({
      type: ProgressReportingActionType.TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS,
      payload: showAggregatedData  
    })
    dispatch({
      type: ProgressReportingActionType.TOGGLE_LAST_SESSION_FROM_SETTINGS,
      payload: false
    })
  }


  const handleTeamAggregationToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ProgressReportingActionType.TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS,
      payload: !showTeamAggregatedData
    })
    dispatch({
      type: ProgressReportingActionType.TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS,
      payload: showTeamAggregatedData  
    })
    dispatch({
      type: ProgressReportingActionType.TOGGLE_LAST_SESSION_FROM_SETTINGS,
      payload: false
    })
  }

  const handleLastSessionDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    dispatch({
      type: ProgressReportingActionType.TOGGLE_LAST_SESSION_FROM_SETTINGS,
      payload: !showLastSession
    })
    dispatch({
      type: ProgressReportingActionType.TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS,
      payload: true
    })
    dispatch({
      type: ProgressReportingActionType.TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS,
      payload: false  
    })
  }

  const handleServiceProviderChange = (serviceProvider: ServiceProviderResponse) => {
    dispatch({
      type: ProgressReportingActionType.SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS,
      payload: serviceProvider
    })
  }

  

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "20px" }}>
      <FormControlLabel
        sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
        control={
          <Switch
            checked={showObjectives}
            onChange={() => setIsObjective(prev => !prev)}
          />
        }
        label={
          <Typography className="noselect" noWrap>
            {"Show Objectives"}
          </Typography>
        }
        labelPlacement="start"
      />
      <FormControlLabel
        sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
        control={
          <Switch
            checked={showChart}
            onChange={() => setIsChart(prev => !prev)}
          />
        }
        label={
          <Typography className="noselect" noWrap>
            {"Show Chart"}
          </Typography>
        }
        labelPlacement="start"
      />

      {/* service provider logged in */}
      <Typography sx={{ fontWeight: 700 }}>Apply X Logs Data</Typography>
      <Box sx={{ marginLeft: "15px", marginTop: "10px" }}>
        {showAggregatedData && <XNGDropDown
          id={"service_provider_logged_in"}
          variant="onPrimary"
          size="small"
          ref={serviceProviderLoggedInRef} 
          name={"service_provider_logged_in"}
          value={currentlySelectedServiceProviderFromSettings}
          defaultValue={currentlySelectedServiceProviderFromSettings} 
          useTypedDropDown={{
            onChange: handleServiceProviderChange,
            value: currentlySelectedServiceProviderFromSettings,
            items:[currentlyLoggedInServiceProvider as ServiceProviderResponse, ...allServiceProvidersUnderSelectedCampuses],
            getRenderedValue: (item) => item?.firstName + " " + item?.lastName || "",
          }}
          label={"Select Service Provider"}
          fullWidth
          maxwidth="100%"
        />}

       <FormControlLabel
          sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
          control={
            <Switch
              checked={showAggregatedData}
              onChange={handleAggregationToggleChange}
            />
          }
          label={
            <Typography className="noselect" noWrap>
              {'Aggregated Data'}
            </Typography>
          }
          labelPlacement="start"
        />

        <FormControlLabel sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
          control={
            <Switch
              checked={showLastSession}
              onChange={handleLastSessionDataChange}
            />
          }
          label={
            <Typography className="noselect" noWrap>
              {'Last Session Data'}
            </Typography>
          }
          labelPlacement="start"
        />

        <FormControlLabel sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
          control={
            <Switch
              checked={showTeamAggregatedData}
              onChange={handleTeamAggregationToggleChange}
            />
          }
          label={
            <Typography className="noselect" noWrap>
              {'Team Aggregated Data'}
            </Typography>
          }
          labelPlacement="start"
        /> 
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <FormControlLabel sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
          control={
            <Switch
              checked={showActiveGoals}
              onChange={handleActiveGoalsChange}
            />
          }
          label={
            <Typography className="noselect" noWrap>
              {'Show Only Active Goals'}
            </Typography>
          }
          labelPlacement="start"
        />

      </Box>

    </Box>
  )
}