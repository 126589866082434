import { useMemo } from "react";
import useApiQueryServiceProviders from "../../../../../api/hooks/service_provider/use_api_query_service_providers";
import useUserRoles from "../../../../../hooks/use_user_roles";
import { ServiceProviderRef } from "../../../../../profile-sdk";

/* 
  This custom hook is used to get the service provider options based on user roles.
*/

type Props = {
  clientId: string;
  stateInUs: string;
  userProfileWithSpSchema: Required<ServiceProviderRef>;
  userRoles: ReturnType<typeof useUserRoles>;
  loggedInClientAssignmentProviders: {
    supervisedServiceProviders: ServiceProviderRef[];
    appointingServiceProviders: ServiceProviderRef[];
  };
};

export default function useQueryAndFilterServiceProviderOptionsByUserRoles(props: Props) {
  // Query service providers via API
  const { data: serviceProvidersResponse, ...serviceProviderApiQueryClientProps } =
    useApiQueryServiceProviders({
      queryParams: {
        clientId: props.clientId,
        state: props.stateInUs,
      },
    });

  // filter service providers response based on user roles
  const serviceProviderOptionsByUserRole: ServiceProviderRef[] = useMemo(() => {
    const allServiceProviders = [...(serviceProvidersResponse?.serviceProviders ?? [])];

    // Admin: sees all service providers
    if (
      props.userRoles.includes("Executive Admin") ||
      props.userRoles.includes("Delegated Admin")
    ) {
      return allServiceProviders;
    }

    // Approver: Your sessions + People in your caseload
    // DEC: Your sessions + People in your caseload
    if (props.userRoles.includes("Approver") || props.userRoles.includes("Proxy Data Entry")) {
      const serviceProvidersWhereSupervisedOrAppointing = allServiceProviders.filter((provider) => {
        // If is supervised, include. (Applies to Approvers. FYI: Users may have both roles)
        if (props.userRoles.includes("Approver")) {
          if (
            props.loggedInClientAssignmentProviders?.supervisedServiceProviders?.some(
              (sp) => sp.id === provider.id,
            )
          )
            return true;
        }

        // If is appointing, include. (Applies to DEC. FYI: Users may have both roles)
        if (props.userRoles.includes("Proxy Data Entry")) {
          if (
            props.loggedInClientAssignmentProviders?.appointingServiceProviders?.some(
              (sp) => sp.id === provider.id,
            )
          )
            return true;
        }

        return false;
      });

      const res: ServiceProviderRef[] = [
        ...serviceProvidersWhereSupervisedOrAppointing,
        props.userProfileWithSpSchema,
      ];
      return res;
    }

    // Autonomous SP: Only see your sessions
    // Assistant SP: Only see your sessions
    if (
      props.userRoles.includes("Service Provider - Autonomous") ||
      props.userRoles.includes("Service Provider - Assistant")
    ) {
      return [props.userProfileWithSpSchema];
    }

    return [];
  }, [
    props.loggedInClientAssignmentProviders?.appointingServiceProviders,
    props.loggedInClientAssignmentProviders?.supervisedServiceProviders,
    props.userProfileWithSpSchema,
    props.userRoles,
    serviceProvidersResponse?.serviceProviders,
  ]);

  return { data: serviceProviderOptionsByUserRole, ...serviceProviderApiQueryClientProps };
}
