import { GoalDisplay } from "../../../../../profile-sdk";
import { GoalRef } from "../../../../../session-sdk";

/**
 * Accepts a list of GoalRef and GoalDisplays to consolidate them into a list that combines the two classes.
 *
 * The reason for this is because GoalRef provides a field that doesn't exist on GoalDisplay called
 * GoalNumberFromVendor (Note: confirm if this field exists on GoalDisplay and is just inconsistently named)
 */
export interface UnifiedGoal {
  goalRef: GoalRef;
  goalDisplay: GoalDisplay;
}

export function useUnifiedGoals(props: {
  goalRefs: GoalRef[];
  goalDisplays: GoalDisplay[];
}): UnifiedGoal[] | null {
  const { goalRefs, goalDisplays } = props;

  const res: UnifiedGoal[] = [];
  goalRefs.forEach((goalRef: GoalRef) => {
    const unifiedGoal: UnifiedGoal | null = deriveUnifiedGoal({
      goalRef,
      goalDisplays,
    });

    if (unifiedGoal) {
      res.push(unifiedGoal);
    }
  });

  return res;

  function deriveUnifiedGoal(props: {
    goalRef: GoalRef;
    goalDisplays: GoalDisplay[];
  }): UnifiedGoal | null {
    const { goalRef } = props;

    const goalDisplay = props.goalDisplays.find(
      (gd) => gd.internalId! === props.goalRef.internalId!,
    )!;

    if (!goalDisplay) {
      return null;
    }

    return { goalDisplay, goalRef };
  }
}
