import { Box } from "@mui/material";
import GoalInfoTabHeader from "./component/presentational/goal_info_tab_header";
import { Goal, StudentResponse } from "../../../../profile-sdk";
import React from "react";
import GoalGridListView from "./component/presentational/goal_grid_list_view";
import GoalCreateEditView from "./component/presentational/goal_create_edit_view";
import { useStudentProfileContext } from "../../context/context";

type Props = {
  state: string;
  edited_student: StudentResponse;
  set_edited_student: React.Dispatch<React.SetStateAction<StudentResponse | null>>;
};

/**
 * Renders the Goal Objective Info tab.
 *
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered Goal Objective Info tab.
 */
export const GoalObjectiveInfoTab = (props: Props) => {
  // gets all the goals from the student object
  const all_goals = React.useMemo(() => {
    return props.edited_student.spedDossier?.prescribedCareProvisionsLedger?.goals || [];
  }, [props.edited_student]);

  const {
    isDirty,
    isCreatingGoal,
    setIsCreatingGoal,
    setBackButtonClicked,
    inEditGoalView,
    setInEditGoalView,
    selectedGoal,
    setSelectedGoal,
  } = useStudentProfileContext();

  /**
   * Updates or adds the selected goal.
   *
   * @param {Goal} goal - The goal to update or add.
   * @returns {void}
   */
  const handle_update_or_add_selected_goal = (goal: Goal) => {
    setSelectedGoal(goal);
  };

  return (
    <Box px={"12px"} pt={"0.5rem"} maxWidth={"xl"}>
      {!inEditGoalView && !isCreatingGoal ? (
        <>
          <GoalInfoTabHeader
            use_save_btn={{
              label: "Create New Goal",
              onClick() {
                handle_update_or_add_selected_goal({});
                setInEditGoalView(true);
                setIsCreatingGoal(true);
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",

              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
            }}
          >
            <GoalGridListView
              goals={all_goals}
              onGoalCardClick={(g) => {
                handle_update_or_add_selected_goal(g);
                setInEditGoalView(true);
              }}
            />
          </Box>
        </>
      ) : (
        <GoalCreateEditView
          edited_student={props.edited_student}
          selected_goal={selectedGoal}
          onBackBtnClick={() => {
            if (isDirty || isCreatingGoal) {
              setBackButtonClicked(true);
            } else {
              handle_update_or_add_selected_goal({});
              setInEditGoalView(false);
            }
          }}
        />
      )}
    </Box>
  );
};
