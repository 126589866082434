import { GoalAreaOfFocus, GoalDisplay } from "../../../../../profile-sdk";
import { ServiceArea } from "../../../../../session-sdk";
import { getAreaOfFocusFromGoalDisplayUsingInventory } from "./get_area_of_focus_from_goal_display_using_inventory";
import { isGoalInServiceArea } from "./is_goal_in_service_area";

export function getGoalDisplayIsWithinServiceArea(
  goalDisplay: GoalDisplay,
  goalAreasOfFocusInventory: GoalAreaOfFocus[],
  serviceArea: ServiceArea,
) {
  const goalAreaOfFocus = getAreaOfFocusFromGoalDisplayUsingInventory(
    goalDisplay,
    goalAreasOfFocusInventory,
  );

  const isInServiceArea = goalAreaOfFocus
    ? isGoalInServiceArea(goalAreaOfFocus, serviceArea)
    : false;

  return isInServiceArea;
}
