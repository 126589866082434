import { Box,Input, Typography } from "@mui/material";
import { MWD } from "./types";
import { RootState, useXNGSelector } from "../../context/store";
import { getSizing } from "../../design/sizing";
import { XNGIconRenderer } from "../../design";
import XNGToggleGroup from "../../design/low-level/button_togglegroup";
import usePalette from "../../hooks/usePalette";
import dayjs, { Dayjs } from "dayjs";
import XNGButton from "../../design/low-level/button";
import { ApproverFilterButton } from "./calendar_filter_button";
import { ApproverFilterBubbles } from "./assistant_filter_bubbles";
import sessionStorageKeys from "../../browser/sessionStorageKeys";
import { Dispatch, SetStateAction } from "react";
import { useCalendarContext } from "./context/context";
import Grid from '@mui/material/Unstable_Grid2';
import { MSBICONS } from "../../fortitude";

/**
 * In order to modularize the calendar header into its own component and streamline its memoized dependencies, we are going to
 * encapsulate all of its previously dependent state. Ideally, we may want to turn this into an isolated module using a useReducer
 * pattern or even contextual state. For now, we'll package it and pass it to the `<CalendarHeader />`.
 *
 * ### NOTE: This is closed off to any additions! In the instance of needing modifications, consider removing the field from this type.
 *
 * For more information:
 * This is effectively a way to have our header component accept its previous dependencies as part of a progressive Calendar refinement.
 * This is not an antipattern considering that the code seen will not change. For all developers who need to add new state or affect current
 * state within the calendar header module, do so in other ways: consider passing a state and callback, leveraging the `CalendarFilterState`
 * if your new functionality pertains to filter functionality, or perhaps even build the foundation for a new CalendarHeader context.
 */
export interface LegacyCalendarHeaderState {
  focusedDate: dayjs.Dayjs;
  setFocusedDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  setMWD: Dispatch<SetStateAction<MWD>>;
}

export function CalendarHeader(props: Readonly<{
  onCreateClick: (start?: Date, end?: Date) => void;
  legacyState: LegacyCalendarHeaderState;
}>) {
  const mwd: MWD = useXNGSelector(
    (state: RootState) => state.calendarViewModeSlice.calendarViewMode,
  );
  const palette = usePalette();
  const focusedDateKey = sessionStorageKeys.FOCUSED_DATE_KEY;
  const { focusedDate, setFocusedDate, setMWD } = props.legacyState;

  const weekFormatter = (date: Dayjs) => {
    const beginningOfTheWeek = date.date(date.date() - date.day());

    if (beginningOfTheWeek.date(beginningOfTheWeek.date() + 6).date() < beginningOfTheWeek.date()) {
      if (date.month() === 11)
        return `${beginningOfTheWeek.format("MMMM D")}, ${date.year()}-${beginningOfTheWeek
          .date(beginningOfTheWeek.date() + 6)
          .format("MMMM D")}, ${date.year() + 1}`;
      return `${beginningOfTheWeek.format("MMMM D")}-${beginningOfTheWeek
        .date(beginningOfTheWeek.date() + 6)
        .format("MMMM D")}, ${date.year()}`;
    }

    return `${beginningOfTheWeek.format("MMMM D")}-${beginningOfTheWeek
      .date(beginningOfTheWeek.date() + 6)
      .date()}, ${date.year()}`;
  };

  function onIncrement(i: number) {
    switch (mwd) {
      case "Day":
        setFocusedDate(focusedDate.add(i, "day"));
        sessionStorage.setItem(focusedDateKey, focusedDate.add(i, "day").toISOString());
        break;
      case "Week":
        setFocusedDate(focusedDate.add(i, "week"));
        sessionStorage.setItem(focusedDateKey, focusedDate.add(i, "week").toISOString());
        break;
      case "Month":
        setFocusedDate(focusedDate.add(i, "month"));
        sessionStorage.setItem(focusedDateKey, focusedDate.add(i, "month").toISOString());
        break;
    }
  }

  const calendarContext = useCalendarContext();

  const {Caret, Search} = MSBICONS;

  return (
    <Grid
      container
      spacing={{
        xs: 1,
        sm: 2,
      }}
    >
      <Grid
        xs={6}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        sx={{
          order: { xs: 1, sm: 2, md: 2, lg: 0 },
        }}
      >
        <Box
          sx={{
            // backgroundColor:"wheat",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Type here to search"
            onChange={(e) => {
              calendarContext.calendarFilterState.setSessionName(e.target.value);
            }}
            value={calendarContext.calendarFilterState.sessionName}
            fullWidth
            sx={{
              backgroundColor: palette.contrasts[5],
              "& .MuiInputBase-input": {
                borderLeft: "2px solid #E0E0E0",
              },
              borderRadius: "4px 4px 4px 4px",
              maxWidth: "370px",
            }}
            startAdornment={
              <XNGIconRenderer disableRenderer i={<Search />} size="md" color="black" />
            }
          />
        </Box>
      </Grid>

      <Grid
        xs={12}
        lg={6}
        sm={9}
        md={10}
        xl={4}
        sx={{
          order: { xs: 8, sm: 0, md: 0, lg: 1, xl: 1 },
        }}
      ></Grid>

      <Grid
        xs={4}
        sm={3}
        md={2}
        lg={2}
        xl={4}
        sx={{
          order: { xs: 6, sm: 1, md: 1, lg: 2 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: {
              justifyContent: "center",
              sm: "end",
            },
            alignItems: "center",
          }}
        >
          <XNGButton
            sx={{
              backgroundColor: palette.primary[3],
              color: palette.contrasts[5],
            }}
            onClick={() =>
              props.onCreateClick(
                dayjs(new Date()).startOf("day").add(8, "hours").toDate(),
                dayjs(new Date()).startOf("day").add(9, "hours").toDate(),
              )
            }
          >
            <Typography
              noWrap
              sx={{
                fontSize: {
                  xs: "0.75rem",
                  sm: "1rem",
                },
              }}
            >
              Create Session
            </Typography>
          </XNGButton>
        </Box>
      </Grid>

      <Grid
        xs={4}
        sm={"auto"}
        md={"auto"}
        lg={1}
        xl={1}
        sx={{
          order: { xs: 4, sm: 5, md: 5, lg: 3, xl: 3 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: {
              justifyContent: "center",
              sm: "start",
            },
            width: "100%",
            height: "100%",
          }}
        >
          <XNGToggleGroup
            options={[
              {
                icon: (
                  <XNGIconRenderer size="caret" left i={<Caret />} color={palette.primary[2]} />
                ),
                onClick: () => {
                  onIncrement(-1);
                },
              },
            ]}
            sx={{ backgroundColor: palette.contrasts[5], width: "57.5px" }}
            borderRadius="4px 0px 0px 4px"
          />
          <XNGToggleGroup
            options={[
              {
                icon: (
                  <XNGIconRenderer size="caret" right i={<Caret />} color={palette.primary[2]} />
                ),
                onClick: () => {
                  onIncrement(1);
                },
              },
            ]}
            sx={{ backgroundColor: palette.contrasts[5], width: "57.5px" }}
            borderRadius="0px 4px 4px 0px"
          />
        </Box>
      </Grid>
      <Grid
        xs={4}
        sm={1}
        md={1}
        lg={1}
        xl={0.65}
        sx={{
          order: { xs: 3, sm: 6, md: 6, lg: 4 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            justifyContent: {
              justifyContent: "center",
              sm: "start",
            },
          }}
        >
          <XNGButton
            sx={{
              backgroundColor: palette.primary[3],
              color: palette.contrasts[5],
            }}
            onClick={() => {
              setFocusedDate(dayjs());
              sessionStorage.setItem(focusedDateKey, dayjs().toISOString());
            }}
          >
            Today
          </XNGButton>
        </Box>
      </Grid>

      <Grid
        xs={6}
        sm={4}
        md={4}
        lg={2}
        xl={2.35}
        sx={{
          order: { xs: 0, sm: 4, md: 4, lg: 5 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: {
              lg: "start",
              sm: "end",
            },
          }}
        >
          <ApproverFilterButton />
          <ApproverFilterBubbles />
        </Box>
      </Grid>
      <Grid
        xs={12}
        sm={4}
        md={4}
        lg={4}
        xl
        sx={{
          order: { xs: 6, sm: 3, md: 3, lg: 6 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: palette.contrasts[5] }} className="noselect" variant="h6">
            {mwd === "Day" && focusedDate.format("MMMM D, YYYY")}
            {mwd === "Week" && weekFormatter(focusedDate)}
            {mwd === "Month" && focusedDate.format("MMMM YYYY")}
          </Typography>
        </Box>
      </Grid>

      <Grid
        xs={12}
        sm
        md
        lg={4}
        xl={4}
        xlOffset={"auto"}
        lgOffset={0}
        sx={{
          order: { xs: 7, sm: 7, md: 7, lg: 7 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              justifyContent: "center",
              sm: "end",
            },
            alignItems: "center",
            gap: getSizing(2),
            width: "100%",
            height: "100%",
          }}
        >
          <XNGToggleGroup
            options={[{ label: "Month", onClick: () => setMWD("Month") }]}
            sx={{
              backgroundColor: mwd === "Month" ? palette.primary[3] : palette.contrasts[5],
              color: mwd === "Month" ? palette.contrasts[5] : palette.primary[2],
              border: "unset",
              ":hover": {},
              width: "82px",
              height: "28px",
            }}
            value={mwd === "Month" ? "Month" : ""}
            border="unset"
          />
          <XNGToggleGroup
            options={[{ label: "Week", onClick: () => setMWD("Week") }]}
            sx={{
              backgroundColor: mwd === "Week" ? palette.primary[3] : palette.contrasts[5],
              color: mwd === "Week" ? palette.contrasts[5] : palette.primary[2],
              border: "unset",
              ":hover": {},
              width: "82px",
              height: "28px",
            }}
            value={mwd === "Week" ? "Week" : ""}
            border="unset"
          />
          <XNGToggleGroup
            options={[{ label: "Day", onClick: () => setMWD("Day") }]}
            sx={{
              backgroundColor: mwd === "Day" ? palette.primary[3] : palette.contrasts[5],
              color: mwd === "Day" ? palette.contrasts[5] : palette.primary[2],
              border: "unset",
              ":hover": {},
              width: "82px",
              height: "28px",
            }}
            value={mwd === "Day" ? "Day" : ""}
            border="unset"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
