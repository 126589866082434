import {
  Autocomplete,
  TextField,
  TextFieldProps,
  SxProps,
  Chip,
  AutocompleteRenderInputParams,
  Tooltip,
  Box,
  AutocompleteProps,
  Divider,
} from "@mui/material";
import { SelectableRow } from "./selectable_row";
import { Fragment } from "react";

// ----------------------------------- CONTRACTS -----------------------------------

type AutocompletePartialProps<T> = Partial<AutocompleteProps<T, boolean, boolean, boolean>>;

export type MSBSearchMultiselectVariant = "default" | "no overflow after 1" | "no overflow";
export type MSBSearchMultiselectRenderOptionVariant = "default" | "checkbox";
export type MSBSearchMultiselectColorVariant = "white-on-white" | "white-on-blue";

/**
 * Closed to modification. These are the props required for this component's minimum
 * functionality.
 *
 * If you need to modify, instead create a unique component local to your feature.
 */
interface RequiredProps<T> {
  options: T[];
  selectedOptions: T[];
  getOptionLabel: (option: T) => string;
  onChange: (v: T[], e?: React.SyntheticEvent<Element, Event>) => void;
}

/**
 * Open to extension. The optional props for `MSBSearchMultiselect`.
 */
interface OptionalProps<T> {
  label?: string;
  variant?: MSBSearchMultiselectVariant;
  renderOptionVariant?: MSBSearchMultiselectRenderOptionVariant;
  sx?: SxProps;
  textFieldProps?: TextFieldProps;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  getTooltip?: (option: T) => React.ReactNode;
  disabled?: boolean;
  autocompleteProps?: AutocompletePartialProps<T>;
  colorVariant?: MSBSearchMultiselectColorVariant;
}

export type MSBSearchMultiselectProps<T> = RequiredProps<T> & OptionalProps<T>;

// ---------------------------------------------------------------------------------

/**
 * Will boilerplate an MUI multi-selectable `Autocomplete` with programmable variants
 *
 * @param options All possible values to select from
 * @param selectedOptions Should accept React state that represents user-selected options
 * @param onChange Will return updated selectedOptions to use to update your user-selected options state
 * @param getOptionLabel Tells the component what text to render for each option value
 * @param variant _(Optional)_ Allows us to reuse predefined `Autocomplete` props for different scenarios
 * 
 * @fileoverview The module below contains a concrete example of interacting with the `MSBSearchMultiselect` component,
 * in our jsDom testing environment.
 * 
 * @module "src\views\reports\views\session_logs\views\integration\integration.tsx"
 * 
 * @example
 * // Example usage in a test suite
 * import MSBSearchMultiselect from './search_multiselect';
 * 
 *  const multiSelectComponent = screen.getByLabelText(/Test Label/i);
 *  await user.click(multiSelectComponent);
 *  const selectOption = screen.getByTestId(/select-all/i);
 *  const checkboxInputComponent = await screen.findByLabelText(/Test Label/i);
 *  await user.click(selectOption);
 *  const presentation = screen.getByRole("presentation");
 *  expect(checkboxInputComponent).toBeChecked();
 *  expect(presentation).not.toBeInTheDocument();
 */
export default function MSBSearchMultiselect<T>(props: MSBSearchMultiselectProps<T>) {
  const variantProps = useVariantProps(props);
  const colorVariantProps = useColorVariantProps(props);
  const renderOptionVariant = useRenderOptionVariant(props);

  return (
    <>
      {props.options && (
        <Autocomplete
          disableCloseOnSelect
          disabled={props.disabled}
          options={props.options}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant="outlined"
              label={props.label ?? "Type to search..."}
              {...colorVariantProps.textfieldProps}
              {...props.textFieldProps}
            />
          )}
          renderOption={renderOptionVariant.renderOption}
          size={"small"}
          multiple
          getOptionLabel={(option: string | T) =>
            typeof option === "string" ? option : props.getOptionLabel(option)
          }
          value={props.selectedOptions}
          onChange={(e, value) => props.onChange(value as T[], e)}
          isOptionEqualToValue={props.isOptionEqualToValue}
          slotProps={{ popper: { sx: { minWidth: "20rem" }, placement: "bottom-start" } }}
          // --------------- DEFAULT ABOVE, OVERRIDES BELOW ---------------
          {...variantProps}
          {...colorVariantProps.multiselectProps}
          sx={props.sx}
          {...props.autocompleteProps}
        />
      )}
    </>
  );
}

/**
 * This will return props we can use to effectively program our Autocomplete with predefined,
 * reusable functionality.
 *
 * NOTE: ***Existing variants are closed for modification***. If existing variants need to
 * be modified to accomplish a goal, please create a new variant instead.
 */
function useVariantProps<T>(props: MSBSearchMultiselectProps<T>): AutocompletePartialProps<T> {
  const noOverFlowAfter1: AutocompletePartialProps<T> = {
    sx: {
      ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": { minWidth: "1px" },
    },
    renderTags: (value: any, getTagProps: any) =>
      value
        .slice(0, 2)
        .map((option: T, index: number) => (
          <Chip
            size="small"
            variant="filled"
            key={index + index}
            label={
              index < 1
                ? props.getOptionLabel(option)
                : value.length > 1
                ? `+${value.length - 1} more`
                : props.getOptionLabel(option)
            }
            {...getTagProps({ index })}
            {...(index > 0 && { onDelete: null, sx: { pr: ".25rem" } })}
          />
        )),
  };

  const noOverFlow: AutocompletePartialProps<T> = {
    sx: {
      ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": { minWidth: "1px" },
    },
    renderTags: (value: any, getTagProps: any) => (
      <Chip
        size="small"
        variant="filled"
        label={`${value.length} selected`}
        {...getTagProps(0)}
        onDelete={null}
        sx={{ pr: ".25rem" }}
      />
    ),
  };

  const variant = props.variant ?? "default";
  switch (variant) {
    case "default":
      return {};
    case "no overflow after 1":
      return noOverFlowAfter1;
    case "no overflow":
      return noOverFlow;
    default:
      throw new Error(
        "Fallthrough in switch statement! Has a new MSBSearchMultiselect variant been introduced?",
      );
  }
}

/**
 * Custom hook that returns the variant of rendering options based on the provided props.
 *
 * @template T - The type of the options.
 * @param {MSBSearchMultiselectProps<T>} props - The props for the search multiselect component.
 * @returns {object} - The variant of rendering options based on the selected variant.
 * @throws {Error} - If an unknown variant is selected.
 */
function useRenderOptionVariant<T>(
  props: MSBSearchMultiselectProps<T>,
): AutocompletePartialProps<T> {
  const selectedVariant = props.renderOptionVariant ?? "default";

  const defaultVariant: AutocompletePartialProps<T> = {
    renderOption: (oprops: any, option: T, state) => {
      const { key, ...rest } = oprops;
      return props.getTooltip ? (
        <Tooltip key={key} title={props.getTooltip(option)} placement="right" arrow>
          <li {...rest}>{props.getOptionLabel(option)}</li>
        </Tooltip>
      ) : (
        <li key={key} {...rest}>
          {props.getOptionLabel(option)}
        </li>
      );
    },
  };

  const checkboxVariant: AutocompletePartialProps<T> = {
    renderOption: (oprops: any, option: T, state) => {
      const isAllSelected = props.selectedOptions.length === props.options.length;
      function handleSelectAllClick() {
        if (isAllSelected) {
          props.onChange([]);
        } else {
          props.onChange(props.options);
        }
      }

      const { key, ...rest } = oprops;

      return (
        <Fragment key={key + "fragment"}>
          {state.index === 0 && (
            <Box onClick={handleSelectAllClick}>
              <SelectableRow checked={isAllSelected} title={"Select all"}/>
              <Divider key={state.index + state.index} />
            </Box>
          )}

          <SelectableRow
            checked={state.selected}
            title={props.getOptionLabel(option)}
            boxProps={rest}
          />
        </Fragment>
      );
    },
  };

  switch (selectedVariant) {
    case "default":
      return defaultVariant;
    case "checkbox":
      return checkboxVariant;
    default:
      throw new Error(
        "Fallthrough in switch statement! Has a new MSBSearchMultiselect options variant been introduced?",
      );
  }
}

type MSBSearchMultiselectColorVariantProps<T> = {
  textfieldProps: Partial<TextFieldProps>;
  multiselectProps: AutocompletePartialProps<T>;
};

function useColorVariantProps<T>(
  props: MSBSearchMultiselectProps<T>,
): MSBSearchMultiselectColorVariantProps<T> {
  const whiteOnWhite: MSBSearchMultiselectColorVariantProps<T> = {
    textfieldProps: {},
    multiselectProps: {},
  };

  const whiteOnBlue: MSBSearchMultiselectColorVariantProps<T> = {
    textfieldProps: {
      sx: {
        ".MuiInputLabel-root": {
          bgcolor: "white",
          left: "-.2rem",
          px: ".4rem",
          py: ".1rem",
          top: "-.2rem",
          borderRadius: "4px",
        },
        ".MuiOutlinedInput-root": { bgcolor: "white!important" },
      },
    },
    multiselectProps: {},
  };

  const variant = props.colorVariant ?? "white-on-white";
  switch (variant) {
    case "white-on-white":
      return whiteOnWhite;
    case "white-on-blue":
      return whiteOnBlue;
    default:
      throw new Error(
        "Fallthrough in switch statement! Has a new MSBSearchMultiselect color variant been introduced?",
      );
  }
}
