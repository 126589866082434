import { Box } from '@mui/material'
import Popover from '@mui/material/Popover';
import React from 'react' 

interface IProgressReportingPopupRootProps extends React.FC<{ children: React.ReactNode, popupForm?: React.ReactNode }> {

}

const ProgressReportingPopupRoot: IProgressReportingPopupRootProps = ({ children, popupForm }) => {
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
    setOpen(prev => !prev);
  }
  return (
    <Box>
      <Box onClick={handleClick}>
        {children}
      </Box>
      <Popover open={open} onClose={() => setOpen(false)} anchorEl={anchor}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }} > 
          {popupForm} 
      </Popover>
    </Box>
  )
}

 
export default ProgressReportingPopupRoot