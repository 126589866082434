import { Box } from "@mui/material";
import StaffDirectoryHeader from "../components/header/staff_directory_header";
import { MaxWidthWrapper } from "../../../components/max_width_wrapper";

import StaffDirectoryCreateProviderForm from "../components/interactive/staff_directory_create_provider_form";
import ExportStaffDirectoryForm from "../components/interactive/export_staff_directory_form";
import { ROUTES_XLOGS } from "../../../../../constants/URLs";
import { useNavigate } from "react-router";
import { OrderByDirection, StaffDirectoryProfile } from "../../../../../profile-sdk";
import useStaffDirectoryHomePageContext from "../hooks/context/use_staff_directory_home_page_context";
import useUserManagementContext from "../../../hooks/context/use_user_management_context";
import React, { useMemo } from "react";
import STAFF_DIRECTORY_LOCAL_STORAGE_KEYS from "../constants/staff_directory_local_storage_keys";
import { useXNGSelector } from "../../../../../context/store";
import { selectLoggedInClientAssignment } from "../../../../../context/slices/userProfileSlice";
import { StaffDirectoryTable } from "../components/tables/staff_directory_table";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

const StaffDirectoryManagerHomePage = () => {
  const adminUserId = useUserManagementContext().store.userManagementData.user?.id;
  const clientId = useUserManagementContext().store.userManagementData.client?.id;
  const selectedColumnSort = useStaffDirectoryHomePageContext().store.tableData.sort;
  const isMsbAdmin = useUserManagementContext().store.userManagementData.user?.isMsbAdmin;
  const isExecutiveAdmin = useUserManagementContext().store.userManagementData.user?.isSuperAdmin;
  const stateInUs = useUserManagementContext().store.userManagementData.stateInUs;
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const adminUser =
    loggedInClientAssignment.isDelegatedAdmin || loggedInClientAssignment.isExecutiveAdmin;

  const navigate = useNavigate();

  if (!isMsbAdmin && !isExecutiveAdmin && !adminUser) {
    navigate(ROUTES_XLOGS.calendar);
  }

  const {
    data: directoryApi,
    isLoading: directoryLoading,
    isRefetching: directoryRefetching,
    refetch: directoryRefetch,
  } = useStaffDirectoryHomePageContext().apiQueryStaffDirectory;

  const sortModel: GridSortModel = useMemo(() => {
    const sortFieldEnum = selectedColumnSort.column ?? "firstName"; // default sort by first name
    const sortDirection =
      selectedColumnSort.direction === OrderByDirection.NUMBER_0 ? "asc" : "desc";

    const model: GridSortModel = [
      {
        field: sortFieldEnum,
        sort: sortDirection,
      },
    ];

    return model;
  }, [selectedColumnSort]);

  React.useMemo(() => {
    localStorage.setItem(STAFF_DIRECTORY_LOCAL_STORAGE_KEYS.client_id, clientId ?? ""); // initialize persistant storage for client id
  }, [clientId]);

  const createProviderFormOpen =
    useUserManagementContext().store.staffDirectoryData.modals.createProvider.isOpen;

  const userManagementDispatch = useUserManagementContext().dispatch;
  const staffDirectoryDispatch = useStaffDirectoryHomePageContext().dispatch;

  return (
    <Box
      sx={{
        px: 2,
        pt: 3,
      }}
      // bgcolor={"wheat"}
    >
      <StaffDirectoryHeader />
      <MaxWidthWrapper>
        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <StaffDirectoryTable
            rows={directoryApi?.dataList ?? []}
            paginationState={{
              paginationModel: {
                pageSize: directoryApi?.pageSize ?? 10,
                page: directoryApi?.pageNumber ? directoryApi.pageNumber - 1 : 0,
              },
              setPaginationModel: function (paginationModel: GridPaginationModel): void {
                console.log("paginationModel", paginationModel);
                staffDirectoryDispatch({
                  type: "set_pagination_current_page",
                  payload: {
                    currentPage: paginationModel.page + 1,
                  },
                });
                staffDirectoryDispatch({
                  type: "set_pagination_rows_per_page",
                  payload: {
                    rowsPerPage: paginationModel.pageSize,
                  },
                });
              },

              totalRecords: directoryApi?.totalRecords,
            }}
            sortingState={{
              sortModel: sortModel,
              setSortModel: function (sortingModel: GridSortModel): void {
                console.log("sortingModel", sortingModel);
                staffDirectoryDispatch({
                  type: "set_table_column_sort",
                  payload: {
                    sortValue: {
                      column: sortingModel[0]?.field as keyof StaffDirectoryProfile,
                      direction:
                        sortingModel[0]?.sort === "asc"
                          ? OrderByDirection.NUMBER_0
                          : OrderByDirection.NUMBER_1,
                    },
                  },
                });
              },
            }}
            onRowClick={(row) => {
              navigate(
                `${ROUTES_XLOGS._admin.staffDirectoryManager}/${
                  row.id
                }?active=${!row.clientAssignmentStatus}`,
              );
            }}
            loading={directoryLoading || directoryRefetching}
          />
        </Box>
      </MaxWidthWrapper>
      <StaffDirectoryCreateProviderForm
        creating_user_id={adminUserId ?? ""}
        stateInUs={stateInUs}
        isOpen={createProviderFormOpen}
        refetch_staff_directory={directoryRefetch}
        onClose={() => {
          userManagementDispatch({
            type: "set_open_modal_staff_directory_create_provider",
            payload: {
              isOpen: false,
            },
          });
        }}
      />
      <ExportStaffDirectoryForm />
    </Box>
  );
};

export default StaffDirectoryManagerHomePage;
