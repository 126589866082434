export interface ProgressReportingAction {
    type: ProgressReportingActionType;
    payload?: any; // Use specific type if possible
}


export enum ProgressReportingActionType { 
    /**
     * SETTINGS POPUP ACTIONS
     */
    TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS = "TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS",
    TOGGLE_SHOW_CHART_FROM_SETTINGS = "TOGGLE_SHOW_CHART_FROM_SETTINGS",
    TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS = "TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS",
    TOGGLE_LAST_SESSION_FROM_SETTINGS = "TOGGLE_LAST_SESSION_FROM_SETTINGS",
    TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS = "TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS",
    TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS = "TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS",
    SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS = "SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS",
    /***
     * GENERAL ACTIONS  
     */
    OPEN_PROGRESS_REPORTING_EDIT_SECTION = "OPEN_PROGRESS_REPORTING_EDIT_SECTION",
    CLOSE_PROGRESS_REPORTING_EDIT_SECTION = "CLOSE_PROGRESS_REPORTING_EDIT_SECTION",
    SET_CURRENTY_LOGGED_IN_USER_PROFILE = "SET_CURRENTY_LOGGED_IN_USER_PROFILE",
    SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER = " SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER",
    SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION = "SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION",
    SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES = "SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES",
    SET_YEAR_RANGE_FOR_CAMPUS = "SET_YEAR_RANGE_FOR_CAMPUS",
    SET_REPORTING_PERIOD = "SET_REPORTING_PERIOD",

    /***
     * STUDENT PROGRESS REPORT FETCHED
     */
    SET_STUDENT_PROGRESS_REPORT_FETCHED = "SET_STUDENT_PROGRESS_REPORT_FETCHED",

    /** ACTION FOR PROGRESS REPORT API PARAMETER MOUNTING */
    SET_STUDENTS_FOR_PROGRESS_REPORT_GENERATION ="SET_STUDENTS_FOR_PROGRESS_REPORT_GENERATION",
    SET_SERVICE_PROVIDERS_FOR_PROGRESS_REPORT_GENERATION = "SET_SERVICE_PROVIDERS_FOR_PROGRESS_REPORT_GENERATION",
    SET_GOAL_AREA_OF_FOCUS_FOR_PROGRESS_REPORT_GENERATION = "SET_GOAL_AREA_OF_FOCUS_FOR_PROGRESS_REPORT_GENERATION",
    SET_REPORTING_PERIOD_FOR_PROGRESS_REPORT_GENERATION = "SET_REPORTING_PERIOD_FOR_PROGRESS_REPORT_GENERATION",

    /** update general comments */
    UPDATE_GENERAL_COMMENTS_IN_REPORTING_DATA = "UPDATE_GENERAL_COMMENTS_IN_REPORTING_DATA",
    /** update progress report status */
    SET_PROGRESS_POST_STATUS = "SET_PROGRESS_POST_STATUS",

    /** SET TRANSFORMED STUDENT PROGRESS REPORT */
    SET_TRANSFORMED_PROGRESS_REPORT = "SET_TRANSFORMED_PROGRESS_REPORT", 
    /** UPDATE POSTED UNPOSTED STATUS FOR TRANSFORMED DATA */
    SET_TRANSFORMED_PROGRESS_REPORT_POSTED_UNPOSTED_STATUS = "SET_TRANSFORMED_PROGRESS_REPORT_POSTED_UNPOSTED_STATUS",
    /** UPDATE GENERAL COMMENTS FOR TRANSFORMED DATA */
    SET_TRANSFORMED_PROGRESS_REPORT_GENERAL_COMMENTS = "SET_TRANSFORMED_PROGRESS_REPORT_GENERAL_COMMENTS",

    /** udpate existing progress report post/unpost status */
    UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS = "UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS",

    /*** PRINTING SIGNAL */
    SET_PRINTING_SIGNAL = "SET_PRINTING_SIGNAL",
    /** GENERATING XSLX */
    SET_GENERATING_XLSX_SIGNAL = "SET_GENERATING_XLSX_SIGNAL",

    /***UPDATE POLLING STATUS*/
    UPDATE_POLLING_STATUS = "UPDATE_POLLING_STATUS",
    
    //UPDATE SUMMARY GENERATION STATUS
    UPDATE_SUMMARY_GENERATION_STATUS = "UPDATE_SUMMARY_GENERATION_STATUS",

    /** SET SERVICE PROVIDERS SELECTED ON FILTER */
    SET_ALL_SERVICE_PROVIDERS_USED_IN_FILTER = "SET_ALL_SERVICE_PROVIDERS_USED_IN_FILTER",

    /* * SET AGGREGATED GROUPED PROGRESS REPORT **/
    SET_AGGREGATED_GROUPED_PROGRESS_REPORT = "SET_AGGREGATED_GROUPED_PROGRESS_REPORT",
    /*** SET TEAM AGGREGATED GROUPED PROGRESS REPORT */
    SET_TEAM_AGGREGATED_GROUPED_PROGRESS_REPORT = "SET_TEAM_AGGREGATED_GROUPED_PROGRESS_REPORT",

    /** update progress measured */
    UPDATE_PROGRESS_MEASURED_IN_REPORTING_DATA = "UPDATE_PROGRESS_MEASURED_IN_REPORTING_DATA",

    /** SET STATE WHILE PERFORMING AGGREGATION */
    SET_PERFORMING_AGGREGATION_LOADING = "SET_PERFORMING_AGGREGATION_LOADING",

    /** FINALISING PROGRESS  */
    SET_FINALIZING_PROGRESS_LOADER = "SET_FINALIZING_PROGRESS_LOADER",

    /** PROGRESS_REPORT_GENERATED_MASTER_COPY */
    SET_PROGRESS_REPORT_GENERATED_MASTER_COPY = "SET_PROGRESS_REPORT_GENERATED_MASTER_COPY",

    HANDLE_MAKING_ADEQUATE_PROGRESS = "HANDLE_MAKING_ADEQUATE_PROGRESS",

    MAP_TEAM_AGGREGATED_FINALIZED_DATA_TO_ROOT_PROGRESS_REPORT_UNDER_EACH_SERVICE_PROVIDERS = "MAP_TEAM_AGGREGATED_FINALIZED_DATA_TO_ROOT_PROGRESS_REPORT_UNDER_EACH_SERVICE_PROVIDERS"


}
