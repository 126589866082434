
export enum StorageKeys {
  ProgressReport = "progress-report"
}


/**
 * Loads something from storage and parses it using JSON.parse.
 *
 * @param key - The key to fetch.
 * @returns The parsed object, or `undefined` if not found or unable to parse.
 */
export function load<T>(key: string): T | undefined {
    try {
      const almostThere = localStorage.getItem(key);
      if (!almostThere) throw new Error("Value not found in storage");
      return JSON.parse(almostThere) as T;
    } catch (error) {
      console.log(`Error while loading object from storage: ${error}`);
      console.log(`Error while loading object from storage: using ${key}`);
      return undefined;
    }
  }
  
  /**
   * Saves an object to storage.
   *
   * @param key - The key to store the object under.
   * @param value - The object to store.
   */
  export function save<T>(key: string, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(`Error while saving object to storage: ${error}`);
    }
  }
  
  /**
   * Removes an item from storage.
   *
   * @param key - The key of the item to remove.
   */
  export function remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log(`Error while removing item from storage: ${error}`);
    }
  }
  
  /**
   * Clears all items in storage.
   */
  export function clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.log(`Error while clearing storage: ${error}`);
    }
  }