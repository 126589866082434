import { AppConfigurationClient } from "@azure/app-configuration";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store"; // Import RootState from your store
import { msalAccount } from "../../auth/msalEventCallback";
interface FeatureFlagsState {
  flags: Record<string, boolean>;
}

const initialState: FeatureFlagsState = {
  flags: {},
};

export const initializeFeatureFlags = createAsyncThunk(
  "featureFlags/fetch",
  async () => {
    try {
      const stateInUs = msalAccount?.idTokenClaims?.state; // use available stateInUs from source that contains data at time of initialization
      let connectionString;
      switch (stateInUs) {
        case "TX":
          connectionString = process.env.REACT_APP_CONFIG_CONNECTION_STRING_TX;
          break;
        case "NH":
          connectionString = process.env.REACT_APP_CONFIG_CONNECTION_STRING_NH;
          break;
        default:
          connectionString = process.env.REACT_APP_CONFIG_CONNECTION_STRING_TX;
          break;
      }
      const AppConfigClient = new AppConfigurationClient(connectionString);
      const settingsIterator = AppConfigClient.listConfigurationSettings({
        keyFilter: ".appconfig.featureflag/*",
      });
      const flags: Record<string, boolean> = {};
      for await (const setting of settingsIterator) {
        const valueObject = JSON.parse(setting.value!);
        const key = setting.key.replace(".appconfig.featureflag/", "");
        flags[key] = valueObject.enabled;
      }
      return flags;
    } catch (error) {
      console.error("Error fetching feature flags:", error);
      throw error;
    }
  },
);

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initializeFeatureFlags.pending, (state) => {})
      .addCase(
        initializeFeatureFlags.fulfilled,
        (state, action: PayloadAction<Record<string, boolean>>) => {
          state.flags = action.payload;
        },
      )
      .addCase(initializeFeatureFlags.rejected, (state, action) => {
        console.error("Failed to fetch feature flags:", action.error);
      });
  },
});
export const selectFeatureFlags = (state: RootState, index: string) =>
  state.featureFlags.flags[index];

export default featureFlagsSlice.reducer;

