import { useMemo } from "react";
import { useNotatorTools } from "../../../tools";
import { UnifiedGoal, useUnifiedGoals } from "./use_unified_goals";
import { GoalDisplay } from "../../../../../profile-sdk";
import { useGoalShowFlagUtilities } from "./use_goal_show_flag_utilities";

/**
 * Returns a reference map for the goals that should show for the given student, identified by their selected/visible student index.
 *
 * The goal data that renders to the notator itself is pulled from the student's care provision ledger attached to the session.
 */
export function useStudentGoalReferenceMap(props: {
  selectedStudentIndex: number;
  activeGoals: GoalDisplay[] | null;
}): UnifiedGoal[] {
  const { cachedResponsesByStudentIndex } = useNotatorTools();
  const { selectedStudentIndex, activeGoals } = props;

  const fetchedStudentProfilePrescribedGoalsAsGoalRefs = useMemo(
    () =>
      cachedResponsesByStudentIndex?.[selectedStudentIndex]?.studentProfile?.spedDossier
        ?.prescribedCareProvisionsLedger?.goals ?? [],

    [cachedResponsesByStudentIndex, selectedStudentIndex],
  );

  const unifiedGoals = useUnifiedGoals({
    goalRefs: fetchedStudentProfilePrescribedGoalsAsGoalRefs ?? [],
    goalDisplays: activeGoals ?? [],
  });

  const {
    initFlags,
    getListOfShownGoals,
    hideGoalsOutsideServiceArea,
    showGoalsIfAdditional,
    hideGoalsIfExcluded,
  } = useGoalShowFlagUtilities({ activeGoals, unifiedGoals, selectedStudentIndex });

  const goalVisibilityFlags = initFlags(true);

  hideGoalsOutsideServiceArea(goalVisibilityFlags);
  showGoalsIfAdditional(goalVisibilityFlags);
  hideGoalsIfExcluded(goalVisibilityFlags);

  const goals = getListOfShownGoals(goalVisibilityFlags);

  return goals;
}
