import { useCallback, useState } from "react";
import useEffectSkipMount from "../../../../hooks/use_effect_after_mount";
import useDataGridPagination from "../../hooks/use_data_grid_pagination";
import { API_CLIENT_SESSION_COUNTS } from "../../../../api/api";
import { PollRequest, useReportingPollRequest } from "../../hooks/use_polling_mechanism";
import useFetchNullableResponse from "../../../../hooks/use_fetch_nullable_response";
import { SchoolCampusRef } from "../../../../profile-sdk";
import { Dayjs } from "dayjs";
import { getUserTimeZoneByState } from "../../../../utils/timeZones";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import downloadFile from "../../../../utils/downloadFile";
import { useDataGridBase } from "../../../../hooks/use_datagrid_base";
import {
  ClientSessionCountsReportRecordUI,
  ClientSessionCountsReportRecordUIPaginatedReportResponse,
} from "@xng/reporting";
import { UNPOSTED_SESSIONS_REPORT_COLUMNS as columns } from "./columns";
import { DataGridProps, GridColDef, GridValidRowModel } from "@mui/x-data-grid";

interface RequestHandlerForUnpostedSessionsReport {
  handleRunClick: () => void;
  handleExportToCSVClick: () => void;
  tablePollRequest: PollRequest<ClientSessionCountsReportRecordUIPaginatedReportResponse>;
  csvPollRequest: PollRequest<Blob>;
  dataGridProps: { rows: readonly GridValidRowModel[]; columns: GridColDef[] };
  dataGridPaginationProps: Partial<DataGridProps>;
}

/**
 * Encapsulates details related to the API requests made for the table and CSV.
 */
export function useRequestHandlerForUnpostedSessionsReport(props: {
  reduxDependencies: { stateInUS: string; clientID: string };
  requestParameters: {
    selectedCampusOptions: SchoolCampusRef[];
    startDate: Dayjs;
    endDate: Dayjs;
    includeSPsWithoutCampusesCheckbox: boolean;
  };
}): RequestHandlerForUnpostedSessionsReport {
  const {
    requestParameters: {
      selectedCampusOptions,
      startDate,
      endDate,
      includeSPsWithoutCampusesCheckbox,
    },
    reduxDependencies: { stateInUS, clientID },
  } = props;

  const [runReportClickToggle, setRunReportClickToggle] = useState<boolean>(false);

  const reportRunParameters = useFetchNullableResponse(
    () =>
      API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsQueueReportPost({
        queueClientSessionCountReportPostRequest: {
          filterParameters: {
            clientId: clientID,
            campusIds: new Set(selectedCampusOptions.map((c) => c.id!)),
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            includeServiceProvidersWithoutCampus: includeSPsWithoutCampusesCheckbox,
          },
        },
      }),
    [runReportClickToggle],
    { skipMount: true },
  );

  // Export to CSV Poll Request & Callback
  const csvPollRequest = useReportingPollRequest({
    mutationFn: () =>
      API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsDownloadCsvPostRaw({
        getReportCsvFromRunPostRequest: {
          reportRunDate: reportRunParameters?.reportRunDate!,
          reportRunId: reportRunParameters?.reportRunId!,
          timeZoneId: getUserTimeZoneByState(stateInUS),
        },
      }),
    mutationKey: ["v1SessionReportsClientSessionCountsDownloadCsvPostRaw"],
  });

  // Polled Request for Table Rows & Pagination Controls
  const tablePollRequest = useReportingPollRequest({
    mutationFn: () =>
      API_CLIENT_SESSION_COUNTS.v1SessionReportsClientSessionCountsGetReportPostRaw({
        getPaginatedReportFromRunPostRequest: {
          pageParameters,
          reportRunDate: reportRunParameters!.reportRunDate,
          reportRunId: reportRunParameters!.reportRunId,
        },
      }),
    mutationKey: ["v1SessionReportsClientSessionCountsGetReportPostRaw"],
  });
  const { pageParameters, dataGridPaginationProps } = useDataGridPagination({
    rowCount: tablePollRequest.result?.totalRecords,
  });
  useEffectSkipMount(() => {
    if (reportRunParameters) {
      clearReportCache(queryClient);
      tablePollRequest.stop();
      tablePollRequest.start();
    }
  }, [reportRunParameters, pageParameters]);
  useEffectSkipMount(() => {
    if (reportRunParameters) {
      csvPollRequest.stop();
      csvPollRequest.start();
    }
  }, [reportRunParameters]);
  const handleExportToCSVClick = useCallback(() => {
    if (csvPollRequest.result) {
      downloadFile(csvPollRequest.result, "unpostedSessionsReport.csv");
    }
  }, [csvPollRequest.result]);

  // Data Grid Props Declaration
  const dataGridProps = useDataGridBase<ClientSessionCountsReportRecordUI>({
    rows: tablePollRequest.result?.pageRecords ?? [],
    columns,
  });

  // Clear Current Cache on Run Click
  const queryClient = useQueryClient();
  useEffectSkipMount(() => {
    clearReportCache(queryClient);
    clearCsvCache(queryClient);
  }, [runReportClickToggle]);

  return {
    handleRunClick: () => setRunReportClickToggle(!runReportClickToggle),
    tablePollRequest,
    handleExportToCSVClick,
    csvPollRequest,
    dataGridProps,
    dataGridPaginationProps,
  };
}

function clearReportCache(queryClient: QueryClient) {
  queryClient.removeQueries({
    queryKey: ["v1SessionReportsClientSessionCountsGetReportPostRaw"],
  });
}

function clearCsvCache(queryClient: QueryClient) {
  queryClient.removeQueries({
    queryKey: ["v1SessionReportsClientSessionCountsDownloadCsvPostRaw"],
  });
}
