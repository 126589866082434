import { useCallback } from "react";
import { GoalDisplay } from "../../../../../profile-sdk";
import { UnifiedGoal } from "./use_unified_goals";
import { useNotatorTools } from "../../../tools";
import { getGoalDisplayIsWithinServiceArea } from "../utils/goal_display_is_within_service_area";

/**
 * Provides the functions used to modify the list of goals that should render, effectively determining the show/hide conditions.
 */
export function useGoalShowFlagUtilities(props: {
  activeGoals: GoalDisplay[] | null;
  unifiedGoals: UnifiedGoal[] | null;
  selectedStudentIndex: number;
}) {
  const { activeGoals, unifiedGoals, selectedStudentIndex } = props;

  const initFlags = useCallback(
    (defaultFlag: boolean) =>
      new Map<UnifiedGoal, boolean>((unifiedGoals ?? []).map((goal) => [goal, defaultFlag])),
    [unifiedGoals],
  );

  const { goalAreasOfFocusInventory, draftSession } = useNotatorTools();

  function hideGoalsOutsideServiceArea(goalFlags: Map<UnifiedGoal, boolean>): void {
    const sessionServiceArea = draftSession.service?.area;

    if (!sessionServiceArea || !goalAreasOfFocusInventory) {
      return;
    }

    goalFlags.forEach((currentFlag, goal) => {
      const goalDisplayIsWithinServiceArea = getGoalDisplayIsWithinServiceArea(
        goal.goalDisplay,
        goalAreasOfFocusInventory,
        sessionServiceArea,
      );

      goalFlags.set(goal, goalDisplayIsWithinServiceArea);
    });
  }

  function showGoalsIfAdditional(goalFlags: Map<UnifiedGoal, boolean>): UnifiedGoal[] {
    if (!activeGoals) {
      return [];
    }

    const additionalGoalIDs =
      draftSession.studentJournalList?.[selectedStudentIndex].careProvisionLedger
        ?.additionalGoals ?? [];

    additionalGoalIDs.forEach((id) => {
      goalFlags.forEach((_, goal) => {
        if (goal.goalDisplay.internalId === id) {
          goalFlags.set(goal, true);
        }
      });
    });

    return Array.from(goalFlags.entries())
      .filter(([_, flag]) => flag)
      .map(([goal, _]) => goal);
  }

  function hideGoalsIfExcluded(goalFlags: Map<UnifiedGoal, boolean>): UnifiedGoal[] {
    if (!activeGoals) {
      return [];
    }

    const excludedGoalIDs =
      draftSession.studentJournalList?.[selectedStudentIndex].careProvisionLedger?.excludedGoals ??
      [];

    excludedGoalIDs.forEach((id) => {
      goalFlags.forEach((_, goal) => {
        if (goal.goalDisplay.internalId === id) {
          goalFlags.set(goal, false);
        }
      });
    });

    return Array.from(goalFlags.entries())
      .filter(([_, flag]) => flag)
      .map(([goal, _]) => goal);
  }

  function getListOfShownGoals(goalFlags: Map<UnifiedGoal, boolean>): UnifiedGoal[] {
    return Array.from(goalFlags.entries())
      .filter(([_, flag]) => flag === true)
      .map(([goal, _]) => goal);
  }

  return {
    initFlags,
    getListOfShownGoals,
    hideGoalsOutsideServiceArea,
    showGoalsIfAdditional,
    hideGoalsIfExcluded,
  };
}
