
/// Enum 
/// XLogsWorkshopOnSite = 0 
/// XLogsWorkshopVirtual = 1
/// XLogsAccountReview = 2
/// RMTSTrainingVideo = 3
/// MSBUserGroup = 4
/// ExpressTraining = 5

export default function GetTrainingTypeOptions(){
    return ["X Logs Workshop OnSite", "X Logs Workshop Virtual", "X Logs Account Review", "RMTS Training Video", "MSB User Group", "Express Training"]
}