import GridSectionLayout from "../../../../../design/high-level/common/grid_section_layout";
import React from "react";
import { SessionLogsSummaryDataRow } from "@xng/reporting";
import { useSessionLogsSummaryTableColumnDefinition } from "../hooks";
import { SessionLogsSummaryTable } from ".";

type Props = {
  layout: {
    onGenerateReportBtnClick: () => void;
    generateReportBtnDisabled: boolean;
  };
  table: {
    rows: SessionLogsSummaryDataRow[];
    selectedRowIds: string[];
    onRowSelected: (selectedRowIds: string[]) => void;
    dataLoading: boolean;
  };
};

/**
 * Renders a summary of session logs.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {React.Ref<HTMLDivElement>} ref - The ref for the component.
 * @returns {JSX.Element} The rendered SessionLogsSummary component.
 */
export const SessionLogsSummary = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref?: React.Ref<HTMLDivElement>) => {
    const tableColumnDef = useSessionLogsSummaryTableColumnDefinition();

    return (
      <GridSectionLayout
        ref={ref}
        headerConfig={{
          title: "Summary",
          title_sx: {
            fontWeight: 700,
          },
          useHeaderButton: {
            label: "Generate Report",
            disabled: props.layout.generateReportBtnDisabled,
            sx: {
              // width:"178px",
              px: "2rem",
              py: "1.5rem",
            },
            onClick: props.layout.onGenerateReportBtnClick,
          },
        }}
        bottomMargin={"10rem"}
        rows={[
          {
            fullwidth: true,
            cells: [
              <SessionLogsSummaryTable
                key="session-logs-summary-table"
                columnDef={tableColumnDef}
                rows={props.table.rows}
                rowSelectionModel={props.table.selectedRowIds}
                onRowSelectionModelChange={(selectedRowIds) =>
                  props.table.onRowSelected(selectedRowIds as string[])
                }
                loading={props.table.dataLoading}
              />,
            ],
          },
        ]}
      />
    );
  },
);
