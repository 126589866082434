import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  headerText: string;
  listItems: ApprovalsModalListItemsType;
  badItemsIds?: string[];
  badItemsText?: string;
  variant: "error" | "success";
};

type ListItem = {
  id: string;
  itemName: string;
};

export type ApprovalsModalListItemsType = Map<string, ListItem>;

const ApprovalsModalListItemsContent = (props: Props) => {
  return (
    <Box>
      <Typography variant="body1" color={props.variant}>
        {props.headerText}
      </Typography>
      {props.badItemsIds?.length && props.badItemsIds?.length > 0 && (
        <Typography variant="body2" color={"error"}>
          {props.badItemsText}
        </Typography>
      )}
      <Box
        component={"ul"}
        sx={{
          maxHeight: "80px",
          bgcolor: "contrasts.1",
          overflowY: "auto",
          p: 1,
          borderRadius: "5px",
        }}
      >
        {Array.from(props.listItems.values()).map((item) => (
          <Box component={"li"} key={item.id}>
            <Typography
              variant="body1"
              textAlign={"left"}
              color={props.badItemsIds?.includes(item.id) ? "error.main" : "text.primary"}
            >
              {item.itemName}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ApprovalsModalListItemsContent;
