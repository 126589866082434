import { useMemo } from "react";
import { ServiceProviderRef } from "../../../../profile-sdk";

const useSelectedProvidersHandler = (props: {
  onChangeSelectedProviderIds: (selectedProviderIds: string[]) => void;
  providers: ServiceProviderRef[];
  selectedProviderIds: string[];
}) => {
  const { onChangeSelectedProviderIds, providers, selectedProviderIds } = props;

  const selectedProviders = useMemo(() => {
    return providers.filter((provider) => selectedProviderIds.includes(provider.id ?? ""));
  }, [providers, selectedProviderIds]);

  const setSelectedProvidersHandler = (selected: ServiceProviderRef[]) => {
    onChangeSelectedProviderIds(selected.map((p) => p.id ?? ""));
  };

  return {
    selectedProviders,
    setSelectedProviders: setSelectedProvidersHandler,
  };
};

export default useSelectedProvidersHandler;
