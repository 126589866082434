import { Box, SxProps, useTheme } from "@mui/material";

export function CodeBlock(props: { readonly literal: any; disablePreWrap?: boolean }) {
  const { palette } = useTheme();
  const disablePreWrap = props.disablePreWrap ?? false;

  const CODE_STYLE: SxProps = {
    pre: {
      boxShadow: 1,
      bgcolor: palette.text.primary,
      color: palette.getContrastText(palette.text.primary),
      borderRadius: "4px",
      padding: ".5rem .5rem",
      code: { boxShadow: 0 },
      whiteSpace: disablePreWrap ? "nowrap" : "pre-wrap",
      margin: "unset",
    },
    code: {
      boxShadow: 1,
      bgcolor: palette.text.primary,
      color: palette.getContrastText(palette.text.primary),
      borderRadius: "4px",
    },
  };

  return (
    <Box sx={CODE_STYLE}>
      <pre>
        <code>{JSON.stringify(props.literal, undefined, 2)}</code>
      </pre>
    </Box>
  );
}
