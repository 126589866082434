import { ClientAssignment } from "../profile-sdk";

export function getLoggedInClientAssignment(
  clientAssignments: ClientAssignment[],
  clientID: string,
): ClientAssignment {
  const matches = clientAssignments.filter((ca) => ca.client?.id === clientID);
  if (matches.length > 1) console.error("Multiple client assignments detected!");

  return matches[0];
}
