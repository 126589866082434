import * as yup from "yup";

import dayjs from "dayjs";
import { sessionDateFilterOptions } from "../utils";

/**
 * Defines the schema for the session log's view filters form data.
 */
export const sessionLogsFormSchema = yup.object().shape({
  sessionDateFilter: yup
    .string()
    .oneOf(sessionDateFilterOptions)
    .required("Please select a date range.")
    .test("sessionDateFilter", "Please select a valid date range.", function (value) {
      const { startYear, endYear, week, startDate, endDate } = this.parent.dateFilterOptions;

      if (value === sessionDateFilterOptions[0]) {
        if (!startYear || !endYear || !week.start || !week.end || startYear > endYear) {
          return false;
        }
      } else if (value === sessionDateFilterOptions[1] || value === sessionDateFilterOptions[2]) {
        if (!startDate || !endDate) {
          return false;
        }
      }

      return true;
    })
    .test(
      "sessionDateFilterDateRange",
      "The end date must be after The start date",
      function (value) {
        const { startDate, endDate } = this.parent.dateFilterOptions;

        if (value === sessionDateFilterOptions[1]) {
          if (!startDate || !endDate) {
            return false;
          }
          if (dayjs(startDate).isAfter(dayjs(endDate))) {
            return false;
          }
        } else {
          return true;
        }

        return true;
      },
    ),
  dateFilterOptions: yup.object().shape({
    startYear: yup.string(),
    endYear: yup.string(),
    week: yup.object().shape({
      start: yup.date(),
      end: yup.date(),
      weekNumber: yup.number().nullable(),
    }),
    startDate: yup.date(),
    endDate: yup.date(),
  }),
  sessionFilter: yup
    .array()
    .of(yup.string().required())
    .min(1, "Please select at least one session filter."),
  showIepServiceData: yup.boolean(),
  studentWithSession: yup.boolean(),
  medicalEligibleOnly: yup.boolean(),
  providerAbsent: yup
    .string()
    .oneOf(["Both", "Yes", "No"])
    .required("Please select a provider absent filter."),
  makeUpSession: yup
    .string()
    .oneOf(["Both", "Yes", "No"])
    .required("Please select a make up session filter."),
  serviceProviders: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        firstName: yup.string(),
        lastName: yup.string(),
        email: yup.string(),
      }),
    )
    .min(1, "Please select at least one service provider."),
  dols: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string(),
      }),
    )
    .min(1, "Please select at least one district of liability."),
  serviceTypes: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string(),
      }),
    )
    .min(1, "Please select at least one service provider type."),
  schools: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Please select at least one school."),
});

/**
 * Represents the type of the form data for filters in the session logs form.
 */
export type SessionLogsFormType = yup.InferType<typeof sessionLogsFormSchema>;
