import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export class ErrorEntry {
  titleID: string;
  message?: string;
  codeBlockMessage?: string;
  tryCatchErrorMessage?: unknown;

  constructor({
    titleID,
    message,
    codeBlockMessage,
    tryCatchErrorMessage,
  }: {
    titleID: string;
    message?: string;
    codeBlockMessage?: string;
    tryCatchErrorMessage?: unknown;
  }) {
    this.titleID = titleID;
    this.message = message;
    this.codeBlockMessage = codeBlockMessage;
    this.tryCatchErrorMessage = tryCatchErrorMessage;
  }
}

const initialState: ErrorEntry[] = [];

const errorSlice = createSlice({
  name: "errorEntries",
  initialState,
  reducers: {
    pushError: (state, action: PayloadAction<ErrorEntry>) => {
      const {
        titleID,
        codeBlockMessage,
        message,
        tryCatchErrorMessage: unknownOrAnyError,
      } = action.payload;
      state.push({ titleID, codeBlockMessage, message, tryCatchErrorMessage: unknownOrAnyError });
    },
    clearErrors: () => {
      return [];
    },
  },
});

export const { pushError, clearErrors } = errorSlice.actions;

export const selectErrorEntries = (state: RootState) => state.errorEntriesReducer;

export default errorSlice.reducer;
