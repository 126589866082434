import { ProgressReportingActionType } from "./progress_reporting_actions";
import { ReportStatus, ReportTypes } from "./reducer/progress_reporting_reducer";

export class ProgressReportingPayloadManager { 
    static updateGeneralCommentsPayloadOf(
        goalId:string,
        period:number,
        generalComment:string,
        progressReportType:ReportTypes.Goal = ReportTypes.Goal,
        rowPosition:number
      ) {
        return {
          type: ProgressReportingActionType.UPDATE_GENERAL_COMMENTS_IN_REPORTING_DATA,
          payload: {
            goalId,
            period,
            generalComment,
            progressReportType,
            rowPosition
          }
        }
    } 


    static updateStatusPayloadOf(
        goalId:string,
        period:number,
        status:string,
        progressReportType:ReportTypes,
        rowPosition:number
      ) {
        return{ 
          type:ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
          payload:{ goalId, period , progressReportType,status, rowPosition}
        }
    }
}

export class GeneralCommentsPayloadManager{
    goalId:string
    objectiveId?:string
    period:number
    generalComment:string
    progressReportType:ReportTypes
    rowPosition:number;
    objectiveProgressArrayIndex:number 
    static of(goalId:string, period:number, generalComment:string, progressReportType:ReportTypes, rowPosition:number,objectiveId?:string,objectiveProgressArrayIndex?:number){
      return {
        goalId,
        period,
        generalComment,
        progressReportType,
        rowPosition,
        objectiveId,
        objectiveProgressArrayIndex
      }
    }
}

export class GoalObjectiveStatusPayloadManager{
    goalId:string
    period:number
    status:string
    progressReportType:ReportTypes
    rowPosition:number
    objectiveId?:string
    objectiveProgressArrayIndex?:number

    constructor(goalId:string, period:number, progressReportType:ReportTypes, status:ReportStatus, rowPosition:number, objectiveId?:string){
      this.goalId = goalId
      this.period = period
      this.status = status
      this.progressReportType = progressReportType
      this.rowPosition = rowPosition
    }
    static of(goalId:string, period:number, progressReportType:ReportTypes, status:ReportStatus, rowPosition:number,objectiveId?:string,objectiveProgressArrayIndex?:number){
      return {
        goalId,
        period,
        status,
        progressReportType,
        rowPosition,
        objectiveId,
        objectiveProgressArrayIndex
      }
    }
}


export class ProgressMeasuredPayloadManager{
  goalId:string
  period:number
  progressReportType:ReportTypes
  rowPosition:number 
  progressMeasured:number
  objectiveId?:string
  objectiveProgressArrayIndex?:number
  constructor(goalId:string, period:number, progressReportType:ReportTypes, rowPosition:number,   progressMeasured:number,   objectiveId?:string,objectiveProgressArrayIndex?:number){
    this.goalId = goalId
    this.period = period
    this.progressReportType = progressReportType
    this.rowPosition = rowPosition 
    this.progressMeasured = progressMeasured
  }

  static of (goalId:string, period:number, progressReportType:ReportTypes, rowPosition:number,progressMeasured:number,objectiveId?:string,objectiveProgressArrayIndex?:number){
    return {
      goalId,
      period,
      progressReportType,
      rowPosition, 
      progressMeasured,
      objectiveId,
      objectiveProgressArrayIndex
    }
  }
}

export class IsMakingAdequateProgressPayloadManager{
  goalId:string
  period:number
  progressReportType:ReportTypes
  rowPosition:number 
  isMakingAdequateProgress:boolean
  objectiveId?:string
  objectiveProgressArrayIndex?:number
  constructor(goalId:string, period:number, progressReportType:ReportTypes, rowPosition:number,   isMakingAdequateProgress:boolean,   objectiveId?:string,objectiveProgressArrayIndex?:number){
    this.goalId = goalId
    this.period = period
    this.progressReportType = progressReportType
    this.rowPosition = rowPosition 
    this.isMakingAdequateProgress = isMakingAdequateProgress
  }

  static of (goalId:string, period:number, progressReportType:ReportTypes, rowPosition:number,isMakingAdequateProgress:boolean,objectiveId?:string,objectiveProgressArrayIndex?:number){
    return {
      goalId,
      period,
      progressReportType,
      rowPosition, 
      isMakingAdequateProgress,
      objectiveId,
      objectiveProgressArrayIndex
    }
  }
}


export class MapUserInputsToRootPayloadManager{
  goalId:string
  period:number
  status:string
  progressReportType:ReportTypes
  rowPosition:number
  objectiveId?:string
  objectiveProgressArrayIndex?:number
  progressMeasured?:number
  isMakingAdequateProgress?:boolean
  generalComments?:string

  constructor(goalId:string, period:number, progressReportType:ReportTypes, status:ReportStatus, rowPosition:number, objectiveId?:string){
    this.goalId = goalId
    this.period = period
    this.status = status
    this.progressReportType = progressReportType
    this.rowPosition = rowPosition
  }
  static of(
    goalId:string, 
    period:number,
    progressReportType:ReportTypes,
    status:ReportStatus,
    rowPosition:number,
    objectiveId?:string,
    objectiveProgressArrayIndex?:number,
    progressMeasured?:number,
    isMakingAdequateProgress?:boolean,
    generalComments?:string
  ){
    return {
      goalId,
      period,
      status,
      progressReportType,
      rowPosition,
      objectiveId,
      objectiveProgressArrayIndex,
      progressMeasured,
      isMakingAdequateProgress,
      generalComments
    }
  }
} 