
import { StudentProgressReportRecordUIPaginatedReportResponse } from "@xng/reporting";
import {ReportingPeriod, SchoolCampusRef, ServiceProviderCaseloadOption, ServiceProviderResponse, UserResponse} from "../../../../../profile-sdk";
import { ProgressReportingAction } from "../context/progress_reporting_actions";
import { ExtendedStudentProgressReportRecordUIPaginatedReportResponse, GroupedStudentReport } from "./extended_progress_repoting_types";


export enum PollingStatus{
    Idle = "idle",
    Loading = "loading",
    Error = "error", 
}
 
export enum  SummaryGenerationStatus{
    Idle = "idle",
    Loading = "loading",
    Error = "error",
    ReportGenerationRequestParamChanged = "param_changed",
    Success = "success"
}

export interface ProgressReportingStateType { 
    error: string | undefined
    loading: boolean 
    isEditing: boolean, 
    currentlyLoggedInServiceProvider:ServiceProviderResponse | undefined,
    currentlyLoggedInUserProfile:UserResponse | undefined,
    campusesSelectedForProgressReporting:SchoolCampusRef|undefined,
    allServiceProvidersUnderSelectedCampuses:ServiceProviderCaseloadOption[],
    schoolYearRange:string|undefined,
    reportingPeriods:ReportingPeriod[],
    studentProgressReportFetched:ExtendedStudentProgressReportRecordUIPaginatedReportResponse|undefined ,
    settingsPopup :{
         showObjectives:boolean,
         showChart:boolean,
         showAggregatedData:boolean,
         showLastSession:boolean,
         showTeamAggregatedData:boolean,
         showActiveGoals:boolean,
         serviceProviderSelected:ServiceProviderResponse|undefined
    },
 
    /** student progress report transformed with post unpost status */
     studentProgressReportTransformed:ExtendedStudentProgressReportRecordUIPaginatedReportResponse | undefined,
    printingSignal:boolean,
    isGeneratingXLSX:boolean,
    pollingStatus:PollingStatus,
    summaryGenerationStatus: SummaryGenerationStatus,
    serviceProvidersSelectedOnFilter:ServiceProviderResponse[],
    aggregatedProgressReport:GroupedStudentReport[]|undefined,
    teamAggregatedProgressReport:GroupedStudentReport[]|undefined,
    isPerformingAggregation:boolean,
    isFinalizingProgressReport:boolean,
    progressReportGeneratedMasterCopy:StudentProgressReportRecordUIPaginatedReportResponse | undefined
    
}

type ProgressReportingContextType = {
    state : ProgressReportingStateType,
    dispatch: React.Dispatch<ProgressReportingAction>;
}

export default ProgressReportingContextType;