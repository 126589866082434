import { Alert, Box } from "@mui/material";

export function StaffDirectoryDataGridNoRowsOverlay(message: string) {
  return () => (
    <Box
      aria-label="emtpy table"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Alert severity="info" sx={{ width: "80%" }}>
        {message}
      </Alert>
    </Box>
  );
}
