import { ExtendedStudentProgressDetailsWithAggregatedProgress } from "../types/extended_progress_repoting_types";
import { getProgress } from "./progress_helper";
export class LineChartType {
    date: number;
    value: number;
    constructor( date: number, value: number) {
        this.date = date;
        this.value = value;
    }

    static of(date: number, value: number) {
        return {date,value}
    }
    
}

export class ProgressLineChartHelper{
    static transformToLineChartData(data:ExtendedStudentProgressDetailsWithAggregatedProgress[],isTeamAggregated: boolean,isLastSession: boolean):LineChartType[] {
 
        return data.map(item =>  LineChartType.of(new Date(item?.periodStartDate as unknown as string).getTime(), getProgress(isTeamAggregated,isLastSession,item)));
    }
}