import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { MdArrowDropDown } from "react-icons/md";
import { Box, Checkbox, Input } from '@mui/material';
import { BiSolidPencil } from "react-icons/bi";
import IfElseBox from '../../../design/components-dev/if_else_box';
import ZINDEX_LAYERS from '../../../constants/zIndexLayers';
import usePalette from '../../../hooks/usePalette'; 
import { ProvidedActivity } from '../../../session-sdk'; 
import { useNotatorTools } from '../tools';


const multiply = (a:number,b:number)=>(a??0)*(b??0)


interface AccordionProps {accordionTitle?:string,activitiesCaresProvision:ProvidedActivity,setTimeForAdlIadl:(name:string,totalMinutes:number,increment:number,averageMinutesPerTime:number)=>void,selectedStudentIndex?:number}
export default function CollapsableAccordionRoot({accordionTitle="Temp placeholder",selectedStudentIndex,activitiesCaresProvision,setTimeForAdlIadl}:Readonly<AccordionProps>) {
    const [expanded, setExpanded] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [isTotalMinutesEnabled, setTotalMinutesEnabled] = React.useState(false);
    const [timeUsed, setTimeUsed] = React.useState<{numberofTimes: number,averageMinutes: number,totalMinutes: number}>({
        numberofTimes: 0,
        averageMinutes: 0,
        totalMinutes: 0
      
    });
    const [error,setError] = React.useState({
      numberOfTimes:{
         message:"",
         isError:false
      },
      averageMinutes:{
         message:"",
         isError:false
      },
      totalMinutes:{
         message:"",
         isError:false
      } 
    })   
    const accordionRef = React.useRef<HTMLDivElement>(null);
    const palette = usePalette(); 
    const { readOnly } = useNotatorTools();

    React.useEffect(() => {
      if (activitiesCaresProvision?.increments && activitiesCaresProvision.increments > 0) {
        setChecked(true);
      }
      if (
        activitiesCaresProvision &&
        (activitiesCaresProvision.increments ?? -1) >= 0 &&
        (activitiesCaresProvision.totalMinutesSpent ?? -1) >= 0 &&
        (activitiesCaresProvision.averageMinutes ?? -1) >= 0
      ) {
        const totalMinutesCalculated = activitiesCaresProvision.totalMinutesSpent ?? 0;
    
        setTimeUsed({
          averageMinutes: activitiesCaresProvision.averageMinutes ?? 0,
          numberofTimes: activitiesCaresProvision.increments ?? 0,
          totalMinutes: totalMinutesCalculated,
        });
      }else {
        setTimeUsed({
          averageMinutes: 0,
          numberofTimes: 0,
          totalMinutes: 0,
        });
        setTotalMinutesEnabled(false)
        setChecked(false)
      }

    }, [activitiesCaresProvision,selectedStudentIndex])

    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if((checked && !isTotalMinutesEnabled && (timeUsed?.averageMinutes === 0 || !Number(timeUsed?.averageMinutes)))){ 
          return
         }
 
        if (accordionRef.current && !accordionRef.current.contains(event.target as Node)) {
          setExpanded(false);
        }
      };
  
      if (expanded) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [expanded,checked,timeUsed.averageMinutes,isTotalMinutesEnabled]);

    const handleChange = () => {
      if(readOnly)return
      setTimeUsed(prev=>{
        return {...prev,numberofTimes:prev.numberofTimes??0,averageMinutes:prev.averageMinutes??0}})
      // if there is a error in average minutes and the user did not enter a custom total minutes then dont allow him to close the accordion
      if(error.averageMinutes.isError && !isTotalMinutesEnabled) return  setExpanded(true)
      setExpanded(!expanded);
    };
  
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation()
      setChecked(event.target.checked);
      if(event.target.checked){
        setExpanded(true);
        const checkedTime = {...timeUsed,numberofTimes: 1}
        const totalMinutesCalculated = isTotalMinutesEnabled ? checkedTime.totalMinutes : multiply(checkedTime.averageMinutes,checkedTime.numberofTimes) 
        setTimeUsed({...checkedTime,totalMinutes:totalMinutesCalculated})
        if(timeUsed?.averageMinutes === 0 && !isTotalMinutesEnabled){
           setError(prev=>({...prev,averageMinutes:{message:"Cannot be empty or zero",isError:true}}))
           return 
        } 
        setTimeForAdlIadl(accordionTitle, totalMinutesCalculated, checkedTime.numberofTimes??0, checkedTime.averageMinutes??0)
      }else{
        setExpanded(false);
        const checkedTime = {...timeUsed,numberofTimes: 0, averageMinutes: 0}
        const totalMinutesCalculated = isTotalMinutesEnabled ? checkedTime.totalMinutes : multiply(checkedTime.numberofTimes,checkedTime.averageMinutes) 
        setTimeUsed({...checkedTime,totalMinutes:totalMinutesCalculated}) 
        setError(prev=>({...prev,averageMinutes:{message:"",isError:false}}))
        setTimeForAdlIadl(accordionTitle, totalMinutesCalculated, checkedTime.numberofTimes??0, checkedTime.averageMinutes??0)
      }
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
      const { name, value } = event.target; 
      const isTotalField = name === "totalMinutes";
      const isOnCustomTotalMinutesModeAndNumberOfTimesChanged = name === "numberofTimes" && isTotalMinutesEnabled
      const isAverageField = name === "averageMinutes"; 
      let isError = false
      if(name === "numberofTimes" && Number(value) >0){
        setChecked(true)
      }
      if(isAverageField && (!Number(value) || Number(value) === 0) && !isTotalMinutesEnabled){
          isError = true
          setError(prev=>{
          return {...prev,averageMinutes:{message:"Cannot be empty or zero",isError}}
          })
      } else if((isAverageField && Number(value) > 0 && !isTotalMinutesEnabled) || Number(timeUsed.averageMinutes) > 0 && !isTotalMinutesEnabled){ 
          isError = false
          setError(prev=>{
          return {...prev,averageMinutes:{message:"",isError:false}}
          })
      }

      if(Number(value) < 0){ 
        setTimeUsed((prevTimeUsed) => {
          const updatedState = {...prevTimeUsed,[name]:0} 
          const calculated = checkField(Number(value) < 0, isTotalField, !isOnCustomTotalMinutesModeAndNumberOfTimesChanged, updatedState)
          return ({...updatedState,totalMinutes:calculated})});
         
      }else{
        setTimeUsed((prevTimeUsed) =>{ 
            const updatedState = {...prevTimeUsed,[name]: value}
            const calculated = checkField(false,isTotalField,!isOnCustomTotalMinutesModeAndNumberOfTimesChanged,updatedState)
            return {...updatedState,totalMinutes:calculated}
          });
      } 
      

    };
    const handleTimeOnBlur = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
      if(!event)return 
      const { name, value } = event.target; 
      const isTotalField = name === "totalMinutes"; 
      const isAverageField = name === "averageMinutes";
      const isOnCustomTotalMinutesModeAndNumberOfTimesChanged = name === "numberofTimes" && isTotalMinutesEnabled
      let isError = false

      if((isOnCustomTotalMinutesModeAndNumberOfTimesChanged && !value) || (isOnCustomTotalMinutesModeAndNumberOfTimesChanged && Number(value) === 0)){
          setChecked(false)
      }

      if(isAverageField && (!value || Number(value) === 0) && !isTotalMinutesEnabled){
           isError = true
           setError(prev=>{
            return {...prev,averageMinutes:{message:"Cannot be empty or zero",isError}}
           })
      } else if(Number(timeUsed.averageMinutes) >0 && !isTotalMinutesEnabled){
           isError = false
           setError(prev=>{
            return {...prev,averageMinutes:{message:"",isError}}
           })
      }

      if(Number(value) < 0 || !value){  
          const updatedState = {...timeUsed,[name]:0}
          const calculated = checkField(Number(value) < 0, isTotalField, !isOnCustomTotalMinutesModeAndNumberOfTimesChanged, updatedState)
          setTimeUsed({...updatedState,totalMinutes:calculated});
          if(isError) return 
          setTimeForAdlIadl(accordionTitle,calculated,updatedState.numberofTimes ?? 0,updatedState.averageMinutes ?? 0) 
      }else{ 
          const updatedState = {...timeUsed,[name]: value}
          const calculated = checkField(false,isTotalField,!isOnCustomTotalMinutesModeAndNumberOfTimesChanged,updatedState)
          setTimeUsed({...updatedState,totalMinutes:calculated});
          if(isError) return 
          setTimeForAdlIadl(accordionTitle,calculated,updatedState.numberofTimes ?? 0,updatedState.averageMinutes ?? 0) 
      }   
    };

    const hanldePencileIconClick = React.useCallback(() => {
      setTotalMinutesEnabled((prevIsPencil) => !prevIsPencil); 
    },[])

    const handleReset = React.useCallback(()=>{
      setTotalMinutesEnabled((prevIsPencil) => !prevIsPencil); 
      setTimeUsed(prev=>{
        const updatedState = {...prev,totalMinutes:multiply(prev.numberofTimes,prev.averageMinutes)}
        setTimeForAdlIadl(accordionTitle,updatedState.totalMinutes,updatedState.numberofTimes,updatedState.averageMinutes)
        return updatedState
      })
    },[])

    function checkField(valueNotAllowed: boolean,isTotalField:boolean,shouldCalculate:boolean,time:{ numberofTimes: number;averageMinutes: number;  totalMinutes: number }):number{
      if(isTotalField&&valueNotAllowed){
          return 0 
      }else if(!isTotalField && shouldCalculate){ 
          return multiply(time.numberofTimes,time.averageMinutes)
      }else if(!isTotalField && !shouldCalculate){ 
          return time.totalMinutes
      }
      return time.totalMinutes 
}
    return ( 
        <Accordion
          disableGutters
          ref={accordionRef}
          expanded={expanded}
          onChange={handleChange} 
          sx={{ 
            borderRadius: "8px",
            boxShadow: "none",
            '&:before': { display: 'none' }, 
            width:"100%",
            position:"relative",  
            '&:hover': {
              cursor: readOnly ? "default !important" : "pointer",
            },
            '& *': {
              cursor: readOnly ? "default !important" : "pointer",
            }
         
          }}
        >
          <AccordionSummary
            expandIcon={<MdArrowDropDown size={24} />}
            sx={{
              backgroundColor: !expanded ? "inherit" : "#e0e0e0", 
              '&:hover': {
                backgroundColor: readOnly ? "inherit" : (expanded?"#e0e0e0":palette.contrasts[4]),
                cursor:readOnly? "cell":"pointer"
              },
              '& .MuiAccordionSummary-content': { margin: 0 },
              padding:0
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center"}}>
              <Box onClick={(e)=>e.stopPropagation()}>
                  <Checkbox
                  size="small"
                  sx={{
                    ".MuiTouchRipple-root": { display: "none" },
                    zIndex: ZINDEX_LAYERS.xngCheckbox,
                    svg: { color: readOnly?"inherit":(checked? palette.primary[2]:"#c1c1c1") },
              
                  }}
                    checked={checked}
                    disabled={readOnly}
                    onChange={handleCheckboxChange}
                    onClick={(e) =>{ 
                      e.stopPropagation() 
                    }}  // Prevents the accordion toggle on checkbox click
                  />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center",gap:"20px" }}>
                  <Typography sx={{fontSize:"14px",color:readOnly?"gray":"inherit"}}>{accordionTitle}</Typography>
                  {!expanded && 
                  <Typography sx={{color:readOnly?"gray":palette.primary[2]}} variant="body1">
                    {`| ${activitiesCaresProvision?.increments ?? 0} times 
                    ${activitiesCaresProvision?.totalMinutesSpent ?? 0} min`}
                  </Typography>}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "16px", backgroundColor: "#f5f5f5", display: "flex", flexDirection: "column", gap: "10px",position:"absolute",width:"100%",zIndex:"100" }}>
            <CollapsableAccordionContent onBlur={handleTimeOnBlur} name="numberofTimes" val={timeUsed.numberofTimes} onChange={handleTimeChange}  title="Number of times" />
            <IfElseBox 
             if={isTotalMinutesEnabled} 
             else={<CollapsableAccordionContent onBlur={handleTimeOnBlur} error={error.averageMinutes.isError} name="averageMinutes" val={timeUsed.averageMinutes} onChange={handleTimeChange} title="Average minutes per time" />}  then={<></>}/>
            <CollapsableAccordionContentWithIcon onBlur={handleTimeOnBlur} icon={
              <IfElseBox
               if={!isTotalMinutesEnabled}
               then={
               <Box onClick={hanldePencileIconClick} sx={{'&:hover':{cursor:"pointer",backgroundColor:palette.contrasts[3]},display:"flex",justifyContent:"center",alignItems:"center",width:"32px",height:"32px",borderRadius:"50%"}}>
                      <BiSolidPencil size={22}  />
               </Box>
               }
               else={<Typography onClick={handleReset} color={"primary"} sx={{fontWeight:"bold",cursor:"pointer"}}>Reset</Typography>}
              />  } disabled={!isTotalMinutesEnabled} name="totalMinutes" val={timeUsed.totalMinutes} onChange={handleTimeChange} title="Total minutes" />
          </AccordionDetails>
        </Accordion> 
    );
  }

interface CollapsableAccordionContentProps{ 
  title: string;
  val:number;
  onBlur: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  name:string,
  error?:boolean,
  errorText?:string,
  disabled?:boolean
}

export const CollapsableAccordionContent: React.FC<CollapsableAccordionContentProps> = (props) => {
  const { title,val,onChange,name,disabled=false,onBlur,error=false,errorText="" } = props
  const palette = usePalette();
  const SX = {
    width: "80px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: "white",
    border: error?`2px solid ${palette.danger[2]}`:"",
    padding: "4px",
    "&:before": { display: "none" },       // Removes default MUI underline before element
    "&:after": { display: "none" },        // Removes default MUI underline after element
    "& .MuiInputBase-root": { borderRadius: "5px" }, // Ensures border radius on main input
    "&:focus": {
      outline: "none",                      // Removes blue outline on focus
    } 
  }
    return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography>
        {title}
      </Typography>
      <Input name={name} onBlur={onBlur} disabled={disabled} value={val} onChange={onChange} sx={SX} type="number"/>
    </Box>
  );
};
export const CollapsableAccordionContentWithIcon: React.FC<{ title: string,val:number,onBlur: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,name:string,disabled?:boolean,icon:JSX.Element}> = ({ title,val,onChange,name,disabled=false,icon,onBlur }) => {
  const SX = {
    width: "80px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: "white",
    padding: "4px",
    "&:before": { display: "none" },       // Removes default MUI underline before element
    "&:after": { display: "none" },        // Removes default MUI underline after element
    "& .MuiInputBase-root": { borderRadius: "5px" }, // Ensures border radius on main input
    "&:focus": {
      outline: "none",                      // Removes blue outline on focus
    },
  }
    return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography>
        {title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          {icon}
          <Input onBlur={onBlur} name={name} disabled={disabled} value={val} onChange={onChange} sx={SX} type="number"/>
      </Box>
    </Box>
  );
};

