import { Box, Button, Typography } from "@mui/material";
import { GetGoalAreasOfFocusResponse, Goal, StudentResponse } from "../../../../../../profile-sdk";
import XNGInput2 from "../../../../../../design/low-level/input_2";
import XNGDropDown from "../../../../../../design/low-level/dropdown2";
import { XNGDateField } from "../../../../../unposted_sessions/components/common/date_field";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_STATESNAPSHOTS } from "../../../../../../api/api";
import dayjs from "dayjs";
import GoalInfoTabHeader from "./goal_info_tab_header";
import GoalFromType, { schema } from "../../../types/goal_objective_tab_form_schema_type";
import GridSectionLayout from "../../../../../../design/high-level/common/grid_section_layout";
import { useStudentProfileContext } from "../../../../context/context";
import usePalette from "../../../../../../hooks/usePalette";
import { DualActionModal, XNGICONS, XNGIconRenderer } from "../../../../../../design";
import isEqual from "lodash.isequal";

type Props = {
  edited_student: StudentResponse;
  selected_goal: Goal;
  onBackBtnClick: () => void;
};

/**
 * Component responsible for creating and editing goals.
 */
const GoalCreateEditView = (props: Props) => {
  //#region DROPDOWN OPTIONS
  const [goal_area_of_focus_options, set_goal_area_of_focus_options] =
    React.useState<GetGoalAreasOfFocusResponse>();
  //#endregion

  //#region HOOKFORM
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { isValid, errors, isDirty },
    reset,
  } = useForm<GoalFromType>({
    resolver: yupResolver(schema),
    defaultValues: {
      goal: {
        number: props.selected_goal?.number || "",
        description: props.selected_goal?.description || "",
        goal_area_of_focus: props.selected_goal?.goalAreaOfFocus || undefined,
        start_date: props.selected_goal?.startDate || undefined,
        end_date: props.selected_goal?.endDate || undefined,
        status: props.selected_goal?.status || "",
      },
      objectives:
        props.selected_goal?.objectives && props.selected_goal.objectives.length > 0
          ? props.selected_goal?.objectives
          : [
              {
                number: "",
                description: "",
              },
            ],
    },
  });
  //#endregion

  const {
    backButtonClicked,
    setBackButtonClicked,
    setIsDirty,
    setIsCreatingGoal,
    setInEditGoalView,
    savingGoal,
    goalData,
    setGoalData,
    onSubmitGoal,
  } = useStudentProfileContext();

  //#region FORM STATES
  const objectives_inputs = watch("objectives");
  const is_form_valid = isValid;
  const form_edited = isDirty;
  //#endregion

  //#region SIDE EFFECTS
  // strictly for fetching goal area of focus options
  React.useEffect(() => {
    const fetch = async () => {
      const aof = await API_STATESNAPSHOTS.v1StateSnapshotsGoalAreasOfFocusGet("TX");
      set_goal_area_of_focus_options(aof);
      // console.log("aof", aof)
    };
    fetch();
  }, []);
  //#endregion

  React.useEffect(() => {
    setIsDirty(form_edited || isDirty);
  }, [isDirty]);

  React.useEffect(() => {
    if (!isEqual(goalData, watch())) {
      setGoalData(watch());
    }
  }, [goalData, watch]);

  const palette = usePalette();

  //#region UI COMPONENTS
  const goals_form = (
    <GridSectionLayout
      headerConfig={{
        title: "Goal Information",
      }}
      divider
      fullWidth
      rows={[
        {
          cells: [
            <Controller
              control={control}
              name="goal.number"
              render={({ field }) => {
                return (
                  <XNGInput2
                    type={"text"}
                    label="Goal Number"
                    id={"goal-number-id"}
                    {...register("goal.number")}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setGoalData(watch());
                    }}
                    useError={errors?.goal?.number?.message}
                    fullWidth
                  />
                );
              }}
            />,
            <Controller
              control={control}
              name="goal.status"
              render={({ field }) => {
                return (
                  <XNGDropDown
                    id={"x-logs-status"}
                    items={["Active", "Inactive"]}
                    label={"Goal Status"}
                    ref={field.ref}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setGoalData(watch());
                    }}
                    onBlur={field.onBlur}
                    name={field.name}
                    value={field.value}
                    fullWidth
                    enableButtomMargin
                    maxwidth="100%"
                    useError={errors?.goal?.status?.message}
                  />
                );
              }}
            />,
            <Controller
              control={control}
              name="goal.goal_area_of_focus"
              render={({ field }) => {
                return (
                  <XNGDropDown
                    id={"x-logs-status"}
                    label={"Goal Area Of Focus"}
                    useTypedDropDown={{
                      defaultValue: field.value,
                      value: field.value,
                      items:
                        (goal_area_of_focus_options?.areasOfFocus as GoalFromType["goal"]["goal_area_of_focus"][]) ||
                        [],
                      getRenderedValue: (item) => item?.name || "",
                      onChange: (item) => {
                        field.onChange(item);
                        setGoalData(watch());
                      },
                    }}
                    fullWidth
                    enableButtomMargin
                    maxwidth="100%"
                    useError={errors?.goal?.goal_area_of_focus?.message}
                  />
                );
              }}
            />,
          ],
        },
        {
          useCellStyling: {
            sx: {
              mb: "1rem",
            },
          },
          cells: [
            <Controller
              control={control}
              name="goal.start_date"
              render={({ field }) => {
                return (
                  <XNGDateField
                    label={"Start Date"}
                    value={field.value ? dayjs(new Date(field.value).toDateString()) : null}
                    onChange={(event) => {
                      field.onChange(event);
                      setGoalData(watch());
                    }}
                    fullWidth
                    useError={errors?.goal?.start_date?.message}
                  />
                );
              }}
            />,
            <Controller
              control={control}
              name="goal.end_date"
              render={({ field }) => {
                return (
                  <XNGDateField
                    label={"End Date"}
                    value={field.value ? dayjs(new Date(field.value).toDateString()) : null}
                    onChange={(event) => {
                      field.onChange(event);
                      setGoalData(watch());
                    }}
                    fullWidth
                    useError={errors?.goal?.end_date?.message}
                  />
                );
              }}
            />,
          ],
        },
        {
          // fullwidth: true,
          cellSizes: {
            lg: 9,
            sm: 12,
            xs: 12,
          },

          cells: [
            <Controller
              control={control}
              name="goal.description"
              render={({ field }) => {
                return (
                  <XNGInput2
                    type={"text"}
                    label="Measurable Annual Goal Text"
                    id={"measurable-annual-goal-id"}
                    {...register("goal.description")}
                    // useError={errors?.firstName?.message}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setGoalData(watch());
                    }}
                    fullWidth
                    multiline
                    rows={10}
                    useError={errors?.goal?.description?.message}
                  />
                );
              }}
            />,
          ],
        },
      ]}
    />
  );

  const objectives_form = (
    <GridSectionLayout
      headerConfig={{
        titleOverride: (
          <Typography variant="h6">
            {"Objective Information"} |{" "}
            <Box component={"span"} color={"primary.main"}>
              Total: {objectives_inputs?.length}
            </Box>
          </Typography>
        ),
      }}
      fullWidth
      rows={[
        ...(objectives_inputs
          ?.map((_, index) => {
            return [
              {
                cells: [
                  <Controller
                    control={control}
                    name={`objectives.${index}.number`}
                    render={({ field }) => {
                      return (
                        <XNGInput2
                          type={"text"}
                          label="Objective Number"
                          id={"objective-number-id"}
                          {...register(`objectives.${index}.number`)}
                          onChange={(event) => {
                            field.onChange(event);
                            setGoalData(watch());
                          }}
                          // useError={errors?.firstName?.message}
                          fullWidth
                        />
                      );
                    }}
                  />,
                ],
              },
              {
                cellSizes: {
                  lg: 9,
                  sm: 12,
                  xs: 12,
                },
                cells: [
                  <Controller
                    control={control}
                    name={`objectives.${index}.description`}
                    render={({ field }) => {
                      return (
                        <XNGInput2
                          type={"text"}
                          label="Objective Text"
                          id={"objective-text-id"}
                          {...register(`objectives.${index}.description`)}
                          onChange={(event) => {
                            field.onChange(event);
                            setGoalData(watch());
                          }}
                          // useError={errors?.firstName?.message}
                          fullWidth
                          multiline
                          rows={10}
                        />
                      );
                    }}
                  />,
                ],
              },
            ];
          })
          .flat() || []),
        {
          useCellStyling: {
            sx: {
              mb: "3rem",
            },
          },
          cells: [
            <Button
              sx={{ py: "1.5rem" }}
              disabled={!objectives_inputs?.every((obj) => !!obj.number && !!obj.description)}
              onClick={() => {
                if (objectives_inputs) {
                  setValue("objectives", [
                    ...objectives_inputs,
                    {
                      number: "",
                      description: "",
                    },
                  ]);
                }
              }}
            >
              Add Another Objective
            </Button>,
          ],
        },
      ]}
    />
  );
  //#endregion

  return (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(onSubmitGoal)}>
        <GoalInfoTabHeader
          justifify_content="space-between"
          use_back_btn={{
            onClick: props.onBackBtnClick,
          }}
          use_save_btn={{
            disabled: !is_form_valid || !form_edited || savingGoal,
            label: savingGoal ? "Saving..." : "Save Goal/Observation",
          }}
        />
        {goals_form}
        {objectives_form}
        <DualActionModal
          open={backButtonClicked}
          onClose={() => setBackButtonClicked(false)}
          onConfirm={() => {
            handleSubmit(onSubmitGoal)();
            setBackButtonClicked(false);
          }}
          onReject={() => {
            setBackButtonClicked(false);
            setIsDirty(false);
            setIsCreatingGoal(false);
            setInEditGoalView(false);
            reset();
          }}
          injectContent={{
            header: "Attention",
            body: (
              <Typography variant="body1">
                You have not saved your changes. Would you like to save your progress before you
                leave this page?
              </Typography>
            ),
            noText: "No",
            yesText: "Yes",
            icon: <XNGIconRenderer color={palette.danger[4]} size="2rem" i={<XNGICONS.Alert />} />,
            buttonStyles: {
              yesButton: {
                width: "102px",
                padding: "8px",
                borderRadius: "3px",
              },
              noButton: {
                width: "102px",
                padding: "8px",
                borderRadius: "3px",
                ":hover": {
                  bgcolor: palette.danger[1],
                  color: "white",
                },
                bgcolor: palette.danger[4],
                color: "white",
              },
            },
          }}
        />
      </form>
    </Box>
  );
};

export default GoalCreateEditView;
