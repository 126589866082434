/**
 * Accepts any object holding an error from APIs that return only a string.
 * 
 * Internally casts the object as a Fetch API Response, and derives the blob text if any.
 *
 * CONTEXT: Some of our API endpoints are programmed to return only string responses when erroring. We can use this function
 * to get the error message for those cases.
 */
export async function getErrorFromFetchAPIWhenResponseIsOnlyString(error: any): Promise<string> {
  return await (await (error as unknown as Response).blob()).text();
}
