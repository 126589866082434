import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

type AutocompletePartialProps<T> = Partial<AutocompleteProps<T, boolean, boolean, boolean>>;

/**
 * Private, closed to modification. These are the props required for this component's minimum
 * functionality.
 *
 * If you need to modify, instead create a unique component local to your feature.
 */
interface RequiredProps<T> {
  options: T[];
  selectedOption: T | null;
  getOptionLabel: (option: T) => string;
  onChange: (v: T, e?: React.SyntheticEvent<Element, Event>) => void;
}

/**
 * Private, open to extension. The optional props for `MSBSearchMultiselect`.
 */
interface OptionalProps<T> {
  autocompleteProps?: AutocompletePartialProps<T>;
  defaultOption?: T;
  label?: string;
}

export type MSBSearchSingleSelectProps<T> = RequiredProps<T> & OptionalProps<T>;

export default function MSBSearchSingleSelect<T>(props: Readonly<MSBSearchSingleSelectProps<T>>) {
  return (
    <Autocomplete
      autoHighlight
      value={props.selectedOption}
      onChange={(e, newValue) => {
        props.onChange(newValue as T, e);
      }}
      options={props.options}
      getOptionLabel={(v) => props.getOptionLabel(v as T)}
      renderInput={(params) => (
        <TextField {...params} label={props.label ?? ""} fullWidth size="small" />
      )}
      {...props.autocompleteProps}
    />
  );
}
