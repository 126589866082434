import { Box, MenuItem, Popover, Tooltip, Typography } from "@mui/material";
import { useRef, useState } from "react";

export function MSBDataGridPopoverMenu<T>(
  props: Readonly<{
    value: T[];
    getRenderValue: (value: T) => string;
    getUniqueKey: (value: T) => string;
    emtpyValue: string;
  }>,
) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const hoveringOverAnchor = useRef(false);
  const hoveringOverContent = useRef(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (props.value.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    if (!hoveringOverContent.current && !hoveringOverAnchor.current) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Box
        component={"div"}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(e) => {
          hoveringOverAnchor.current = true;
          handlePopoverOpen(e);
        }}
        onMouseLeave={() => {
          hoveringOverAnchor.current = false;
          setTimeout(() => {
            handlePopoverClose();
          }, 100);
        }}
        whiteSpace={"nowrap"}
        display={"flex"}
        alignItems={"end"}
        gap={"2px"}
      >
        <Tooltip title={props.value.length === 1 ? props.getRenderValue(props.value[0]) : ""} placement="top">
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflow: props.value.length > 1 ? "hidden" : "visible",
            maxWidth: props.value.length > 1 ? "9ch" : "unset",
          }}
        >
          {props.getRenderValue(props.value[0]) || props.emtpyValue}{" "}
        </Typography>
        </Tooltip>
        <Box component={"span"} fontSize={"12px"} sx={{ opacity: 0.85 }}>
          {props.value?.length > 1 ? ` +${props.value.length - 1}More` : ""}
        </Box>
      </Box>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ pointerEvents: "none" }}
        slotProps={{
          paper: {
            sx: { pointerEvents: "auto" },
            onMouseLeave: () => {
              hoveringOverContent.current = false;
              handlePopoverClose();
            },
            onMouseEnter: () => {
              hoveringOverContent.current = true;
            },
          },
        }}
        disableRestoreFocus
      >
        {props.value?.map((value, i) => {
          return (
            <MenuItem
              divider
              key={props.getUniqueKey(value)}
              disableRipple
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {props.getRenderValue(value)}
            </MenuItem>
          );
        })}
      </Popover>
    </Box>
  );
}
