import { useMemo } from "react";
import { CareProvision } from "../types/care_provision";
import CareProvisionControl from "./care_provision_control";
import CollapsableAccordionRoot from "./collapsable_accordion";
import { ActivityInventoryItem } from "../../../profile-sdk"; 
import { ProvidedActivity } from "../../../session-sdk";

 

 
export type LockedCareProvisionsDisplayData = {
  defaultIDs: string[];
  draftProvidedCareProvisions: CareProvision[];
  savedCustomCareProvisionLedger: string[];
  draftCustomCareProvisionLedger: string[];
};

export function LockedCareProvisionsDisplayWithBillableCondition(
  props: Readonly<{
    displayData: LockedCareProvisionsDisplayData;
    displayDataWithBillableCondition: ActivityInventoryItem[];
    selectedStudentIndex?:number;
    onToggleName: (id: string) => void;
    onIncrementNameUp: (id: string) => void;
    onIncrementNameDown: (id: string) => void;
    onCustomDelete: (id: string) => void;
    setTimeForAdlIadl:(name:string,totalMinutes:number,increment:number,averageMinutesPerTime:number)=>void
  }>,
) {
  const { defaultIDs, draftCustomCareProvisionLedger, savedCustomCareProvisionLedger } =
    props.displayData; 


  const lockedCustomsWithoutCachedDeletions = deriveLockedCustomsWithoutCachedDeletions({
    draftCustomCareProvisionLedger,
    savedCustomCareProvisionLedger,
  });

  const defaultIDsWithoutCustoms = useMemo(
    () => defaultIDs.filter((defID) => !savedCustomCareProvisionLedger.includes(defID)),
    [defaultIDs],
  ); 

  function checkIfAccordionShouldBeRendered(activityInventoryItemName: string) {
        const foundActivityInventoryItem = props?.displayDataWithBillableCondition.find((cp) => cp.name === activityInventoryItemName);
        if(foundActivityInventoryItem){
            return foundActivityInventoryItem?.billable
        }
        return false
  }  
  return ( 
        < >
            {defaultIDsWithoutCustoms.map((id: string, i: number) => {
              const incrementWithTotalAmountOfMinutes =
                props.displayData.draftProvidedCareProvisions.find((cp) => cp.name === id)  
              if(checkIfAccordionShouldBeRendered(id)){ 
                  return (   
                  <CollapsableAccordionRoot 
                  selectedStudentIndex={props.selectedStudentIndex}
                  setTimeForAdlIadl={(name:string,totalMinutes:number,increment:number,averageMinutesPerTime:number)=> props.setTimeForAdlIadl(name,Number(totalMinutes),Number(increment??0),Number(averageMinutesPerTime??0))} 
                  activitiesCaresProvision={incrementWithTotalAmountOfMinutes as ProvidedActivity} 
                  key={`${id}-activity-with-accordion`} 
                  accordionTitle={id} />        
                          )
                }
              return ( 
                    <CareProvisionControl 
                      key={`${id}-activity-without-accordion`}
                      label={id}
                      increments={incrementWithTotalAmountOfMinutes?.increments ? incrementWithTotalAmountOfMinutes?.increments : 0}
                      onIncrement={() => props.onIncrementNameUp(id)}
                      onDecrement={() => props.onIncrementNameDown(id)}
                      onToggle={() => props.onToggleName(id)}
                    /> 
              );
            })}

            {lockedCustomsWithoutCachedDeletions.map((id: string, i: number) => {
              const increments =
                props.displayData.draftProvidedCareProvisions.find((cp) => cp.name === id)?.increments ??
                0;

              return ( 
                    <CareProvisionControl 
                      label={id}
                      key={id}
                      increments={increments}
                      onIncrement={() => props.onIncrementNameUp(id)}
                      onDecrement={() => props.onIncrementNameDown(id)}
                      onToggle={() => props.onToggleName(id)}
                      useDeleteButton={{
                        onDelete: () => {
                          props.onCustomDelete(id);
                        },
                      }}
                    /> 
              );
            })}
       </> 
  );
}


 

function deriveLockedCustomsWithoutCachedDeletions(props: {
  savedCustomCareProvisionLedger: string[];
  draftCustomCareProvisionLedger: string[];
}) {
  const savedCustoms = props.savedCustomCareProvisionLedger;
  const draftCustoms = props.draftCustomCareProvisionLedger;

  const cachedDeletionIDs: string[] = savedCustoms.filter((saved) => !draftCustoms.includes(saved));

  const customsWithoutCachedDeletions = savedCustoms.filter((id) => {
    const isHidden = cachedDeletionIDs.includes(id);
    return !isHidden;
  });

  return customsWithoutCachedDeletions;
}
