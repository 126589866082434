 import { useContext } from "react";
import ProgressReportingContext from "../progress_reporting_context";
import ProgressReportingContextType from "../../types/progress_reporting_context_type";
const useProgressReporting = (): ProgressReportingContextType => {
    const context = useContext(ProgressReportingContext);
    if (context === undefined) {
        throw new Error("useProgressReporting must be used within a FinancialDashboardProvider");
    }
    return context;
};

export default useProgressReporting;
