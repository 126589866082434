import { StudentProgressReportRecordUI, StudentProgressReportUpdateParameters } from "@xng/reporting";
import { ServiceProviderResponse } from "../../../../../profile-sdk";
import { ReportStatus } from "../context/reducer/progress_reporting_reducer";

export class ProgressReportApiParametersGenerator{

    // for normal aggregation no need to check if it was posted or not
    public static generateParamsForProgressReportUpdateForAggregatedData(
        goalId:string, 
        period:number, 
        currentlyLoggedInServiceProvider:ServiceProviderResponse,
        pageRecords:StudentProgressReportRecordUI[],
        currentlySelectedServiceProviderId:string,
        objectiveId?:string,
        userInput?:{generalComments?:string,progressMeasured?:number,makingAdiquateProgress?:boolean,studentName?:string} 
    ):StudentProgressReportUpdateParameters[]{
        const result : StudentProgressReportUpdateParameters[] = []
        const {generalComments, progressMeasured, makingAdiquateProgress,studentName} = userInput ?? {}
        pageRecords.forEach((report)=>{
            if(report?.goal?.goalId === goalId && studentName=== report?.studentName && report.serviceProviderId === currentlySelectedServiceProviderId){
                  report?.goal?.goalProgressDetails?.forEach((goalProgressDetail)=>{
                       if(period === goalProgressDetail.period  ){
                           result.push({
                               id:report.id,
                               reportRunId:report.reportRunId,
                               objectiveId:objectiveId ?? "", 
                               period:goalProgressDetail.period,
                               reportFinalizedBy:currentlyLoggedInServiceProvider.firstName + " " + currentlyLoggedInServiceProvider.lastName,
                               generalComments,
                               progressMeasured,
                               makingAdiquateProgress
                           })
                       }
                  })
            }
        })
 
        return result
    }
   
    
    public static generateParamsForProgressReportUpdateForTeamAggregatedData(
        goalId:string, 
        period:number, 
        currentlyLoggedInServiceProvider:ServiceProviderResponse , 
        pageRecords:StudentProgressReportRecordUI[], objectiveId?:string, 
        extraArgs?:{ 
            generalComments?:string,
            progressMeasured?:number,
            makingAdiquateProgress?:boolean,
            studentName?:string
         }) : StudentProgressReportUpdateParameters[]{
       const result : StudentProgressReportUpdateParameters[] = []
       const {generalComments, progressMeasured,makingAdiquateProgress,studentName} = extraArgs ?? {}
 
       pageRecords.forEach((report,_reportIndexPosition)=>{
            if(report?.goal?.goalId === goalId && studentName === report?.studentName){
               report?.goal.goalProgressDetails?.forEach((goalProgressDetail,_goalProgressIndexPosition)=>{
                   const isPosted = goalProgressDetail?.status?.toLocaleLowerCase() === ReportStatus.Posted
                   if(period === goalProgressDetail.period && isPosted){
                          result.push({
                              id:report.id,
                              reportRunId:report.reportRunId,
                              objectiveId:objectiveId??"",
                              period:goalProgressDetail.period,
                              generalComments,
                              progressMeasured,
                              reportFinalizedBy:currentlyLoggedInServiceProvider.firstName + " " + currentlyLoggedInServiceProvider.lastName,
                              makingAdiquateProgress
                          })
                      }
                 })
           }
       })

       return result
    }



   // for aggregated data view update goal which is under a service provider NOTE: no need to check if it was posted or not
    public static _objectiveGenerateParamsForAggregatedData(
        goalId:string, 
        period:number, 
        currentlyLoggedInServiceProvider:ServiceProviderResponse , 
        pageRecords:StudentProgressReportRecordUI[], 
        currentlySelectedServiceProviderId:string,
        objectiveId?:string, 
        extraArgs?:{ 
            generalComments?:string,
            progressMeasured?:number,
            makingAdiquateProgress?:boolean,
            studentName?:string
         }) : StudentProgressReportUpdateParameters[]{
        
        const result : StudentProgressReportUpdateParameters[] = []
        const {generalComments, progressMeasured,makingAdiquateProgress,studentName} = extraArgs ?? {}
    
        pageRecords.forEach((report,_reportIndexPosition)=>{
                if(report?.goal?.goalId === goalId && studentName === report?.studentName && report?.serviceProviderId == currentlySelectedServiceProviderId){
                report?.goal.goalProgressDetails?.forEach((goalProgressDetail,_goalProgressIndexPosition)=>{ 
                    if(period === goalProgressDetail.period){
                            result.push({
                                id:report.id,
                                reportRunId:report.reportRunId,
                                objectiveId:objectiveId??"",
                                period:goalProgressDetail.period,
                                generalComments,
                                progressMeasured,
                                reportFinalizedBy:currentlyLoggedInServiceProvider.firstName + " " + currentlyLoggedInServiceProvider.lastName,
                                makingAdiquateProgress
                            })
                        }
                    })
            }
        })
    
        return result
    
    }


    // udpate all objectives which was posted NOTE: check if objective was posted or not
    public static _objectiveGenerateParamsForTeamAggregatedData(
        goalId:string, 
        period:number, 
        currentlyLoggedInServiceProvider:ServiceProviderResponse , 
        pageRecords:StudentProgressReportRecordUI[], 
        objectiveId:string, 
        extraArgs?:{ 
            generalComments?:string,
            progressMeasured?:number,
            makingAdiquateProgress?:boolean ,
            studentName?:string
         }) : StudentProgressReportUpdateParameters[]{

       const {generalComments,progressMeasured,makingAdiquateProgress,studentName} = extraArgs ?? {}
       const result:StudentProgressReportUpdateParameters[] = []
       pageRecords?.forEach((report,_pageRecordIndex)=>{
          // there will be lots of goals 
          if(report?.goal?.goalId === goalId && studentName === report?.studentName){
                report?.objectives?.forEach((objective,_objectiveIndexPosition)=>{
                          if(objective?.objectiveId === objectiveId ){
                                 objective?.objectiveProgressDetails?.forEach((objectiveProgress,_objectiveProgressIndex)=>{
                                      const isMatchingPeriod = period === objectiveProgress?.period
                                      const isPosted = objectiveProgress?.status?.toLocaleLowerCase() === ReportStatus.Posted
                                      if( isMatchingPeriod && isPosted ){
                                          result.push({
                                            id:report.id,
                                            reportRunId:report.reportRunId,
                                            objectiveId:objectiveId,
                                            period:objectiveProgress.period,
                                            generalComments,
                                            progressMeasured,
                                            makingAdiquateProgress,
                                            reportFinalizedBy:currentlyLoggedInServiceProvider.firstName + " " + currentlyLoggedInServiceProvider.lastName,
                                        })
                                      }
                                 })
                          }
                })
          }
       })

       return result 
    }

}