import { GridColDef } from "@mui/x-data-grid";
import { SessionLogsSummaryData } from "..";
import dayjs from "dayjs";

type GridRowsColumnDefType = GridColDef<
  NonNullable<NonNullable<SessionLogsSummaryData["records"]>[number]>
>;

export default function useSessionLogsSummaryTableColumnDefinition() {
  const columnDefDefaultProps: Partial<GridRowsColumnDefType> = {
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    valueGetter(params) {
      return params.value || emptyCellValue;
    },
  };

  const emptyCellValue = "N/A";

  const sessionLogsSummaryTablecolumns: GridRowsColumnDefType[] = [
    {
      ...columnDefDefaultProps,
      field: "firstName",
      headerName: "First Name",
    },
    {
      ...columnDefDefaultProps,
      field: "lastName",
      headerName: "Last Name",
    },
    {
      ...columnDefDefaultProps,
      field: "studentId",
      headerName: "Student ID",
    },
    {
      ...columnDefDefaultProps,
      field: "dateOfBirth",
      headerName: "Birth Date",
      valueGetter(params) {
        return dayjs(params.value).format("MM/DD/YYYY") || emptyCellValue;
      },
      sortComparator: (v1, v2) => {
        const diff = Math.round(Math.max(-1, Math.min(1, dayjs(v1).diff(dayjs(v2)))));
        return diff;
      },
    },
    {
      ...columnDefDefaultProps,
      field: "gradeLevel",
      headerName: "Grade",
    },
  ];

  return sessionLogsSummaryTablecolumns;
}
