import { DataGrid } from "@mui/x-data-grid";
import './progress_summary_view.css';
import { progress_summary_manager, ProgressSummaryDataGrid_MUI_RowDataProps } from "./utils/progress_summary_data_grid_manager";
import ReportsLayout from "../../reports_layout"; 
import { Box, Button, Typography } from "@mui/material";
import { useDataGridBase } from "../../../../hooks/use_datagrid_base";
import { StudentProgressReportRecordUIPaginatedReportResponse } from "@xng/reporting";
import { xlsxReportGeneratorManager } from "./utils/xlsx_helper"; 
import { useLocation } from "react-router";

export function ProgressSummary() {  
    const location = useLocation() 
    const progressReportGenerated = location?.state
    // Set the rows and columns in progress_summary_manager based on progressReportGenerated
    progress_summary_manager
        .setProgressDataSummaryColumnNames()
        .setProgressDataSummaryRows(progressReportGenerated as StudentProgressReportRecordUIPaginatedReportResponse);
 
    
   
    // Memoized rows and columns to prevent unnecessary recalculations
    const rows =  progress_summary_manager._progressDataGridRow 
    const cols =  progress_summary_manager._progressDataGridColumn 
 
    // Base DataGrid setup using memoized rows and columns
    const dataGrid = useDataGridBase<ProgressSummaryDataGrid_MUI_RowDataProps>({ rows, columns: cols });

 
    // Download XLSX report
    const downloadDocument = () => {
        xlsxReportGeneratorManager.generateXlsxReportForProgressSummary(rows); 
    };

    return (
        <ReportsLayout title="Summary" gutterTop>
            { progressReportGenerated?.pageRecords?.length === 0 || !progressReportGenerated ? 
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
               <Typography variant="subtitle2" sx={{ fontSize: "24px", textAlign: "center", fontWeight: 700, color: "red" }}>
                   {"No goals on file"}
               </Typography>
           </Box>
            :(
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                        <Button
                            sx={{
                                width: "178px",
                                px: "2rem",
                                py: "1.5rem",
                            }}
                            onClick={downloadDocument}
                        >
                            Download
                        </Button>
                    </Box>
                    <DataGrid
                        rows={dataGrid.rows.length === 0 ? [] : dataGrid.rows}
                        columns={dataGrid.columns}
                        hideFooterPagination
                        disableColumnMenu
                        hideFooter
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow data_grid_text_clr' : 'data_grid_text_clr'
                        }
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        sx={{
                            padding: "0px 10px 20px 10px",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "black",
                            maxHeight: "698px",
                            marginTop: "20px",
                            '& .MuiDataGrid-columnHeaders': {
                                textAlign: 'center',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textAlign: 'center',
                                width: '100%',
                            },
                        }}
                    />
                </Box>
            )}
        </ReportsLayout>
    );
}
