import useApiMutatePollData from "../../../../../../api/hooks/use_api_mutate_poll_data";
import { API_SESSION_LOGS } from "../../../../../../api/api";
import { ApiResponse, V1SessionReportsSessionLogsDownloadCSVPostRequest } from "@xng/reporting";
import XNGApiMutateParamObject from "../../../../../../types/xng_api_mutate_param_object";

type Data = Awaited<
  ReturnType<typeof API_SESSION_LOGS.v1SessionReportsSessionLogsDownloadCSVPostRaw>
> extends ApiResponse<infer T>
  ? T
  : never;
type Body = V1SessionReportsSessionLogsDownloadCSVPostRequest; // generic parameter B is used to define body the type of the useApiMutateData hook

export default function useApiMutatePollSessionLogsCsvDownload(
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) {
  return useApiMutatePollData({
    mutationFn: async (body: Body) =>
      await API_SESSION_LOGS.v1SessionReportsSessionLogsDownloadCSVPostRaw(body),
    mutationKey: ["v1SessionReportsSessionLogsDownloadCSVPostRaw"],
    ...(paramObject?.options ?? {}),
  });
}
