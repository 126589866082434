import { GoalAreaOfFocus, GoalDisplay } from "../../../../../profile-sdk";

/**
 * Accepts a GoalDisplay, maps it to the State Snapshot's GoalAreaOfFocusInventory, and returns the appropriate GoalAreaOfFocus
 */
export function getAreaOfFocusFromGoalDisplayUsingInventory(
  goalDisplay: GoalDisplay,
  inventory: GoalAreaOfFocus[],
): GoalAreaOfFocus | null {
  const thisFocus = goalDisplay.goalAreaOfFocus ?? null;
  if (!thisFocus) {
    console.warn("Unable to identify goal area of focus!");
    return null;
  }

  return inventory.find((focus) => focus.id === thisFocus.id) || null;
}
