import { useMemo } from "react";
import { Student } from "../../../../session-sdk";

const useSelectedStudentsHandler = (props: {
  onChangeSelectedStudentIds: (selectedProviderIds: string[]) => void;
  selectedStudentIds: string[];
  students: Student[];
}) => {
  const { onChangeSelectedStudentIds, students, selectedStudentIds } = props;
  const selectedStudents = useMemo(() => {
    return students.filter((student) => selectedStudentIds.includes(student.id ?? ""));
  }, [students, selectedStudentIds]);

  const setSelectedStudentsHandler = (selected: Student[]) => {
    onChangeSelectedStudentIds(selected.map((s) => s.id ?? ""));
  };

  return { selectedStudents, setSelectedStudents: setSelectedStudentsHandler };
};

export default useSelectedStudentsHandler;
