import { useNavigate } from "react-router";
import { ROUTES_XLOGS } from "../../../constants/URLs";

interface NavigateToSessionProps {
  sessionID: string;
  sessionServiceProviderID: string;
  sessionSeriesID?: string;
  sessionDate: Date;
}

type NavigateToSession = (v: NavigateToSessionProps) => void;

export function useNavigateToSession(): NavigateToSession {
  const navigate = useNavigate();

  return function navigateToSession(v: NavigateToSessionProps) {
    navigate(
      ROUTES_XLOGS.notator +
        "/" +
        v.sessionID +
        "?serviceProviderId=" +
        v.sessionServiceProviderID +
        "&seriesId=" +
        v.sessionSeriesID +
        "&date=" +
        v.sessionDate,
    );
  };
}

// navigate(
//     ROUTES_XLOGS.notator +
//       "/" +
//       session.id +
//       "?serviceProviderId=" +
//       session?.serviceProvider?.id +
//       "&seriesId=" +
//       session.seriesId +
//       "&date=" +
//       session.startTime,
//   );
