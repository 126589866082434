import { StudentProgressReportRecordUI, StudentProgressReportRecordUIPaginatedReportResponse } from "@xng/reporting";
import { ColumnHeader } from "../../../../../hooks/use_datagrid_base"; 
import {  getFormattedDateTime } from "../../../../admin/views/district_profile/utils/date_utils";
import { getGoalStatus } from "./getGoalStatus";
import { getPostedStatus } from "./getPostedStatus";
 

 
export enum ProgressDataColumnNames{
        STUDENT_NAME ="Student Name",
        DISTRICT="District",
        CAMPUS ="Campus",
        STUDENT_ID = "Student ID",
        STUDENT_DOB = "Student DOB",
        NUMBER_OF_GOALS_ON_FILE ="Number of goals on file",
        NUMBER_OF_GOALS_CREATED ="Number of goals created",
        NUMBER_OF_GOALS_SUBMITTED="Number of goals submitted",
        NUMBER_OF_GOALS_POSTED ="Number of goals posted",
        STAFF_REPORTED_PROGRESS="Staff reported progress"
}

export interface ProgressSummaryDataGrid_MUI_ColumnDataProps {
    key: ProgressDataColumnNames;  // Type the field as ProgressDataColumnNames
    title: string; 
}

export interface ProgressSummaryDataGrid_MUI_RowDataProps {
    id: number;
    [ProgressDataColumnNames.STUDENT_NAME]: string;
    [ProgressDataColumnNames.DISTRICT]: string;
    [ProgressDataColumnNames.CAMPUS]: string;
    [ProgressDataColumnNames.STUDENT_ID]: string;
    [ProgressDataColumnNames.STUDENT_DOB]: string;
    [ProgressDataColumnNames.NUMBER_OF_GOALS_ON_FILE]: number;
    [ProgressDataColumnNames.NUMBER_OF_GOALS_CREATED]: number;
    [ProgressDataColumnNames.NUMBER_OF_GOALS_SUBMITTED]: number;
    [ProgressDataColumnNames.NUMBER_OF_GOALS_POSTED]: number;
    [ProgressDataColumnNames.STAFF_REPORTED_PROGRESS]: string;
}



export class ProgressSummaryDataGridManager{
    private static _instance:ProgressSummaryDataGridManager|undefined = undefined
    public _progressDataGridColumn:ColumnHeader<ProgressSummaryDataGrid_MUI_RowDataProps>[]= []
    public _progressDataGridRow:ProgressSummaryDataGrid_MUI_RowDataProps[] = [] 
    constructor(){}


    /**
     * Returns the singleton instance of the ProgressSummaryDataGridManager class.
     * If the instance does not exist, it creates a new one and initializes the column names.
     *
     * @return {ProgressSummaryDataGridManager} The singleton instance of the ProgressSummaryDataGridManager class.
     */
    static getInstace():ProgressSummaryDataGridManager{
        if(!this._instance){
            this._instance = new ProgressSummaryDataGridManager()
        }
        // column init
        this._instance.setProgressDataSummaryColumnNames()
        return this._instance
    }

    /**
     * Sets the progress data summary column names by iterating over the ProgressDataColumnNames object.
     * It generates a column for each entry and adds it to the _progressDataGridColumn array.
     *
     * @return {this} Returns the instance of the class.
     */
     setProgressDataSummaryColumnNames():this{ 
        if(this._progressDataGridColumn.length > 0) return this
        Object.entries(ProgressDataColumnNames).forEach(([key,value])=>{
               const headerName = (value)
               this._progressDataGridColumn = [...this._progressDataGridColumn, this.generateColumn(value,headerName)]
        })
        return this
    }

/**
 * Sets the progress data summary rows by creating an array of dummy row data objects.
 * Each dummy row object has a unique id generated dynamically using the index.
 *
 * @param {StudentProgressReportRecordUIPaginatedReportResponse} data - Optional data to be used to set the progress data summary rows.
 * @returns {this} Returns the instance of the class.
 */
     setProgressDataSummaryRows(data:StudentProgressReportRecordUIPaginatedReportResponse):this{
        if(this._progressDataGridRow.length > 0){
            this._progressDataGridRow = []
        } 
        data?.pageRecords?.map((arg,idx)=>{
            if(this.isStudentAlreadyAddedToRows(arg.studentName as string)) return console.error("student already added")
            this._progressDataGridRow.push(this.generateStudentProgressDataForRow(arg,idx,data?.pageRecords as StudentProgressReportRecordUI[]))
        }) 
        return this
    }

    isStudentAlreadyAddedToRows(studentName:string):boolean{ 
        let isExisting =  this._progressDataGridRow.find(arg=>arg[ProgressDataColumnNames.STUDENT_NAME] === studentName)
        return isExisting ? true : false
    }

    generateStudentProgressDataForRow(arg:StudentProgressReportRecordUI,idx:number,allReports:StudentProgressReportRecordUI[]):ProgressSummaryDataGrid_MUI_RowDataProps{
        const defaultFallBack = "NA"  
        const aggregatedReportsUnderCurrentStudent = this.getCurrentStudentDetails(arg.studentName as string, allReports)

        return {
               id:idx,
               [ProgressDataColumnNames.STUDENT_NAME]: arg.studentName ?? defaultFallBack,
               [ProgressDataColumnNames.DISTRICT]:aggregatedReportsUnderCurrentStudent?.district ?? defaultFallBack ,
               [ProgressDataColumnNames.CAMPUS]: aggregatedReportsUnderCurrentStudent?.campus ?? defaultFallBack,
               [ProgressDataColumnNames.STUDENT_ID]: aggregatedReportsUnderCurrentStudent?.studentId ??  defaultFallBack,
               [ProgressDataColumnNames.STUDENT_DOB]:  getFormattedDateTime(aggregatedReportsUnderCurrentStudent?.dob as unknown as string)?.date ?? defaultFallBack,
               [ProgressDataColumnNames.NUMBER_OF_GOALS_ON_FILE]:aggregatedReportsUnderCurrentStudent?.numberOfGoalsOfCurrentStudentOnFile,
               [ProgressDataColumnNames.NUMBER_OF_GOALS_CREATED]: aggregatedReportsUnderCurrentStudent?.numberOfGoalsCreated ?? 0,
               [ProgressDataColumnNames.NUMBER_OF_GOALS_SUBMITTED]: aggregatedReportsUnderCurrentStudent?.numberOfGoalsSubmitted ?? 0,
               [ProgressDataColumnNames.NUMBER_OF_GOALS_POSTED]: aggregatedReportsUnderCurrentStudent?.numberOfGoalsPosted ?? 0,
               [ProgressDataColumnNames.STAFF_REPORTED_PROGRESS]: aggregatedReportsUnderCurrentStudent?.concatinatedStaffReportedProgress ?? "NA",
           }
    }

    private getCurrentStudentDetails(studentName:string,reports:StudentProgressReportRecordUI[]) {
         const reportsOfCurrentStudent =  reports.filter((report)=> report.studentName === studentName) as StudentProgressReportRecordUI[]
         const numberOFGoalsWithAValidId = reportsOfCurrentStudent?.filter((report)=> report?.goal?.goalId)
         let NA = "NA"
         let studentDOB = reportsOfCurrentStudent[0]?.dob
         let numberOfGoalsOfCurrentStudentOnFile = numberOFGoalsWithAValidId?.length ?? 0
         let numberOfGoalsCreated = numberOFGoalsWithAValidId?.length ?? 0
         let numberOfGoalsPosted = 0
         let concatinatedStaffReportedProgress = ''
         let allReporters: Set<string> = new Set<string>()
         let numberOfGoalsSubmitted = reportsOfCurrentStudent?.filter((record)=> getGoalStatus(record?.goal?.goalStatus ?? "") === "Active")?.length ?? 0
         reports.map((report)=>{
             numberOfGoalsPosted += report?.goal?.goalProgressDetails?.filter(item=>getPostedStatus(item?.status ?? "") === "Posted").length ?? 0
             report?.goal?.goalProgressDetails?.forEach((reporter)=>{
                if(reporter?.progressReportedBy)
                 allReporters.add(reporter?.progressReportedBy)
             }) 
         })

         // remove duplicate reporters 
         concatinatedStaffReportedProgress = Array.from(allReporters).join(", ");

         return {
             studentName,
             district:reportsOfCurrentStudent[0]?.district,
             campus:reportsOfCurrentStudent[0]?.campus,
             studentId:NA,
             dob:studentDOB,
             numberOfGoalsOfCurrentStudentOnFile:numberOfGoalsOfCurrentStudentOnFile,
             numberOfGoalsCreated:numberOfGoalsCreated,
             numberOfGoalsPosted:numberOfGoalsPosted,
             numberOfGoalsSubmitted:numberOfGoalsSubmitted,
             concatinatedStaffReportedProgress
         }

    }

    /**
     * Generates a column data object for the progress data grid.
     *
     * @param {ProgressDataColumnNames} field - The field name of the column.
     * @param {"dummy"} headerName - The header name of the column.
     * @param {number} [minWidth=200] - The minimum width of the column.
     * @param {boolean} [editable=false] - Whether the column is editable.
     * @return {ProgressSummaryDataGrid_MUI_ColumnDataProps} The generated column data object.
     */
    private generateColumn(field:ProgressDataColumnNames,headerName:string,minWidth:number=210,editable:boolean=false,sortable:boolean= false,align:'center'='center',headerAlign:'center'='center'):ColumnHeader<ProgressSummaryDataGrid_MUI_RowDataProps>{
        return {
             key:field,
             title:headerName ,
             columnProps:{
                 minWidth:minWidth,
                 editable:editable,
                 sortable:sortable,
                 align:align,
                 headerAlign:headerAlign
             }
        }
    }



    /**
     * Generates a row data object for the progress data grid.
     *
     * @param {string} id - Unique identifier of the row.
     * @param {string} studentName - Name of the student.
     * @param {string} campus - Campus of the student.
     * @param {string} studentId - ID of the student.
     * @param {string} studentDOB - Date of birth of the student.
     * @param {number} number_of_goals_created - Number of goals created by the student.
     * @param {string} number_of_goals_unposted - Number of goals unposted by the student.
     * @return {ProgressSummaryDataGrid_MUI_RowDataProps} The generated row data object.
     */
    generateRows(
        id:number,
        studentName:string,
        district:string,
        campus:string,
        studentId:string,
        studentDOB:string,
        number_of_goals_created:number,
        number_of_goals_unposted:number,
        number_of_goals_submitted:number,
        number_of_goals_posted:number,
        staff_reported_progress:string
    ):ProgressSummaryDataGrid_MUI_RowDataProps{
        return {
            id,
            [ProgressDataColumnNames.STUDENT_NAME]:studentName, 
            [ProgressDataColumnNames.DISTRICT]:district,
            [ProgressDataColumnNames.CAMPUS]:campus, 
            [ProgressDataColumnNames.STUDENT_ID]:studentId, 
            [ProgressDataColumnNames.STUDENT_DOB]:studentDOB, 
            [ProgressDataColumnNames.NUMBER_OF_GOALS_ON_FILE]:number_of_goals_created, 
            [ProgressDataColumnNames.NUMBER_OF_GOALS_CREATED]:number_of_goals_unposted,
            [ProgressDataColumnNames.NUMBER_OF_GOALS_SUBMITTED]:number_of_goals_submitted,
            [ProgressDataColumnNames.NUMBER_OF_GOALS_POSTED]:number_of_goals_posted,
            [ProgressDataColumnNames.STAFF_REPORTED_PROGRESS]:staff_reported_progress
        }
    }
}


export const progress_summary_manager = ProgressSummaryDataGridManager.getInstace()