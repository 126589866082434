import { Box, Typography } from "@mui/material"
import { AnimatePresence, motion, Transition } from "framer-motion"
import React, { useState } from "react"
import { IoIosArrowUp } from "react-icons/io"

const collapsableWrapperVariants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0, overflow: "hidden" },
  }
const collapsableFilterArrow = {
    rotate: { rotate: 180 },
    stop: { rotate: 0 }
}
const transition:Transition = { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }

const CollapsableWrapperRoot = ({children,title="Filters",isWrapperActive=true}:{children:React.ReactNode,title?:string,isWrapperActive:boolean}) => {
  const [isActive,setIsActive] = useState(true)
 
  return (
    <AnimatePresence  initial={false}> 
            <motion.div key={"content"} initial="collapsed" animate={isActive?"open":"collapsed"} transition={transition} variants={collapsableWrapperVariants}>
                {children}
            </motion.div> 
            {isWrapperActive&&<Box onClick={()=>setIsActive(!isActive)} mt={4} sx={{ display: "flex", alignItems: "center",justifyContent:"center", gap: "5px",height:"60px",width:"100%" }}>
                <Typography sx={{color:"back",fontWeight:700, fontSize:"18px"}}>{title}</Typography>
                <motion.div style={{cursor:"pointer",display:"flex",alignItems:"center",justifyContent:"center"}} variants={collapsableFilterArrow} animate={isActive ? "rotate" : "stop"}>
                     <IoIosArrowUp size={20} color="black" />
                </motion.div>
            </Box>}
    </AnimatePresence>
  ) 
}

export default CollapsableWrapperRoot