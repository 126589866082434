import { useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { Outlet, useNavigate } from "react-router";
import PaddedWrapper from "./components/common/padded_wrapper";
import DlgBatchPostAlert from "./components/dialog/dlg_batch_post_alert";
import UnpostedSessionsHeader from "./components/header/unposted_sessions_header";
import useUnpostedSessionsContext from "./hooks/use_unposted_sessions_context";
import useUnpostedSessionsMatchPath from "./hooks/use_unposted_sessions_match_path";
import useUnpostedSessionsViewState from "./hooks/use_unposted_sessions_view_state";

/**
 * Renders the layout for the Unposted Sessions view.
 * @returns JSX.Element
 */
const UnpostedSessionsLayout = () => {
  const navigate = useNavigate();
  const matchPath = useUnpostedSessionsMatchPath();
  const unpostedSessionContext = useUnpostedSessionsContext();

  const unpostedSessionsCount =
    unpostedSessionContext.unpostedSessionsCountApiQueryClientManager.data;
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const viewState = useUnpostedSessionsViewState({
    viewParamId:
      (matchPath.params.viewId as ReturnType<typeof useUnpostedSessionsMatchPath>["customId"]) ??
      matchPath.customId,
    context: unpostedSessionContext,
    dynapicProps: {
      mySessions: unpostedSessionsCount?.myUnpostedCount,
      decSessions: unpostedSessionsCount?.dataEntryUnpostedCount,
      assistantSessions: unpostedSessionsCount?.assistantUnpostedCount,
    },
  });

  return (
    <Box
      position={"relative"}
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Stack height={"calc(100vh - 64px)"} gap={matchPath.customId === "notator" ? 0 : 4}>
        <Box>
          <PaddedWrapper>
            <Stack
              alignItems={"center"}
              sx={{
                flexDirection: {
                  direction: "column",
                  sm: "row",
                },
                gap: {
                  xs: 1,
                  md: 4,
                },
                paddingY: {
                  xs: 2,
                  sm: 2,
                },
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Stack>
            <UnpostedSessionsHeader
              headerTitle={viewState.headerTitle}
              viewState={viewState}
              unpostedSessionsCount={viewState?.dynamicProps ?? 0}
              onBatchPostingButtonClick={() => setOpenAlert(true)}
              renderBackBtn={matchPath.customId === "notator"}
              onBackBtnClick={() => navigate(viewState.route)}
            />
          </PaddedWrapper>
          <Divider />
        </Box>
        <Box flexGrow={1} sx={{ overflow: "auto" }}>
          <PaddedWrapper fullHeight>
            <Outlet />
          </PaddedWrapper>
        </Box>
      </Stack>
      <DlgBatchPostAlert
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        onContinueBtnClick={() => {
          navigate(viewState.route + "/batch-post");
        }}
      />
    </Box>
  );
};

export default UnpostedSessionsLayout;
