import { Box } from "@mui/material";
import { DataGrid, GridInputRowSelectionModel, GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
import {
  SessionLogsSummaryData,
  useSessionLogsSummaryTableColumnDefinition,
} from "../../hooks";
import { SessionLogsDataGridNoRowsOverlay } from "..";

type Props = {
  rows: NonNullable<SessionLogsSummaryData["records"]>;
  columnDef: ReturnType<typeof useSessionLogsSummaryTableColumnDefinition>;
  rowSelectionModel: GridInputRowSelectionModel;
  onRowSelectionModelChange: (rowSelectionModel: GridRowSelectionModel) => void;
  loading: boolean;
};

export function SessionLogsSummaryTable(props: Readonly<Props>) {
  return (
    <Box
      aria-label="session-logs-summary-table"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "650px",
        minHeight: "400px",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          justifyContent: "left",
        },
      }}
    >
      <DataGrid
        rows={props.rows.map((row, i) => ({ fallBackId: i, ...row }))}
        columns={props.columnDef}
        slots={{
          noRowsOverlay: SessionLogsDataGridNoRowsOverlay("No Session Logs Data found"),
        }}
        sortingOrder={["asc", "desc"]}
        disableColumnSelector
        pagination
        paginationMode="client"
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        checkboxSelection
        rowSelectionModel={props.rowSelectionModel}
        onRowSelectionModelChange={props.onRowSelectionModelChange}
        loading={props.loading}
      />
    </Box>
  );
}
