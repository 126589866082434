import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import DataGridLoadingLayout from "../../../../design/high-level/data_grid_loading_layout";
import { LoadingResponseMessageTemplate } from "../../../../design/templates/loading_response_message_template";
import { useAccountStudentReportTable } from "./hooks/use_account_student_report_table";
import { PageParameters } from "@xng/reporting";
import { ReportRunDateAndID } from "./report_run_date_and_id";

export interface FetchedAccountStudentReportRendererProps {
  requestParams: AccountStudentReportRequestParams;
  onPageParametersChange: (v: PageParameters) => void;
}

export type AccountStudentReportRequestParams = {
  includeInactive: boolean;
  reportRunDateAndID: ReportRunDateAndID | null;
  pageParameters: PageParameters;
};

export function FetchedAccountStudentReportRenderer(
  props: Readonly<FetchedAccountStudentReportRendererProps>,
) {
  const {
    onPageParametersChange,
    requestParams: { includeInactive, reportRunDateAndID, pageParameters },
  } = props;

  const { table, totalRecords } = useAccountStudentReportTable({
    includeInactive,
    pageParameters,
    reportRunDateAndID,
  });

  const { dataGridPaginationProps } = useReportingPagination({
    totalRecords,
    onPageParametersChange,
  });

  return (
    <DataGridLoadingLayout
      isLoading={table.rows.length < 1}
      loadingContent={<LoadingResponseMessageTemplate />}
      sizeAnchor="19rem"
    >
      <DataGrid
        columns={table.columns}
        rows={table.rows}
        getRowId={(r) => r.xLogsId}
        {...dataGridPaginationProps}
      />
    </DataGridLoadingLayout>
  );
}

interface ReportingPagination {
  dataGridPaginationProps: Partial<DataGridProps>;
}

/**
 * This is housed here temporarily. This will be moved to the /reports/hooks destination once ready.
 *
 * UPDATE: This wasn't the best way to handle pagination. Updated version now exists in `/reports/hooks`.
 */
function useReportingPagination(props: {
  totalRecords: number;
  onPageParametersChange: (v: PageParameters) => void;
}): ReportingPagination {
  return {
    dataGridPaginationProps: {
      onPaginationModelChange: (model) =>
        props.onPageParametersChange({ pageNumber: model.page + 1, pageSize: model.pageSize }),
      pagination: true,
      paginationMode: "server",
      rowCount: props.totalRecords,
    },
  };
}
