import {Box, Modal, Paper } from '@mui/material' 
import ProgressReportingStandardBtn from './progress_reporting_standard_btn'
import React from 'react'
import { IoMdClose } from 'react-icons/io'

const DownloadModal:React.FC<{handleClose:()=>void,downloadCSV:()=>void,downloadXlsx:()=>void,isOpen:boolean}> = 
({handleClose,downloadCSV,downloadXlsx,isOpen}) => {
  
  return ( 
    <React.Fragment>
            <Modal open={isOpen} onClose={handleClose} >
                <Box onClick={handleClose} sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"20px",height:"100%"}}>
                    <Box onClick={(e)=>e.stopPropagation()} sx={{background:"white",display:"flex",gap:"10px",alignItems:"center",justifyContent:"center",width:"500px",height:"150px",borderRadius:"10px",position:"relative"}}>
                         < IoMdClose onClick={handleClose} color='black' size={24} style={{ right:"10px",cursor:"pointer",top:"10px",position:"absolute"}} />
                            <ProgressReportingStandardBtn sxOverRide={{}} title='Download CSV' onSubmit={downloadCSV} />
                            <ProgressReportingStandardBtn sxOverRide={{}} title='Download xlsx' onSubmit={downloadXlsx} />
                    </Box>
                </Box>
            </Modal> 
    </React.Fragment>
  )
}

export default DownloadModal