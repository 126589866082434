import { PathMatch, useMatch } from "react-router";
import { ROUTES_XLOGS } from "../../../../constants/URLs";

function useBatchPostingMatchPath(): {
  customId: "my-sessions" | "dec-sessions" | "assistant-sessions" | "none";
} & PathMatch<string> {
  const matchBatchPostingPatch = useMatch(ROUTES_XLOGS.unposted_sessions.batch_post);

  if (matchBatchPostingPatch) {
    return {
      customId: matchBatchPostingPatch.params.viewId as
        | "my-sessions"
        | "dec-sessions"
        | "assistant-sessions",
      ...matchBatchPostingPatch,
    };
  }

  return {
    customId: "none",
    ...({} as PathMatch<string>),
  };
}

export default useBatchPostingMatchPath;
