

export enum StudentFitlerTableTitle {
    PERIOD = "Period",
    REPORTING_PERIOD_START = "Reporting Period Start",
    REPORTING_PERIOD_END = "Reporting Period End",
    SCHOOL = "Making Adequate Progress",
    GOALS_AND_OBJECTIVES = "Progress Measured",
    AREA_OF_FOCUS = "General Comments",
    STATUS = "Reported Progress" 
}

 