import { ProvidedActivity, SessionResponse } from "../../../session-sdk";
import { produce } from "immer";

export function sanitizeSession(draftSession: SessionResponse): SessionResponse {
  const res: SessionResponse = produce(draftSession, (draft) => {
    draft.title = draftSession.title?.substring(0, 255).trim();

    draft.studentJournalList!.map((newStudentJournal, i) => {
      const currentActs = newStudentJournal.careProvisionLedger!.activities!;
      const newActs: ProvidedActivity[] = currentActs.filter((ca) => ca.increments !== 0);

      draft.studentJournalList![i].careProvisionLedger!.activities! = newActs;
    });
  });

  return res;
}
