import { useEffect, useState } from "react";
import { API_STUDENTS } from "../../../../api/api";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { useXNGSelector } from "../../../../context/store";
import { SessionResponse } from "../../../../session-sdk";
import { StudentProfile } from "../../../../profile-sdk";

/**
 * Houses student-related API responses we retrieve when the session loads. NOT API requests made when clicking
 * into / viewing each student. Though those could probably be housed here too.
 * ^ For context, these are the other requests that fire for each notator student that could likely be consolidated here as well:
 * * v1StudentsIdActiveGoalsGet
 * * v1StudentsIdPrescribedCareProvisionsByDateGet
 *
 * If this record is null, that means the session has not been fetched yet.
 * If any entry in this record is null, that means the particular student's associated requests have not fired yet.
 */
interface CachedResponses {
  studentProfile: StudentProfile;
}

export type CachedResponsesByStudentIndexRecord = Record<number, CachedResponses | null>;

/**
 * Provides a record of all the API responses that fire for each student when the session loads. NOT API requests made when clicking
 * into / viewing each student. Though those could probably be housed here too.
 * ^ For context, these are the other requests that fire for each notator student that could likely be consolidated here as well:
 * * v1StudentsIdActiveGoalsGet
 * * v1StudentsIdPrescribedCareProvisionsByDateGet
 */
function useCachedResponsesByStudentIndex(props: {
  draftSession: SessionResponse;
}): CachedResponsesByStudentIndexRecord | null {
  const { studentApiData, reset, initialize } = useCachedResponses();

  useEffect(() => {
    if (!props.draftSession) return;

    reset(props.draftSession);
    initialize(props.draftSession);
  }, [props.draftSession.studentJournalList?.length]);

  return studentApiData;
}

function useCachedResponses() {
  const clientID = useXNGSelector(selectClientID);
  const stateInUS = useXNGSelector(selectStateInUS);

  const [studentApiData, setStudentApiData] = useState<CachedResponsesByStudentIndexRecord | null>(
    null,
  );

  function reset(draftSession: SessionResponse) {
    setStudentApiData(
      draftSession.studentJournalList?.map(
        () =>
          ({
            studentProfile: {},
          }) as CachedResponses,
      ) ?? [],
    );
  }

  async function initialize(draftSession: SessionResponse) {
    if (!draftSession.studentJournalList) return;

    const notatorStudentApiCache = await Promise.all(
      draftSession.studentJournalList.map(async (s) => {
        const studentProfile = await API_STUDENTS.v1StudentsIdGet(
          s.student!.id!,
          clientID,
          stateInUS,
        );

        const studentCache: CachedResponses = {
          studentProfile,
        };

        return studentCache;
      }),
    );

    setStudentApiData(notatorStudentApiCache);
  }

  return { studentApiData, setStudentApiData, reset, initialize };
}

export default useCachedResponsesByStudentIndex;
