import { ReportingPeriod } from "../../../../../profile-sdk";

export interface ReportingPeriodWithStartEndDate extends ReportingPeriod{
    startDate: string 
}

export function transformReportingPeriodDateRange(
    reportingPeriod: ReportingPeriod[],
    yearRangeSelected:string
):ReportingPeriodWithStartEndDate[] {
   return reportingPeriod.map((item,idx) => {
      if(idx === 0){
        return {
            ...item,
            startDate: yearRangeSelected.split("-")[0] + '-08-01'
        }
      }
      let previousDate = new Date(reportingPeriod[idx - 1].date as string);  
      previousDate.setUTCDate(previousDate.getUTCDate() + 1); // Increment the date by 1 day

      return {
        ...item,
        startDate: previousDate.toUTCString() // Set the start date to the incremented date
      };
})
}



export function getFormattedDateTimeInUtc(dateString: string): { date: string, time: string } {
  const date = new Date(dateString);
  
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11, so add 1
  const day = date.getUTCDate();

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm} UTC`;

  return { date: formattedDate, time: formattedTime };
}
