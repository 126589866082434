import { Outlet, useMatch } from "react-router";
import ReportsLayout from "../../../reports_layout";
import { ROUTES_XLOGS } from "../../../../../constants/URLs";
import { useMemo } from "react";

export const SessionLogsLayout = () => {
  const isSessionLogs = useMatch(ROUTES_XLOGS.reports.sessionLogs);
  const isServiceReport = useMatch(ROUTES_XLOGS.reports.serviceReport);

  const title = useMemo(() => {
    if (isSessionLogs) return "Session Logs";
    if (isServiceReport) return "Service Report";
    return "Reports";
  }, [isSessionLogs, isServiceReport]);

  return (
    <ReportsLayout title={title} gutterTop>
      <Outlet />
    </ReportsLayout>
  );
};
