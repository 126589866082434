import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GREY_COLOR } from '../../../../../design/high-level/xngbigtable/constants/grey_color';
import ProgressReportingStandardBtn from './progress_reporting_standard_btn';
import { StudentFitlerTableTitle } from '../utils/progress_report_fitler_edit_data_grid_helper';
import { XNGRadio } from '../../../../../design';
import { FormControlLabel, Input, RadioGroup, Stack, TextareaAutosize, Typography } from '@mui/material';
import useProgressReporting from '../context/hooks/useProgressReporting';
import { ProgressReportingActionType } from '../context/progress_reporting_actions';
import { ReportStatus, ReportTypes } from '../context/reducer/progress_reporting_reducer';
import { getFormattedDateTime } from '../../../../admin/views/district_profile/utils/date_utils';
import { GeneralCommentsPayloadManager, GoalObjectiveStatusPayloadManager, IsMakingAdequateProgressPayloadManager, MapUserInputsToRootPayloadManager, ProgressMeasuredPayloadManager } from '../context/payload.manager';
import IfElseBox from '../../../../../design/components-dev/if_else_box';
import { ExtendedStudentProgressDetailsWithAggregatedProgress } from '../types/extended_progress_repoting_types';
import { getProgress } from '../utils/progress_helper';
import useApiMutateData from '../../../../../api/hooks/use_api_mutate_data';
import { API_STUDENT_PROGRESS_REPORT } from '../../../../../api/api';
import { StudentProgressReportUpdateParameters, V1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRequest } from '@xng/reporting';
import {ProgressReportApiParametersGenerator} from '../utils/progressReportApiParametersGenerator'
import { ServiceProviderResponse } from '../../../../../profile-sdk';
import { pushError } from '../../../../../context/slices/errorEntries';
import { useXNGDispatch } from '../../../../../context/store';
import FullPageLoadingScreen from '../../../../../design/high-level/common/full_page_loading_screen';

interface GoalAreaProgressData {
  period: number;
  reportingPeriodStart: string;
  reportingPeriodEnd: string;
  makingAdequateProgress: "yes" | "no";
  progressMeasured: number;
  generalComments?: string;
  reportedProgress?: string;
  posted: boolean,
  isFinalized: boolean
}

enum StateKeys {
  period = "period",
  reportingPeriodStart = "reportingPeriodStart",
  reportingPeriodEnd = "reportingPeriodEnd",
  makingAdequateProgress = "makingAdequateProgress",
  progressMeasured = "progressMeasured",
  generalComments = "generalComments",
  reportedProgress = "reportedProgress",
}

function createData(
  period: number,
  reportingPeriodStart: string,
  reportingPeriodEnd: string,
  makingAdequateProgress: "yes" | "no",
  progressMeasured: number,
  generalComments?: string,
  reportedProgress?: string,
  posted: boolean = true,
  isFinalized: boolean = false
): GoalAreaProgressData {
  return { period, reportingPeriodStart, reportingPeriodEnd, makingAdequateProgress, progressMeasured, generalComments, reportedProgress, posted ,isFinalized};
}



interface EditProgressReportFilterProps { 
  goalProgressDetails:ExtendedStudentProgressDetailsWithAggregatedProgress[];
  goalId?:string;
  sx?:React.CSSProperties;
  reportRunId:string;
  progressReportId:string;
  studentName:string
}

const EditGoalProgressFilterTable: React.FC<EditProgressReportFilterProps> = ({studentName, goalProgressDetails, goalId, sx, reportRunId, progressReportId }) => {
  const dispatch = useProgressReporting().dispatch;
  const isPrintingSignal = useProgressReporting()?.state?.printingSignal;
  const showTeamAggregatedData = useProgressReporting()?.state?.settingsPopup?.showTeamAggregatedData;
  const showLastSessionData = useProgressReporting()?.state?.settingsPopup?.showLastSession;
  const currentlyLoggedInServiceProvider = useProgressReporting()?.state?.currentlyLoggedInServiceProvider
  const studentProgressReportFetched = useProgressReporting()?.state?.studentProgressReportFetched;
  const currentlySelectedServiceProvider = useProgressReporting()?.state?.settingsPopup?.serviceProviderSelected;
  const errorDispatcher = useXNGDispatch(); 
  const postProgressReportedMutation = useApiMutateData({
    mutationFn:async(data:V1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRequest)=> await API_STUDENT_PROGRESS_REPORT.v1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRaw(data),
    mutationKey:["v1StudentReportsStudentProgressReportUpdateStudentProgressReportPostRaw"],
    onError:(err) => {
      displayError(err)
    }
  })
  // Memoized rows creation
  const rows = React.useMemo(() => {
    return goalProgressDetails.map((row) =>
      createData(
        row?.period as number,
        getFormattedDateTime(row?.periodStartDate?.toISOString() as string).date ?? '',
        getFormattedDateTime(row?.periodEndDate?.toISOString() as string).date ?? '',
        row?.makingAdiquateProgress ? 'yes' : 'no',
        getProgress(
          showTeamAggregatedData,
          showLastSessionData,
          row
        ) ,
        row?.generalComments ?? '',
        row?.isFinalized ? row?.reportFinalizedBy as string : row?.progressReportedBy as string,
        row?.isFinalized,
        row?.isFinalized
      )
    );
  }, [goalProgressDetails,showTeamAggregatedData,showLastSessionData]);

  // Create unique rows based on reportingPeriodStart and reportingPeriodEnd
  const uniqueRows = React.useMemo(() => {
    const seen = new Set();
    return rows.filter(row => {
      const identifier = `${row.reportingPeriodStart}-${row.reportingPeriodEnd}`;
      if (!seen.has(identifier)) {
        seen.add(identifier);
        return true; // keep this row
      }
      return false; // skip this row
    });
  }, [rows]);

  // update post status 
  const handlePostProgress =(goalId: string, period: number, rowPosition: number,generalComments:string,progressMeasured:number,makingAdequateProgress:boolean)=>{
    let  studentProgressReportUpdateParameters:StudentProgressReportUpdateParameters[]=[]

    if(showTeamAggregatedData){
         studentProgressReportUpdateParameters = ProgressReportApiParametersGenerator.generateParamsForProgressReportUpdateForTeamAggregatedData( 
           goalId,
           period, 
           currentlyLoggedInServiceProvider as ServiceProviderResponse,
           studentProgressReportFetched?.pageRecords ?? [],
           "",
          {
           generalComments,
           progressMeasured,
           makingAdiquateProgress:makingAdequateProgress,
           studentName 
          }
         )
    }else{
      // progress report under individual service providers
        studentProgressReportUpdateParameters = ProgressReportApiParametersGenerator.generateParamsForProgressReportUpdateForAggregatedData(
          goalId,
          period, 
          currentlyLoggedInServiceProvider as ServiceProviderResponse,
          studentProgressReportFetched?.pageRecords ?? [],
          currentlySelectedServiceProvider?.id as string,
          "",
          {
            generalComments,
            progressMeasured,
            makingAdiquateProgress:makingAdequateProgress,
            studentName
          }
        )
    }
    postProgressReportedMutation.mutate({updateStudentProgressReportPostRequest:{
       studentProgressReportUpdateParameters
    }})
    dispatch({
      type:ProgressReportingActionType.SET_FINALIZING_PROGRESS_LOADER
    })
    // make all goals with this id finalized
    if(showTeamAggregatedData){
      dispatch({
        type: ProgressReportingActionType.MAP_TEAM_AGGREGATED_FINALIZED_DATA_TO_ROOT_PROGRESS_REPORT_UNDER_EACH_SERVICE_PROVIDERS,
        payload: MapUserInputsToRootPayloadManager.of(
          goalId, 
          period, 
          ReportTypes.Goal, 
          ReportStatus.Posted, 
          rowPosition,
          "",
          0,
          progressMeasured,
          makingAdequateProgress,
          generalComments
        )
      })
    }

    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
      payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Goal, ReportStatus.Posted, rowPosition)
    })


  }


  const handleUnpost=(goalId: string, period: number, rowPosition: number)=>{
    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
      payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Goal, ReportStatus.Unposted, rowPosition)
    })
  }

  async function displayError(arg: Error) {
    errorDispatcher(
           pushError({ 
              titleID: "Failed to fetch student progress report!", 
              message: arg?.message 
            }))
  }

  if (isPrintingSignal && uniqueRows?.length === 0) {
    return null;
  }

  if(isPrintingSignal && rows?.length === 0){
    return   null
  }
  
  return (
    <TableContainer sx={{ minWidth: 800, flexShrink: 0, ...sx }} component={Paper}>
       {postProgressReportedMutation.isPending && <FullPageLoadingScreen text='updating progress' pulse />}
      <Table sx={{ minWidth: 800 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ bgcolor: GREY_COLOR }}>
            {Object.entries(StudentFitlerTableTitle).map(([key, value]) => (
              <TableCell
                sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', textAlign: 'center' }}
                align="center"
                key={key}
              >
                {value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
  
        <IfElseBox if={uniqueRows.filter(row => row.posted)?.length === 0 && isPrintingSignal} then={<EmptyTableBody />} else={
           <React.Fragment>
                  {uniqueRows?.map((row, rowPosition) => isPrintingSignal ? row?.posted ?(
                              <TableRow key={`${row.period}-${rowPosition}`}>
                                {Object.entries(row).map(([key, value]) => (
                                  <ConditionalProgressReportDataGridCellRenderer
                                    makingAdequateProgressString={row.makingAdequateProgress}
                                    isPosted={row.posted}
                                    goalId={goalId}
                                    period={row.period}
                                    rowPosition={rowPosition}
                                    key={key}
                                    identifier={key as StateKeys}
                                    value={value}
                                    isFinalized = {row.isFinalized}
                                  />
                                ))} 
                              </TableRow>
                            ): null : 
                            <TableRow key={`${row.period}-${rowPosition}`}>
                            {Object.entries(row).map(([key, value]) => (
                              <ConditionalProgressReportDataGridCellRenderer
                                    isFinalized = {row?.isFinalized}
                                    makingAdequateProgressString={row?.makingAdequateProgress}
                                    isPosted={row?.posted}
                                    goalId={goalId}
                                    period={row?.period}
                                    rowPosition={rowPosition}
                                    key={key}
                                    identifier={key as StateKeys}
                                    value={value}
                                    />
                            ))}
                            <TableCell sx={{ display: "grid", placeContent: "center", marginTop: "10px" }}>
                              <IfElseBox  if={row?.isFinalized} 
                                          then={<ProgressReportingStandardBtn 
                                            title='Edit' 
                                            disabled={row?.isFinalized}
                                            onSubmit={() => handleUnpost(goalId as string, row?.period, rowPosition)}
                                            sxOverRide={{ marginLeft: "10px", background: "red" }} />
                                          }
                                          else={<ProgressReportingStandardBtn 
                                                  disabled={row?.isFinalized}
                                                  title='Finalize' 
                                                  onSubmit={() =>handlePostProgress(goalId as string, row.period, rowPosition,row?.generalComments as string,row?.progressMeasured,row?.makingAdequateProgress === "yes")}
                                                  sxOverRide={{ marginLeft: "10px" }} />
                                                } /> 
                            </TableCell>
                            </TableRow> )}
           </React.Fragment>
        } />
    
        
      </Table>
    </TableContainer>
  );
};


const EmptyTableBody = () => {
  return (
    <TableBody>
      <TableRow> 
          <TableCell colSpan={Object.keys(StudentFitlerTableTitle).length} sx={{ border: '1px solid rgba(0, 0, 0, 0.16)' }} align="center"  >{  "No records posted"}</TableCell>
             
      </TableRow>
    </TableBody>
  )
}

interface ConditionalProgressReportDataGridCellRendererProps {
  goalId?:string,
  period?:number, 
  identifier: StateKeys, 
  value: string, 
  isPrintingOrDownloading?: boolean, 
  rowPosition?:number,
  isPosted:boolean,
  makingAdequateProgressString?:"yes"|"no",
  isFinalized:boolean
}
 
const ConditionalProgressReportDataGridCellRenderer: React.FC<ConditionalProgressReportDataGridCellRendererProps> = 
(props) => {
  let { isFinalized,identifier, value, isPrintingOrDownloading = false,goalId, period, rowPosition,isPosted,makingAdequateProgressString="no" } = props
  const isPrinting = useProgressReporting()?.state?.printingSignal
  const [toggleStatus, setToggleStatus] = React.useState(makingAdequateProgressString === "yes")
  const [comment, setComment] = React.useState("")
  const dispatch = useProgressReporting()?.dispatch 
  const [progress,setProgress] = React.useState(0) 

  const handleUnpost= React.useCallback((goalId: string, period: number, rowPosition: number)=>{
    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_REPORT_POST_UNPOST_STATUS,
      payload: GoalObjectiveStatusPayloadManager.of(goalId, period, ReportTypes.Goal, ReportStatus.Unposted, rowPosition)
    })
  },[])

  let children = null 
  
  React.useEffect(()=>{
     if(identifier === StateKeys.generalComments){
       setComment(value)
     }
     else if(identifier === StateKeys.progressMeasured){
       setProgress(value as unknown as number)
      value = value? `${value}%` : value
    } 
    
  },[identifier,value])

  const handlePercentageChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let val = Number(event.target.value) 
    if(isPosted){
      handleUnpost(goalId as string,period as number, rowPosition as number)
    }
    if(val >100){
      setProgress(0)
    }else if(val < 0){
      setProgress(100)
    }else {
      setProgress(val)
    }
  },[isPosted])

  const handleProgressMeasuredOnBlur = React.useCallback(()=>{ 
    dispatch({
      type: ProgressReportingActionType.UPDATE_PROGRESS_MEASURED_IN_REPORTING_DATA,
      payload: ProgressMeasuredPayloadManager.of(goalId as string, period as number,ReportTypes.Goal, rowPosition as number, progress)
    })
  },[goalId,period,progress])
 
  const handleGeneralCommentsChange = React.useCallback((e:React.ChangeEvent<HTMLTextAreaElement>)=>{
    setComment(e.target.value)
    if(isPosted){ 
          handleUnpost(goalId as string,period as number,rowPosition as number)
    }
   },[ goalId,period,comment,rowPosition,isPosted])

  const handleGeneralCommentsOnBlur = React.useCallback(()=>{
    dispatch({
      type:ProgressReportingActionType.UPDATE_GENERAL_COMMENTS_IN_REPORTING_DATA,
      payload:GeneralCommentsPayloadManager.of(
        goalId as string,
        period as number,
        comment,ReportTypes.Goal,
        rowPosition as number
      ) 
    }) 
   },[ goalId,period,comment,ReportTypes.Goal,rowPosition])

  const handleMakingAdequateProgress = React.useCallback(()=>{
    setToggleStatus(!toggleStatus)
    dispatch({
      type:ProgressReportingActionType.HANDLE_MAKING_ADEQUATE_PROGRESS,
      payload:IsMakingAdequateProgressPayloadManager.of(
        goalId as string,
        period as number,
        ReportTypes.Goal,
        rowPosition as number,
        !toggleStatus  
      ) 
    }) 
   },[ goalId, period, comment, ReportTypes.Goal, rowPosition, toggleStatus])

  

  const commonStyles:React.CSSProperties = React.useMemo(()=>({
    border:"1px solid rgba(0, 0, 0, 0.16)",
    borderTop: rowPosition !==0  ? "2px solid solid rgba(0, 0, 0, 0.16)" :""
  }),[])


  if(!isFinalized && isPrinting)return null


  children = {
    [StateKeys.period]: <TableCell align="center" sx={{ ...commonStyles, width: "5% !important" }} >{(rowPosition as number)+1}</TableCell>,
    [StateKeys.reportingPeriodStart]: <TableCell align="center" sx={{ ...commonStyles, minWidth: "120px" , width: "15%" }} >{value}</TableCell>,
    [StateKeys.reportingPeriodEnd]: <TableCell align="center" sx={{...commonStyles, minWidth: "120px", width: "15%" }} >{value}</TableCell>,
    [StateKeys.makingAdequateProgress]: (<TableCell sx={{...commonStyles, width: "10%" }}>
      <RadioGroup name='makeAdequateProgress' sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
        {
          isFinalized ?
           makingAdequateProgressString  :
            (
            <>
              <FormControlLabel value={"Yes"} control={<XNGRadio disabled={isFinalized} checked={toggleStatus} />} label="Yes" onChange={handleMakingAdequateProgress} />
              <FormControlLabel value={"No"} control={<XNGRadio disabled={isFinalized} checked={!toggleStatus} />} label="No" onChange={handleMakingAdequateProgress} />
            </>
            )
        
        }
      </RadioGroup>
    </TableCell>),
    [StateKeys.progressMeasured]: <TableCell align="center" onBlur={handleProgressMeasuredOnBlur} sx={{ ...commonStyles, width: "20%" }} > 
        {  !isFinalized ? <> <Input type="number" disabled={isFinalized} onChange={ handlePercentageChange}  value={progress} sx={{  width:"60px" }} /> %</> : <>{value}%</>} 
    </TableCell>,
    [StateKeys.generalComments]: (
      <TableCell align="center" sx={{ ...commonStyles, width: "30% !important",maxWidth:"150px" }} >
        {  !isFinalized ?
         <TextareaAutosize 
         disabled={isFinalized}
         onChange={handleGeneralCommentsChange} 
         onBlur={handleGeneralCommentsOnBlur}
         style={{minHeight:"100px" ,resize:"none",width:"100%"}} placeholder='General Comments' value={comment} />:
                  <Typography  textAlign={"left"} sx={{  minWidth: "100px", overflow: "hidden",  textOverflow: "ellipsis", wordWrap: "break-word" }}>
                     {comment}
                </Typography>
         }
      </TableCell>),
    [StateKeys.reportedProgress]: <TableCell align="center" sx={{ ...commonStyles, width: "10%", minWidth: "150px" }} >{value}</TableCell>

  }[identifier]

  let printingOrDownloading = null
  printingOrDownloading = {
    [StateKeys.period]: <TableCell align="center" sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop: "3px solid rgba(0, 0, 0, 0.16)", borderLeft: "3px solid rgba(0, 0, 0, 0.16)", width: "5% !important" }} >{rowPosition}</TableCell>,
    [StateKeys.reportingPeriodStart]: <TableCell align="center" sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", minWidth: "120px", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "15%" }} >{value}</TableCell>,
    [StateKeys.reportingPeriodEnd]: <TableCell align="center" sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", minWidth: "120px", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "15%" }} >{value}</TableCell>,
    [StateKeys.makingAdequateProgress]: (<TableCell sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "10%" }}>
      <Stack sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
        {value}
      </Stack>
    </TableCell>),
    [StateKeys.progressMeasured]: <TableCell align="center" sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "20%" }} >{value}</TableCell>,
    [StateKeys.generalComments]: (
      <TableCell align="center" sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "30% !important" }} >
        <Stack sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center", alignItems: "center" }}>
          {value}
        </Stack>
      </TableCell>),
    [StateKeys.reportedProgress]: <TableCell align="center" sx={{ borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop: "3px solid rgba(0, 0, 0, 0.16)", width: "10%", minWidth: "150px" }} >{value}</TableCell>

  }[identifier]

 
  return isPrintingOrDownloading ? printingOrDownloading : children
};

export default EditGoalProgressFilterTable;
