import { Box, Stack, Typography } from "@mui/material";
import { MSBDataGridPopoverMenu } from "../../components/msb_data_grid_popover_menu";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const MSBDataGridPopoverMenuDemo = () => {
  const columnDef: GridColDef[] = [
    {
      field: "firstNames",
      headerName: "First Names",
      flex: 1,
      sortable: false,
      renderCell(params) {
        return (
          <MSBDataGridPopoverMenu
            value={params.value as string[]}
            getRenderValue={(value) => value}
            getUniqueKey={(value) => value}
            emtpyValue="No Campus Selected"
          />
        );
      },
    },
    {
      field: "lastNames",
      headerName: "Last Names",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <MSBDataGridPopoverMenu
            value={params.value as string[]}
            getRenderValue={(value) => value}
            getUniqueKey={(value) => value}
            emtpyValue="No Campus Selected"
          />
        );
      },
    },
  ];

  return (
    <Stack gap="2rem">
      <Typography>
        Our standard component for rendering multiple values in a single datagrid cell
      </Typography>

      <Stack gap="1rem">
        <Typography variant="h6">Component Example</Typography>
        <Typography variant="body1">
          Hover over table data to view multiple values in a single cell
        </Typography>
        <Box
          aria-label="demo-table"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "800px",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "contrasts.1",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              justifyContent: "left",
            },
            "& [role=row]:hover": {
              // color: "text.primary",
              cursor: "pointer",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={[
              {
                id: "1",
                firstNames: ["John", "Jane", "Jim"],
                lastNames: ["Brown"],
              },
              {
                id: "2",
                firstNames: ["Charlie"],
                lastNames: ["Johnson", "Williams", "Jones"],
              },
              {
                id: "3",
                firstNames: ["David", "Eve", "Frank"],
                lastNames: ["Thomas"],
              },
            ]}
            columns={columnDef}
            disableRowSelectionOnClick
            disableColumnSelector
            disableColumnFilter
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default MSBDataGridPopoverMenuDemo;
