import {
  ClientsApi,
  Configuration,
  DistrictsApi,
  PrescribingProvidersApi,
  ServiceProvidersApi,
  StateSnapshotsApi,
  StudentsApi,
  UsersApi,
} from "../profile-sdk";
import { SessionsApi } from "../session-sdk";
import {
  AccountStudentsApi,
  ClientSessionCountsApi,
  DuplicateStudentReportsApi,
  FinancialDashboardReportApi,
  HTTPHeaders,
  Configuration as ReportingConfig,
  SessionCountApi,
  SessionLogsApi,
  StudentProgressReportApi
} from "@xng/reporting";
import { APIM_SUBSCRIPTION_KEY } from "../constants/apimSubscriptionKey";
import { eventEmitter } from "../event_emitter";
import { APIConfigManager } from "./Api_Config";

/**
 * This file contains the API initialization logic.
 * It is called via msalEventCallback.ts when a user logs in or acquires a token.
 */

// Header used to authenticate with the Reporting API
// APIM_SUBSCRIPTION_KEY comes from the developers local environment
const reportingHeaders: HTTPHeaders = {};
if (APIM_SUBSCRIPTION_KEY) {
  reportingHeaders["Ocp-Apim-Subscription-Key"] = APIM_SUBSCRIPTION_KEY;
}
// This is a flag to check if the APIs have been initialized
let apiReady = false;

/**
 * Initializes the APIs with the bearer token.
 *
 * @param token - The bearer token used to authenticate the APIs.
 */
export function initializeAPIs(token: any) {
  // This is the API configuration manager instance that is used to get the API URLs
  const apiConfigManager = APIConfigManager.getInstance();

  // Initialize the API configurations with the bearer token

  const PROFILE_CONFIG = new Configuration({
    apiKey: `Bearer ${token}`,
    basePath: apiConfigManager.config.PROFILE_API_URL,
  });

  const SESSIONS_CONFIG = new Configuration({
    apiKey: `Bearer ${token}`,
    basePath: apiConfigManager.config.SESSION_API_URL,
  });

  // The Reporting API configuration additionally includes the headers from the reportingHeaders object
  const REPORTING_CONFIG = new ReportingConfig({
    accessToken: token,
    basePath: apiConfigManager.config.REPORTING_API_URL,
    headers: reportingHeaders,
  });

  // Initialize the APIs with the configurations
  API_SESSION_LOGS = new SessionLogsApi(REPORTING_CONFIG);
  API_DUPLICATE_STUDENT_REPORTS = new DuplicateStudentReportsApi(REPORTING_CONFIG);
  API_ACCOUNT_STUDENTS = new AccountStudentsApi(REPORTING_CONFIG);
  API_SESSIONS_COUNT = new SessionCountApi(REPORTING_CONFIG);
  API_CLIENT_SESSION_COUNTS = new ClientSessionCountsApi(REPORTING_CONFIG);
  API_FINANCIAL_DASHBOARD_REPORT = new FinancialDashboardReportApi(REPORTING_CONFIG);
  API_STUDENTS = new StudentsApi(PROFILE_CONFIG);
  API_DISTRICTS = new DistrictsApi(PROFILE_CONFIG);
  API_USERS = new UsersApi(PROFILE_CONFIG);
  API_CLIENTS = new ClientsApi(PROFILE_CONFIG);
  API_STATESNAPSHOTS = new StateSnapshotsApi(PROFILE_CONFIG);
  API_SERVICEPROVIDERS = new ServiceProvidersApi(PROFILE_CONFIG);
          API_PRESCRIBING_PROVIDER = new PrescribingProvidersApi(PROFILE_CONFIG)
  API_SESSIONS = new SessionsApi(SESSIONS_CONFIG);
  API_STUDENT_PROGRESS_REPORT = new StudentProgressReportApi(REPORTING_CONFIG);

  // If the APIs have not been initialized, dispatch an event to let the app know that the APIs are ready
  // This is used in the AuthProvider component
  if (!apiReady) {
    const event = new CustomEvent("apiInitialized");
    eventEmitter.dispatchEvent(event);
  }
  // Set the flag to true to indicate that the APIs have been initialized, preventing them from being unnecessarily initialized again
  apiReady = true;
}

// Export the APIs
export let API_SESSION_LOGS: SessionLogsApi;
export let API_DUPLICATE_STUDENT_REPORTS: DuplicateStudentReportsApi;
export let API_ACCOUNT_STUDENTS: AccountStudentsApi;
export let API_SESSIONS_COUNT: SessionCountApi;
export let API_STUDENTS: StudentsApi;
export let API_DISTRICTS: DistrictsApi;
export let API_USERS: UsersApi;
export let API_CLIENTS: ClientsApi;
export let API_STATESNAPSHOTS: StateSnapshotsApi;
export let API_SERVICEPROVIDERS: ServiceProvidersApi;
export let API_SESSIONS: SessionsApi;
export let API_CLIENT_SESSION_COUNTS: ClientSessionCountsApi;
export let API_FINANCIAL_DASHBOARD_REPORT: FinancialDashboardReportApi;
export const REPORTING_HEADERS = reportingHeaders;
export let API_PRESCRIBING_PROVIDER:PrescribingProvidersApi;
export let API_STUDENT_PROGRESS_REPORT:StudentProgressReportApi