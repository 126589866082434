import { Box, Button, Dialog, Stack, Typography, useTheme } from "@mui/material";
import { useXNGDispatch, useXNGSelector } from "../context/store";
import { clearErrors, ErrorEntry, selectErrorEntries } from "../context/slices/errorEntries";
import MSBClose from "../fortitude/components/button_close";
import { AiOutlineExclamation } from "react-icons/ai";
import { MSBIconRenderer } from "../fortitude";
import { CodeBlock } from "../components/code_block";

export function ErrorEntriesModal() {
  const errors = useXNGSelector(selectErrorEntries);
  const dispatch = useXNGDispatch();
  const { palette } = useTheme();

  function handleClose() {
    dispatch(clearErrors());
  }

  return (
    <Dialog open={errors.length > 0} onClose={handleClose} fullWidth maxWidth="sm">
      <Stack sx={{ p: "1rem", gap: "2rem" }}>
        <Stack>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <MSBClose onClick={handleClose} />
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", mb: "1rem" }}>
            <RedWarningDot />
            <Typography variant="h6">We ran into a problem</Typography>
          </Box>
          <Typography variant="body1" sx={{ color: palette.text.secondary }}>
            We're sorry for the inconvenience, the following errors occurred. If this is blocking
            your work, please reach out to a program specialist.
          </Typography>
        </Stack>

        <Stack gap="1rem">
          {errors.map((error, i) => (
            <ErrorEntryCard key={i} error={error} index={i + 1} />
          ))}
        </Stack>

        <Button variant="outlined" size="large" onClick={handleClose}>
          Okay, continue
        </Button>
      </Stack>
    </Dialog>
  );
}

function ErrorEntryCard(props: Readonly<{ error: ErrorEntry; index: number }>) {
  const { error, index } = props;
  const errors = useXNGSelector(selectErrorEntries);

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Box
        sx={{
          my: ".45rem",
          mx: "1rem",
        }}
      >
        <RedDot />
      </Box>

      <Stack sx={{ pr: "2rem", gap: "1rem" }}>
        <Typography variant="body1">
          <b>
            Error ({index}/{errors.length}):{" "}
          </b>
          {error.titleID}
        </Typography>
        {error.message && (
          <Box sx={{ borderLeft: ".2rem #0003 solid", pl: "1rem" }}>
            <Typography variant="body1">{error.message}</Typography>
          </Box>
        )}
        {error.codeBlockMessage && <CodeBlock literal={error.codeBlockMessage} />}
        {error.tryCatchErrorMessage !== undefined && (
          <CodeBlock literal={deriveStringFromTryCatchErrorObj(error.tryCatchErrorMessage)} />
        )}
      </Stack>
    </Box>
  );
}

function deriveStringFromTryCatchErrorObj(obj: unknown): string {
  const readableError =
    obj instanceof Error
      ? `${obj.message}\n\n${obj.stack}`
      : JSON.stringify(obj, null, 2) || String(obj);

  return readableError;
}

function RedWarningDot() {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "3rem",
        height: "3rem",
        borderRadius: "999px",
        bgcolor: palette.error[1],
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <MSBIconRenderer color={palette.error.main} i={<AiOutlineExclamation />} size={2} />
    </Box>
  );
}

function RedDot() {
  const { palette } = useTheme();

  const SIZE = ".5rem";

  return (
    <Box
      sx={{
        minWidth: SIZE,
        minHeight: SIZE,
        maxWidth: SIZE,
        maxHeight: SIZE,
        borderRadius: SIZE,
        bgcolor: palette.error.main,
      }}
    />
  );
}
