import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { XNGIconRenderer } from "../../../design";
import { MSBIconRenderer, MSBICONS } from "../../../fortitude";
import useUnpostedSessionsContext from "../hooks/use_unposted_sessions_context";
import useUnpostedSessionsMatchPath from "../hooks/use_unposted_sessions_match_path";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useUnpostedSessionsViewState from "../hooks/use_unposted_sessions_view_state";
import { ROUTES_XLOGS } from "../../../constants/URLs";
import { useEffect } from "react";
import { timezoneAdjustedStartOrEndTimes } from "../../../utils/timeZones";
import dayjs from "dayjs";

const UnpostedSessionsNotatorView = () => {
  const { CaretOutline, LoadingAnimation } = MSBICONS;
  const browseBtnColor = useTheme().palette.primary[3];
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const viewParamId = params.viewId as ReturnType<typeof useUnpostedSessionsMatchPath>["customId"];

  const sessionIdUrlDynamicSegment = params.sessionId as string;
  const seriesIdUrlParam = queryParams.get("seriesId");
  const serviceProviderIdUrlParam = queryParams.get("serviceProviderId");
  const dateUrlParam = queryParams.get("date");
  const notatorRenderkey = `${viewParamId}-${sessionIdUrlDynamicSegment}-${seriesIdUrlParam}-${serviceProviderIdUrlParam}-${dateUrlParam}`;

  const navigate = useNavigate();
  const context = useUnpostedSessionsContext();

  const viewState = useUnpostedSessionsViewState({
    viewParamId,
    context,
  });

  const initializeVirtualSessionRequestInfo = viewState.state.notatorApiRequests.get(
    "initializeVirtualSession",
  );

  const disableBtns =
    viewState.state.selectedServiceProviders.length === 0 ||
    viewState.state.allFilteredSessions.length <= 1;


  const { timezoneAdjustedStartTime: sessionStartTime } = timezoneAdjustedStartOrEndTimes(
    context.stateInUs,
    "display",
    dayjs(viewState.state.currentSessionInNotatorView?.session.startTime),
    // we dont care about this value here but we need it for the function to work, and endTime doesnt exist on SessionSlimCard
    dayjs(viewState.state.currentSessionInNotatorView?.session.startTime),
  );

  /**
   * This effect handes navigation for view prev/next session (NOTE: Consider having buttons directly call navigate)
   */
  useEffect(() => {
    const currentSessionRef = viewState.state.currentSessionInNotatorView;
    if (currentSessionRef) {
      const path = `${ROUTES_XLOGS.unposted_sessions.index}/${viewParamId}/notator/${currentSessionRef?.session.id}?serviceProviderId=${currentSessionRef.providerId}&seriesId=${currentSessionRef?.session.seriesId}&date=${currentSessionRef?.session.startTime}`;
      navigate(path);
    }
  }, [
    navigate,
    viewParamId,
    viewState.state.currentSessionIndex,
    viewState.state.currentSessionInNotatorView,
    sessionIdUrlDynamicSegment,
  ]);

  return (
    <Accordion square elevation={0} defaultExpanded sx={{ position: "relative" }}>
      <AccordionSummary
        expandIcon={<XNGIconRenderer i={<CaretOutline />} size="xs" up />}
        disabled={disableBtns}
        sx={{
          backgroundColor: "white",
          borderBottom: "1px solid",
          borderColor: "contrasts.2",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Stack
          alignItems={"center"}
          sx={{
            flexDirection: {
              direction: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            width: "100%",
            gap: {
              xs: 1,
              md: 3,
            },
            paddingY: {
              xs: 2,
              sm: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Typography fontWeight={600} fontSize={"16px"}>
              Currently Viewing Session:
            </Typography>
            <Box
              sx={{
                backgroundColor: "primary.3",
                borderRadius: "3px",
                px: 1,
                color: "contrasts.main",
              }}
            >
              <Tooltip
                title={`${
                  viewState.state.currentSessionInNotatorView?.session.title ?? "No Session"
                } | ${
                  viewState.state.currentSessionInNotatorView?.session.startTime
                    ? new Date(
                        // @ts-ignore
                        sessionStartTime.toDate(),
                      )?.toLocaleString("en-US", {
                        timeStyle: "short",
                        dateStyle: "short",
                      })
                    : "No Date"
                }`}
                placement="top"
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: "12px",
                    padding: "4px",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    verticalAlign: "middle",
                  }}
                >
                  {viewState.state.currentSessionInNotatorView?.session.title ??
                    "No Session Selected"}
                  {" | "}
                  {viewState.state.currentSessionInNotatorView?.session.startTime
                    ? new Date(
                        // @ts-ignore
                        sessionStartTime.toDate(),
                      )?.toLocaleString("en-US", {
                        timeStyle: "short",
                        dateStyle: "short",
                      })
                    : "No Date"}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          {(
            <Stack direction={"row"} fontSize={"12px"} mr={1}>
              <Button
                startIcon={
                  <MSBIconRenderer i={<CaretOutline />} size="xs" left color={browseBtnColor} />
                }
                variant="text"
                color="primary"
                size="small"
                disabled={disableBtns}
                onClick={(e) => {
                  e.stopPropagation();
                  viewState.dispatch({
                    type: "update_view_filtered_session_index",
                    payload: -1,
                  });
                }}
              >
                View Previous Session
              </Button>
              <Button
                endIcon={<MSBIconRenderer i={<CaretOutline />} size="xs" color={browseBtnColor} />}
                variant="text"
                color="primary"
                size="small"
                disabled={disableBtns}
                onClick={(e) => {
                  e.stopPropagation();
                  viewState.dispatch({
                    type: "update_view_filtered_session_index",
                    payload: 1,
                  });
                }}
              >
                View Next Session
              </Button>
            </Stack>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          overflowY: "scroll",
          px: 0,
          position: "relative",
        }}
      >
        <>
          {/* filteredCards, filteredProviders, filteredsessions, navigate, selectedSessionIndex */}
          {viewState.state.currentSessionInNotatorView ? (
            <Outlet key={notatorRenderkey} />
          ) : (
            <Alert severity="info">No Sessions to Post</Alert>
          )}
          {initializeVirtualSessionRequestInfo?.status !== "success" &&
            initializeVirtualSessionRequestInfo?.status !== "idle" && (
              <Box
                sx={{
                  backgroundColor: `hsl(0deg 0% 0% / ${
                    initializeVirtualSessionRequestInfo?.status === "error" ? "65%" : "30%"
                  })`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                {initializeVirtualSessionRequestInfo?.status === "error" && (
                  <Alert severity="warning" color="error" sx={{ mt: 2, mx: 2 }} square>
                    <Typography variant="body1">
                      There was a problem saving this session, please try again later.
                    </Typography>
                  </Alert>
                )}
                {initializeVirtualSessionRequestInfo?.status === "pending" && (
                  <Alert
                    square
                    severity="info"
                    color="info"
                    sx={{ mt: 2, mx: 2 }}
                    iconMapping={{
                      info: <LoadingAnimation width={"20px"} height={"20px"} />,
                    }}
                  >
                    <Typography variant="body1">Please wait, saving session...</Typography>
                  </Alert>
                )}
              </Box>
            )}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default UnpostedSessionsNotatorView;
