import { API_SESSION_LOGS } from "../../../../../../api/api";
import { ApiResponse, V1SessionReportsSessionLogsGetReportPostRequest } from "@xng/reporting";
import XNGApiMutateParamObject from "../../../../../../types/xng_api_mutate_param_object";
import useApiMutatePollData from "../../../../../../api/hooks/use_api_mutate_poll_data";

type Data = Awaited<
  ReturnType<typeof API_SESSION_LOGS.v1SessionReportsSessionLogsGetReportPostRaw>
> extends ApiResponse<infer T>
  ? T
  : never;
type Body = V1SessionReportsSessionLogsGetReportPostRequest; // generic parameter B is used to define body the type of the useApiMutateData hook

export const useApiMutatePollSessionLogsReport = (
  paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>,
) => {
  return useApiMutatePollData({
    mutationFn: async (body: Body) =>
      await API_SESSION_LOGS.v1SessionReportsSessionLogsGetReportPostRaw(body),
    mutationKey: ["v1SessionReportsSessionLogsGetReportPostRaw"],
    ...(paramObject?.options || {}),
  });
};

export type { Data as SessionLogsReportData, Body as SessionLogsReportBody };
