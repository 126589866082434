import { SessionStatus } from "@xng/reporting/dist/models";
/* Enum: Scheduled = 0, InProgress = 1, AwaitingApproval = 2, RevisionsRequested = 3, Posted = 4, Closed = 5
 * @export
 */
// export declare const SessionStatus: {
//    readonly NUMBER_0: 0;
//    readonly NUMBER_1: 1;
//    readonly NUMBER_2: 2;
//    readonly NUMBER_3: 3;
//    readonly NUMBER_4: 4;
//    readonly NUMBER_5: 5;
// };
export const sessionReportStatusOptions = [
  "Scheduled",
  "InProgress",
  "AwaitingApproval",
  "RevisionsRequested",
  "Posted",
  "Closed",
];

export function getSessionReportStatusStringFromEnum(session_report_status: SessionStatus) {
  switch (session_report_status) {
    case SessionStatus.NUMBER_0:
      return sessionReportStatusOptions[0];
    case SessionStatus.NUMBER_1:
      return sessionReportStatusOptions[1];
    case SessionStatus.NUMBER_2:
      return sessionReportStatusOptions[2];
    case SessionStatus.NUMBER_3:
      return sessionReportStatusOptions[3];
    case SessionStatus.NUMBER_4:
      return sessionReportStatusOptions[4];
    case SessionStatus.NUMBER_5:
      return sessionReportStatusOptions[5];
    default:
      return sessionReportStatusOptions[0];
  }
}

export function getSessionReportStatusEnumFromString(session_report_status_string: string) {
  switch (session_report_status_string) {
    case sessionReportStatusOptions[0]:
      return SessionStatus.NUMBER_0;
    case sessionReportStatusOptions[1]:
      return SessionStatus.NUMBER_1;
    case sessionReportStatusOptions[2]:
      return SessionStatus.NUMBER_2;
    case sessionReportStatusOptions[3]:
      return SessionStatus.NUMBER_3;
    case sessionReportStatusOptions[4]:
      return SessionStatus.NUMBER_4;
    case sessionReportStatusOptions[5]:
      return SessionStatus.NUMBER_5;
    default:
      return SessionStatus.NUMBER_0;
  }
}
