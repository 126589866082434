import { Box, Stack, Typography } from "@mui/material";
import LineChart from "../../amcharts/line_chart";

function LineChartDemo() {
    const dummyChartData = [
        { date: new Date(2023, 0, 1).getTime(), value: 50 },
        { date: new Date(2023, 0, 2).getTime(), value: 10 },
        { date: new Date(2023, 0, 3).getTime(), value: 60 },
        { date: new Date(2023, 0, 4).getTime(), value: 65 },
    ];
    return (
        <Stack gap="2rem">
            <Typography>Our standard component for creating pie charts.</Typography>

            <Stack>
                <Typography variant="h6">Required Prop</Typography>
                <Box component="ul" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <li>
                        <code>style: object</code>{" "}
                        <Typography display="inline" sx={{ml:2}}>Your custom css styles goes here</Typography>
                    </li>
                </Box>
                <Box component="ul" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <li>
                        <code>data: {"{date: number value: number}[]"} </code>{" "}
                        <Typography display="inline" sx={{ml:2}}>Your line chart data </Typography>
                    </li>
                </Box>
                <Box component="ul" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <li>
                        <code>{"xKey='date' yKey='value' xLabel='Date' yLabel='Value' "}</code>{" "}
                        <Typography display="inline" sx={{ml:2}}>Your line chart data </Typography>
                    </li>
                </Box>
              
            </Stack>

            <Stack gap="1rem">
                <Typography variant="h6">Sales per day</Typography>
                <LineChart style={{ width: "70%", height: "500px" }} data={dummyChartData} xKey="date" yKey="value" xLabel="Date" yLabel="Value" />
            </Stack>
        </Stack>
    );
}

export default LineChartDemo;
