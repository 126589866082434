import { Box, Button, Popover } from "@mui/material";
import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {
  SessionLogsObservations,
  SessionLogsReportData,
  useSessionLogsReportTableColumnDefinition,
} from "../../hooks";
import { useMemo, useRef, useState } from "react";
import { SessionLogsDataGridNoRowsOverlay } from "..";

type Props = {
  rows: NonNullable<SessionLogsReportData["pageRecords"]>;
  // columnDef: ReturnType<typeof useSessionLogsReportTableColumnDefinition>["sessionLogsReportTablecolumns"];
  paginationState: {
    paginationModel: GridPaginationModel;
    setPaginationModel: (paginationModel: GridPaginationModel) => void;
    totalRecords?: number;
  };
  sortingState: {
    sortModel: GridSortModel;
    setSortModel: (sortingModel: GridSortModel) => void;
  };
  loading: boolean;
};

export function SessionLogsReportTable(props: Readonly<Props>) {
  const columnDef = useSessionLogsReportTableColumnDefinition().sessionLogsReportTablecolumns;

  const rowCountRef = useRef(props.paginationState.totalRecords ?? 0);

  const rowCount = useMemo(() => {
    if (props.paginationState.totalRecords !== undefined) {
      rowCountRef.current = props.paginationState.totalRecords;
    }
    return rowCountRef.current;
  }, [props.paginationState.totalRecords]);

  return (
    <Box
      aria-label="session-logs-report-table"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "600px",
        minHeight: "400px",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          justifyContent: "left",
        },
      }}
    >
      <DataGrid

        rows={props.rows.map((row) => ({ id: crypto.randomUUID(), ...row }))}
        columns={columnDef}
        // getRowId={(row) => getRowId(row)}
        rowCount={rowCount}
        slots={{
          noRowsOverlay: SessionLogsDataGridNoRowsOverlay("No Session Logs Data found"),
        }}
        disableRowSelectionOnClick
        sortingMode="server"
        sortingOrder={["asc", "desc"]}
        sortModel={props.sortingState.sortModel}
        onSortModelChange={(newSortModel) => props.sortingState.setSortModel(newSortModel)}
        disableColumnSelector
        pagination
        disableColumnFilter
        paginationMode="server"
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        paginationModel={props.paginationState.paginationModel}
        onPaginationModelChange={props.paginationState.setPaginationModel}
        pageSizeOptions={[10, 20, 50, 100]}
        loading={props.loading}
      />
    </Box>
  );
}

type ObservationTableProps = {
  rows: SessionLogsObservations;
};
export function ObservationsTableCell(props: Readonly<ObservationTableProps>) {
  const columnDef = useSessionLogsReportTableColumnDefinition().observationsTablecolumns;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const expanded = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        variant="text"
      >
        View Observations
      </Button>
      <Popover
        open={expanded}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          aria-label="session-logs-nested-table"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "contrasts.1",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              justifyContent: "left",
              fontWeight: 700,
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={props.rows.map((row) => ({ id: row.refNumber, ...row }))}
            columns={columnDef}
            slots={{
              noRowsOverlay: SessionLogsDataGridNoRowsOverlay("No Observations Data found"),
            }}
            sortingOrder={["asc", "desc"]}
            disableRowSelectionOnClick
            disableColumnSelector
            // pagination
            paginationMode="client"
            initialState={{
              pagination: { paginationModel: { pageSize: 10, page: 0 } },
            }}
            // pageSizeOptions={[5]}
          />
        </Box>
      </Popover>
    </>
  );
}
