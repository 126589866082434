/**
 * Custom hook to initialize and save a virtual session when visiting a virtual session with no session id in the unposted sessiions Notator view.
 *
 * @param props - The properties required for initializing and saving the virtual session.
 * @param props.session - The session object from NotatorTools.
 * @param props.inUnpostedSessionsView - Boolean indicating if the current view is within unposted sessions.
 * @param props.refetchOnSessionAction - Function to refetch unposted sessions upon session action.
 * @param props.saveSession - Function to save the session, returns a Promise.
 *
 * @returns void
 *
 * This hook performs the following actions:
 * - Uses the `useEffect` hook to trigger the initialization and saving of a virtual session upon visiting the Notator view.
 * - Prevents multiple `saveSession` calls using a ref (`savingSessionRef`).
 * - Uses `useParams` to get URL parameters.
 * - Retrieves the unposted sessions context and view state.
 * - Uses the `useMutation` hook from `@tanstack/react-query` to handle the session save operation.
 * - Dispatches actions to update the view state based on the success or failure of the session save operation.
 *
 *
 * @example
 * ```typescript
 * const { session, inUnpostedSessionsView, refetchOnSessionAction, saveSession } = props;
 * useInitSaveUnpostedSessionsVirtualSession({
 *   session,
 *   inUnpostedSessionsView,
 *   refetchOnSessionAction,
 *   saveSession,
 * });
 * ```
 */
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useUnpostedSessionsMatchPath from "./use_unposted_sessions_match_path";
import useRefetchUnpostedSessionsRequests from "./use_refetch_unposted_sessions_requests";
import { NotatorTools } from "../../notator/tools";
import { useMutation } from "@tanstack/react-query";
import useUnpostedSessionsContext from "./use_unposted_sessions_context";
import useUnpostedSessionsViewState from "./use_unposted_sessions_view_state";

export default function useInitUnpostedSessionsVirtualSession(props: {
  session: NotatorTools["session"];
  inUnpostedSessionsView: ReturnType<
    typeof useRefetchUnpostedSessionsRequests
  >["inUnpostedSessionsView"];
  refetchOnSessionAction: ReturnType<
    typeof useRefetchUnpostedSessionsRequests
  >["refetchOnSessionAction"];
  saveSession: (session: any) => Promise<any>;
}) {
  const { session, saveSession, inUnpostedSessionsView, refetchOnSessionAction } = props;
  const savingSessionRef = useRef(false); //used to prevent multiple saveSession calls
  const params = useParams(); //returns an object with the URL parameters
  const unpostedSessionsContext = useUnpostedSessionsContext();
  const viewParamId = params.viewId as ReturnType<typeof useUnpostedSessionsMatchPath>["customId"]; //represents the viewId in the URL, set in the route definition
  const viewState = useUnpostedSessionsViewState({
    viewParamId,
    context: unpostedSessionsContext,
  });
  const { mutate: initVirtualSession } = useMutation({
    mutationKey: ["saveVirtualSessionUponUnpostedSessionNotatorVisitation"],
    mutationFn: async (session: NotatorTools["session"]) => {
      await saveSession(session);
    },
  });

  return useEffect(() => {
    if (!inUnpostedSessionsView) return;
    const saveVirtualSessionUponNotatorVisitation = async () => {
      if (!session.id && !!session.seriesId && !savingSessionRef.current) {
        savingSessionRef.current = true;
        viewState.dispatch({
          type: "set_notator_api_request_state",
          payload: {
            request: "initializeVirtualSession",
            status: "pending",
          },
        });
        initVirtualSession(session, {
          onSuccess: () => {
            refetchOnSessionAction();
            viewState.dispatch({
              type: "set_notator_api_request_state",
              payload: {
                request: "initializeVirtualSession",
                status: "success",
              },
            });
          },
          onError: (e) => {
            console.error(e);
            viewState.dispatch({
              type: "set_notator_api_request_state",
              payload: {
                request: "initializeVirtualSession",
                status: "error",
              },
            });
          },
        });
      } else {
        viewState.dispatch({
          type: "set_notator_api_request_state",
          payload: {
            request: "initializeVirtualSession",
            status: "idle",
          },
        });
      }
    };

    saveVirtualSessionUponNotatorVisitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, inUnpostedSessionsView, viewParamId]);
}
