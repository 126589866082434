import usePalette from "./usePalette";
import { SessionStatus } from "../session-sdk";

interface SessionStatusColors {
  primary: string;
  primaryDark: string;
}

export default function useSessionStatusColors(status: SessionStatus): SessionStatusColors {
  const palette = usePalette();
  switch (status) {
    // Gray
    case SessionStatus.NUMBER_0:
      return {
        primary: palette.contrasts[3],
        primaryDark: palette.contrasts[0],
      };
    // Yellow
    case SessionStatus.NUMBER_1:
      return {
        primary: palette.warning[3],
        primaryDark: palette.warning[0],
      };
    // Blue
    case SessionStatus.NUMBER_2:
      return {
        primary: palette.menu[3],
        primaryDark: palette.contrasts[0],
      };
    // Red
    case SessionStatus.NUMBER_3:
      return { primary: palette.danger[3], primaryDark: palette.danger[1] };
    // Green
    case SessionStatus.NUMBER_4:
      return {
        primary: palette.success[3],
        primaryDark: palette.success[1],
      };
    // Purple
    case SessionStatus.NUMBER_5:
      return {
        primary: palette.secondary[3],
        primaryDark: palette.secondary[4],
      };
    default:
      console.error("Fallthrough in switch case! Was a new session status introduced?");
      return {
        // Return default coloring as stopgap to prevent application from crashing
        primary: palette.contrasts[3],
        primaryDark: palette.contrasts[0],
      };
  }
}
