import ReportsLayout from "../../reports_layout";
import { Stack } from "@mui/material";
import { useState } from "react";
import { API_DISTRICTS } from "../../../../api/api";
import { useXNGSelector } from "../../../../context/store";
import useFetchNullableResponse from "../../../../hooks/use_fetch_nullable_response";
import dayjs, { Dayjs } from "dayjs";
import useBreakpointHelper from "../../../../design/hooks/use_breakpoint_helper";
import TableInputControls from "./table_input_controls";
import { selectLoggedInClientAssignment } from "../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { SchoolCampusRef } from "../../../../profile-sdk";
import { campusDropdownStyleHack } from "./campus_dropdown_style_hack";
import { DataGrid } from "@mui/x-data-grid";
import UnpostedSessionsReportToolbar from "./unposted_sessions_report_toolbar";
import { LoadingResponseMessageTemplate } from "../../../../design/templates/loading_response_message_template";
import PaginatedDataGridLoadingLayout from "../../../../design/high-level/paginated_data_grid_loading_layout";
import { useRequestHandlerForUnpostedSessionsReport } from "./request_handler_for_unposted_sessions_report";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";

function UnpostedSessionsReport() {
  const clientID = useXNGSelector(selectClientID);
  const stateInUS = useXNGSelector(selectStateInUS);

  // Breakpoint Management
  const bph = useBreakpointHelper();
  const screenShouldCollapse = !bph.isGreaterThanEqualTo("md"); // TECH DEBT: Technically this is a hook invocation. We should examine the helper module and see what can be kept, refactored, or phased out.

  // School Campus Options Declaration
  const primaryDistrict = useXNGSelector(selectLoggedInClientAssignment).authorizedDistricts![0];
  const fetchedCampusOptions = useFetchNullableResponse(() =>
    API_DISTRICTS.v1DistrictsIdSchoolCampusesDropdownDisplaysGet(
      primaryDistrict.id!,
      stateInUS,
    ).then((v) => v.schoolCampuses!),
  );

  // Form States
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(1, "year"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [selectedCampusOptions, setSelectedCampusOptions] = useState<SchoolCampusRef[]>([]);
  const [includeSPsWithoutCampusesCheckbox, setIncludeSPsWithoutCampusesCheckbox] =
    useState<boolean>(false);

  const rch = useRequestHandlerForUnpostedSessionsReport({
    reduxDependencies: { clientID, stateInUS },
    requestParameters: {
      startDate,
      endDate,
      selectedCampusOptions,
      includeSPsWithoutCampusesCheckbox,
    },
  });

  return (
    <ReportsLayout title="Unposted Sessions Report">
      <Stack sx={{ pr: "2rem" }}>
        <TableInputControls
          shouldCollapse={screenShouldCollapse}
          checkboxState={{
            value: includeSPsWithoutCampusesCheckbox,
            onCheckboxClick: () =>
              setIncludeSPsWithoutCampusesCheckbox(!includeSPsWithoutCampusesCheckbox),
          }}
          dateRangeState={{
            endDate,
            startDate,
            onStartDateChange: setStartDate,
            onEndDateChange: setEndDate,
          }}
          campusDropdownProps={{
            options: fetchedCampusOptions ?? [],
            selectedOptions: selectedCampusOptions,
            onChange: setSelectedCampusOptions,
            getOptionLabel: (v) => v.name!,
            variant: "no overflow",
            label: "",
            sx: campusDropdownStyleHack,
            renderOptionVariant: "checkbox",
            textFieldProps: {
              placeholder: selectedCampusOptions.length > 0 ? "" : "None selected",
            },
            isOptionEqualToValue: (a, b) => a.id === b.id,
          }}
          onRunClick={rch.handleRunClick}
        />

        <PaginatedDataGridLoadingLayout
          isLoading={rch.tablePollRequest.isPolling}
          loadingContent={<LoadingResponseMessageTemplate />}
          sizeAnchor={screenShouldCollapse ? "25rem" : "21rem"}
        >
          <DataGrid
            slots={{
              toolbar: UnpostedSessionsReportToolbar,
            }}
            slotProps={{
              toolbar: {
                onDownload: rch.handleExportToCSVClick,
                shouldDisableDownloadAsCsv: !rch.csvPollRequest.result,
                isPolling: rch.csvPollRequest.isPolling,
              },
            }}
            columns={rch.dataGridProps.columns}
            rows={rch.dataGridProps.rows}
            getRowId={() => crypto.randomUUID()}
            {...rch.dataGridPaginationProps}
          />
        </PaginatedDataGridLoadingLayout>
      </Stack>
    </ReportsLayout>
  );
}

export default UnpostedSessionsReport;
