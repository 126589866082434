import { TabInnerViewportLayout } from "../../layouts/inner_viewport_headers";
import { useState } from "react";
import { EditGoalListModal } from "./edit_goal_list_modal";
import { Box, Divider } from "@mui/material";
import { StudentGoalOption } from "./components/student_goal_option";
import { NotatorTabViewPropsBase } from "../../types/tab_view_props_base";
import { useStudentGoalReferenceMap } from "./hooks/use_student_goal_reference_map";
import { useFetchedActiveGoalsByStudentInSessionIndex } from "./api-hooks/use_fetch_active_goals";

interface GoalsObjectivesTabViewProps extends NotatorTabViewPropsBase {}

export default function GoalsObjectivesTabView(props: Readonly<GoalsObjectivesTabViewProps>) {
  const { selectedStudentIndex } = props;

  const activeGoals = useFetchedActiveGoalsByStudentInSessionIndex(selectedStudentIndex);

  const referenceMap = useStudentGoalReferenceMap({
    selectedStudentIndex,
    activeGoals,
  });

  const [showGoalsModal, setShowGoalsModal] = useState<boolean>(false);

  return (
    <>
      {/* Modals */}
      <EditGoalListModal
        fetchedActiveGoals={activeGoals}
        open={showGoalsModal}
        onClose={() => setShowGoalsModal(false)}
        selectedStudentIndex={selectedStudentIndex}
        shownGoalReferenceList={referenceMap}
      />

      {/* Content */}
      <TabInnerViewportLayout
        title="Goals & Objective Progress Tracking"
        useLink={{ text: "Edit Goal List", onClick: () => setShowGoalsModal(true) }}
      >
        {referenceMap?.map((goal, i) => {
          return (
            <Box key={`${selectedStudentIndex}-${i}`}>
              {i !== 0 && <Divider />}

              <StudentGoalOption unifiedGoal={goal} selectedStudentIndex={selectedStudentIndex} />
            </Box>
          );
        })}
      </TabInnerViewportLayout>
    </>
  );
}
