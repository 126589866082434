import React from "react";  
import ProgressReportingContext from "../progress_reporting_context";
import { PollingStatus, ProgressReportingStateType, SummaryGenerationStatus } from "../../types/progress_reporting_context_type";
import { ProgressReportingAction } from "../progress_reporting_actions";
import { progressReportingReducer } from "../reducer/progress_reporting_reducer"; 




const initialState: ProgressReportingStateType = {
  error: undefined,
  isEditing: false, 
  loading: false,
  currentlyLoggedInServiceProvider:undefined,
  currentlyLoggedInUserProfile:undefined,
  campusesSelectedForProgressReporting:undefined,
  allServiceProvidersUnderSelectedCampuses:[],
  schoolYearRange:undefined,
  reportingPeriods:[],
  studentProgressReportFetched:undefined,
  settingsPopup:{
    showObjectives:true,
    showChart:true,
    showAggregatedData:true,
    showLastSession:false,
    showTeamAggregatedData:false,
    showActiveGoals:false,
    serviceProviderSelected:undefined
  }, 
  /** DATA MOUNTED FROM THE FILTER SECTION */
 

  /** STUDENT PROGRESS REPORT WITH POST UNPOST STATUS */
  studentProgressReportTransformed:undefined,
  printingSignal:false,
  isGeneratingXLSX:false,
  pollingStatus:PollingStatus.Idle,
  summaryGenerationStatus: SummaryGenerationStatus.Idle,
  serviceProvidersSelectedOnFilter:[],
  aggregatedProgressReport:undefined,
  teamAggregatedProgressReport:undefined,
  isPerformingAggregation:false,
  isFinalizingProgressReport:false,
  progressReportGeneratedMasterCopy:undefined
}


const ProgressReportingContextProvider:React.FC<{children: React.ReactNode}> = ({children}) => {
  const [state, dispatch] = React.useReducer<React.Reducer<ProgressReportingStateType, ProgressReportingAction>>(progressReportingReducer, initialState);
  const memoisedState = React.useMemo(()=>state,[state])
  const memoisedDispatch = React.useMemo(()=>dispatch,[])

  return (
    <ProgressReportingContext.Provider value={{state:memoisedState,dispatch:memoisedDispatch}}>
        {children}
    </ProgressReportingContext.Provider>
  )
}

export default ProgressReportingContextProvider