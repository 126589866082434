import { PageParameters, V1StudentReportsStudentProgressReportGetStudentProgressReportPostRequest } from "@xng/reporting"; 
import { useReportingPollRequest } from "../../../hooks/use_polling_mechanism";
import { API_STUDENT_PROGRESS_REPORT } from "../../../../../api/api";
import { useMemo } from "react";
import { ReportRunDateAndID } from "../../account_student_report/report_run_date_and_id";

export function useProgressReportPolling(props: {
  reportRunDateAndID: ReportRunDateAndID | null;
  pageParameters: PageParameters;
}) {
  const { reportRunDateAndID, pageParameters } = props;

  const params:V1StudentReportsStudentProgressReportGetStudentProgressReportPostRequest = {
    getStudentProgressReportPostRequest: {
      pageParameters,
      reportRunDate: reportRunDateAndID?.date,
      reportRunId: reportRunDateAndID?.id,
    },
  };

  const poll = useReportingPollRequest({
    mutationFn: () =>
      API_STUDENT_PROGRESS_REPORT.v1StudentReportsStudentProgressReportGetStudentProgressReportPostRaw(params),
      mutationKey: ["v1StudentReportsStudentProgressReportGetStudentProgressReportPostRaw"],
  });

  const pollRequest = useMemo(() => {
    return poll;
  }, [params]);

  return pollRequest;
}
