import {
  Dialog,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Box from "../../../components-dev/BoxExtended";
import { getSizing } from "../../../sizing";
import { useEffect,useState } from "react";
import XNGButton from "../../../low-level/button";
import { DistrictAccessRequest, ServiceProviderType } from "../../../../profile-sdk";
import { XLogsRoleStrings } from "../../../../context/types/xlogsrole";
import useApiQueryGetServiceProviderTypesByDate from "../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { MSBInputErrorWrapper, MSBSearchMultiselect } from "../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../utils/msb_mui_auto_complete_filter_options";

interface IApproveDistrictAccessModal {
  handleDone: (role: XLogsRoleStrings, selectedServiceProviders: ServiceProviderType[]) => void;
  districtAccessRequest: DistrictAccessRequest | undefined;
  setShowApproveDistrictAccessModal: (show: boolean) => void;
  showApproveDistrictAccessModal: boolean;
  stateInUs: string;
  serviceProviderTypes: ServiceProviderType[];
}
export function ApproveDistrictAccessModal(props: Readonly<IApproveDistrictAccessModal>) {
  const requestingUser = props.districtAccessRequest?.requestingUser;
  const roles = [
    "Service Provider - Autonomous",
    "Service Provider - Assistant",
    "Approver",
    "Proxy Data Entry",
    "Delegated Admin",
    "Executive Admin",
  ];

  const [selectedRole, setSelectedRole] = useState<XLogsRoleStrings>("Service Provider - Autonomous");

  function onSelectChange(event: SelectChangeEvent<XLogsRoleStrings>) {
    const role = event.target.value;
    setSelectedRole(role as XLogsRoleStrings);
  }

  const {
    data: serviceProviderTypesOptionsResponse,
    isError: serviceProviderTypesOptionsIsError,
    isPending: serviceProviderTypesOptionsIsPending,
    refetch: refetchMutlipleServiceProviderTypesOptions,
  } = useApiQueryGetServiceProviderTypesByDate({
    queryParams: {
      state:props.stateInUs,
    },
  });
  
  const [selectedServiceProviderTypes, setSelectedServiceProviderTypes
  ] = useState<ServiceProviderType[]>(props.districtAccessRequest?.requestingUser?.serviceProviderTypes || []);
 


  useEffect(() => {
    setSelectedServiceProviderTypes(props.districtAccessRequest?.requestingUser?.serviceProviderTypes || []);
  }, [props.districtAccessRequest?.requestingUser?.serviceProviderTypes]);

  return (
    <Dialog
      className="noselect"
      onClose={() => {
        props.setShowApproveDistrictAccessModal(false);
      }}
      open={props.showApproveDistrictAccessModal}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: getSizing(3),
          paddingTop: getSizing(3),
          paddingBottom: getSizing(2),
          paddingX: getSizing(2),
        }}
      >
        <Typography variant="h6">Please Select Provider Role</Typography>
        <Typography variant="body1">
          Please select the appropriate role for the provider you would like to approve.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {requestingUser?.firstName} {requestingUser?.lastName}:
        </Typography>
        <Box
          display="inline-flex"
          sx={{
            gap: getSizing(1),
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ textDecoration: "underline" }}>
            Service Provider Type:
          </Typography>
          <MSBInputErrorWrapper
            isError={serviceProviderTypesOptionsIsError}
            displayRefreshButton={serviceProviderTypesOptionsIsError}
            errorText={"Error fetching service provider types Options"}
            refetch={refetchMutlipleServiceProviderTypesOptions}
            rootProps={{
              sx:{
                flex: 1,
              }
            }}
          >
            <MSBSearchMultiselect
              selectedOptions={selectedServiceProviderTypes}
              options={serviceProviderTypesOptionsResponse?.serviceProviderTypes || []}
              getOptionLabel={(option) => `${option?.name}`}
              onChange={(newServiceProviderTypes, e) => {
                e?.preventDefault();
                e?.stopPropagation();
                setSelectedServiceProviderTypes(newServiceProviderTypes);
              }}
              renderOptionVariant="checkbox"
              variant="no overflow after 1"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              label={(() => {
                if (serviceProviderTypesOptionsIsPending) {
                  return "Loading Service Provider Types Options...";
                }
                if (serviceProviderTypesOptionsIsError) {
                  return "Failed to load Service Provider Types Options";
                }
                return "Service Provider Type(s)";
              })()}
              disabled={serviceProviderTypesOptionsIsPending || serviceProviderTypesOptionsIsError}
              autocompleteProps={{
                disableCloseOnSelect: true,
                filterOptions: msbMUIAutoCompleteFilterOptions(),
                onClick: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                },
              }}
              textFieldProps={{
                onClick: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                },
              }}
              sx={{
                flex: 1,
                maxWidth: "100%",
              }}
            />
          </MSBInputErrorWrapper>
        </Box>
        <Box
          display="inline-flex"
          sx={{
            gap: getSizing(1),
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ textDecoration: "underline" }}>
            X Logs Role Assigned:
          </Typography>
          <Box sx={{ position: "relative", minWidth: getSizing(15), flex:1 }}>
            <FormControl fullWidth>
              <Select
                name="dropdown"
                value={selectedRole}
                onChange={(e) => onSelectChange(e)}
                size="small"
              >
                {roles.map((d: any, index: number) => {
                  return (
                    <MenuItem key={index} value={d}>
                      {d}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <XNGButton
            disabled={selectedServiceProviderTypes.length === 0} 
            sx={{
              width: getSizing(6),
            }}
            onClick={() => {
              props.setShowApproveDistrictAccessModal(false);
              props.handleDone(selectedRole, selectedServiceProviderTypes);
            }}
          >
            Done
          </XNGButton>
        </Box>
      </Box>
    </Dialog>
  );
}
