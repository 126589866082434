import { useEffect } from "react";
import { API_STATESNAPSHOTS } from "../../../api/api";
import { useMutation } from "@tanstack/react-query";
import { GoalAreaOfFocus } from "../../../profile-sdk";
import { useXNGDispatch } from "../../../context/store";
import { pushError } from "../../../context/slices/errorEntries";

export default function useFetchedGoalAreasOfFocusInventory(): GoalAreaOfFocus[] | null {
  const dispatch = useXNGDispatch();

  const mutation = useMutation({
    mutationFn: () => API_STATESNAPSHOTS.v1StateSnapshotsGoalAreasOfFocusGet("TX"),
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  useEffect(() => {
    if (mutation.error) {
      dispatch(
        pushError({
          titleID: "Failed to acquire goal area of focuses",
          codeBlockMessage: mutation.error.message,
        }),
      );
    }
  }, [mutation.error]);

  return mutation.data?.areasOfFocus ?? null;
}
